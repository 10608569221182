import { AlertService } from './../../../../shared/alert/alert.service';
import { ExpenseService } from './../../../../core/services/expense.service';
import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AutoApprovalResult, ExpenseSuggestionData } from 'app/core/models/expense.model';
import { LogHelper } from 'app/core/helpers/log.helper';

@Component({
  selector: 'app-expense-auto-approval-result',
  templateUrl: './expense-auto-approval-result.component.html',
  styleUrls: ['./expense-auto-approval-result.component.scss']
})
export class ExpenseAutoApprovalResultComponent implements OnInit {
  @Input() result?: AutoApprovalResult
  @Input() expenseClaimId: string;

  @Output() resultUpdated: EventEmitter<any> = new EventEmitter();

  expenseSuggestionData: ExpenseSuggestionData;
  borderColor: string;
  suggestionClass: string;

  loading: boolean = false;

  constructor(private expenseService: ExpenseService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.setExpenseSuggestionData();
  }

  setExpenseSuggestionData() {
    this.borderColor = this.getBorderColor();
    this.suggestionClass = this.getSuggestionClass();
    switch (this.result) {
      case AutoApprovalResult.Approve:
        this.expenseSuggestionData = {
          text: 'Approve Claim',
          icon: 'check_circle',
        };
        break;
      case AutoApprovalResult.Reject:
        this.expenseSuggestionData = {
          text: 'Reject Claim',
          icon: 'close',
        };
        break;
      default:
        this.expenseSuggestionData = {
          text: 'Manual Review',
          icon: 'help',
        };
        break;
    }
  }

  suggestApproval() {
    if (this.loading)
      return;

    this.loading = true;
    this.expenseService.suggestApproval(this.expenseClaimId).subscribe({
      next: result => {
        this.result = result.autoApprovalResult;
        this.resultUpdated.emit(this.result);
        this.setExpenseSuggestionData();
        this.loading = false;
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert('Failed to suggest approval');
        this.loading = false;
      }
    });
  }

  getBorderColor(): string {
    switch (this.result) {
      case AutoApprovalResult.Approve:
        return '#00b958';
      case AutoApprovalResult.Reject:
        return '#c5131b';
      default:
        return '#fe8b0d';
    }
  }

  getSuggestionClass(): string {
    switch (this.result) {
      case AutoApprovalResult.Approve:
        return 'approve';
      case AutoApprovalResult.Reject:
        return 'reject';
      default:
        return 'unsure';
    }
  }
}
