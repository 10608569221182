import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiConsumerService } from "../../../core/services/api-consumer.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ApiConsumerList } from "../../../core/models/ApiConsumerList.model";
import { ApiConsumer } from "../../../core/models/api-consumer.model";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { AddApiConsumerModalComponent } from "./add-api-consumer-modal/add-api-consumer-modal.component";
import { EditApiConsumerModalComponent } from "./edit-api-consumer-modal/edit-api-consumer-modal.component";

@Component({
  selector: 'app-admin-api-consumers',
  templateUrl: './admin-api-consumers.component.html',
  styleUrl: './admin-api-consumers.component.scss'
})
export class AdminApiConsumersComponent implements OnInit {
  @ViewChild('revealApiKeyModal') revealApiKeyModal: ModalComponent;
  @ViewChild('addApiConsumerModal') addApiConsumerModal: AddApiConsumerModalComponent;
  @ViewChild('editApiConsumerModal') editApiConsumerModal: EditApiConsumerModalComponent;

  searchForm: UntypedFormGroup;
  dataLoaded: boolean = false;
  results = new ApiConsumerList();
  currentPage = 1;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];
  revealApiKey = '';

  constructor(private _apiConsumerService: ApiConsumerService, private _alertService: AlertService, private _templateService: TemplateService) {
  }

  ngOnInit() {
    this._templateService.showHeader();

    this.searchForm = new UntypedFormGroup({
      keywords: new UntypedFormControl('')
    })

    this.loadApiConsumers(1);
  }

  loadApiConsumers(page: number = 1, keywords: string = null): void {
    this.dataLoaded = false;

    this._apiConsumerService.retrieveApiConsumers(page, keywords).subscribe({
      next: results => {
        this.results = results;
        this.dataLoaded = true;
      },
      error: error => {
        this._alertService.showErrorAlert(error);
        this.dataLoaded = false;
      }
    });
  }

  onSearchInputChanged(keywords: string) {
    this.searchForm.patchValue({ keywords: keywords });
    this.loadApiConsumers(1, keywords)
  }

  onChangePage(pageNumber: number) {
    this.currentPage = pageNumber;
    this.loadApiConsumers(pageNumber);
  }

  onRevealApiKey(consumer: ApiConsumer) {
    this.revealApiKey = consumer.apiKey;
    this.revealApiKeyModal.show();
  }

  onConsumerAddedOrUpdated(): void {
    this.loadApiConsumers(this.currentPage, this.searchForm.get('keywords').value);
  }

  onShowAddConsumerModal() {
    this.addApiConsumerModal.show();
  }

  onShowEditConsumerModal(consumer: ApiConsumer) {
    this.editApiConsumerModal.show(consumer);
  }
}
