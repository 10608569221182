import { VisitTemplateTranslation } from "./visit-template-translation.model";

export class VisitTemplate {
  public id: string;
  public title: string;
  public takeMedicationAtVisit: boolean;
  public urineSample3DaysBefore: boolean;
  public urineSample10DaysBefore: boolean;
  public translations: VisitTemplateTranslation[] = [];

  constructor(init?: Partial<VisitTemplate>) {
    Object.assign(this, init);

    if (init.translations)
      this.translations = init.translations.map(t => new VisitTemplateTranslation(t));

    // Check there's an english translation, if not, create one.
    let english = this.translationForCulture('en');
    if (!english && this.id) {
      english = new VisitTemplateTranslation({ culture: 'en' });
      this.translations.push(english);
    }
  }

  translationForCulture(culture: string) {
    return this.translations.find(t => t.culture.toLowerCase() === culture.toLowerCase());
  }
}
