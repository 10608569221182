import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CaxtonCardsListComponent } from './caxton-cards-list/caxton-cards-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { CaxtonCardActionSelectorComponent } from "./caxton-card-action-selector/caxton-card-action-selector.component";

@NgModule({
  declarations: [
    CaxtonCardsListComponent,
    CaxtonCardActionSelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class CardTrackerModule { }
