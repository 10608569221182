import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from "@angular/forms";
import { SiteAutocompleteComponent } from "../site-autocomplete/site-autocomplete.component";
import { SiteAutocomplete } from "../site-autocomplete/site-autocomplete.model";
import { StringHelper } from "../../core/helpers/string-helper";

@Component({
  selector: '[table-inline-cell-site]',
  templateUrl: './table-inline-cell-site.component.html',
  styleUrl: './table-inline-cell-site.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class TableInlineCellSiteComponent implements AfterViewInit {
  @ViewChild('siteSelect') siteSelect: SiteAutocompleteComponent;
  @ViewChildren('td') tdElements: QueryList<ElementRef>;

  @Output('valueChanged') valueChanged = new EventEmitter<SiteAutocomplete>();

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('siteIdControlName') siteIdControlName: string;
  @Input('siteNameControlName') siteNameControlName: string;
  @Input('siteNumberControlName') siteNumberControlName: string;
  @Input('editable') editable: boolean = false;
  @Input('trialId') trialId: string;
  @Input('truncateChars') truncateChars: number = 0;
  @Input('allowClear') allowClear: boolean = false;

  editing = false;
  originalValue = '';
  showTooltip = false;
  labelTruncated = false;
  private tooltipTimer: any;

  ngAfterViewInit() {
    if (this.parentForm && this.siteSelect) {
      this.siteSelect.setInitialValue(this.parentForm.get(this.siteIdControlName).value, this.parentForm.get(this.siteIdControlName).value);
    }
  }

  onClear() {
    this.parentForm.patchValue({
      siteId: null,
      siteNumber: null,
      siteName: null
    });

    this.siteSelect.clear();
    this.editing = false;

    this.valueChanged.emit(null);
  }

  onEdit(event: MouseEvent): void {
    event.stopPropagation();

    if (this.editable && this.trialId) {
      this.editing = true;
      this.originalValue = this.parentForm.get(this.siteIdControlName).value;

      let siteId = this.parentForm.get(this.siteIdControlName).value;
      let siteName = this.parentForm.get(this.siteNameControlName).value;
      let siteNumber = this.parentForm.get(this.siteNumberControlName).value;

      // Delay to allow site dropdown to load
      setTimeout(() => {
        if (siteId && siteName)
          this.siteSelect.setInitialSelectedItem(new SiteAutocomplete(siteId, siteNumber, siteName, this.getLabel(), ''));

        this.siteSelect.openDropdown();
        this.showTooltip = false;
      }, 100);
    }
  }

  onValueChanged(value: SiteAutocomplete) {
    this.parentForm.patchValue({[this.siteIdControlName]: value.id, [this.siteNameControlName]: value.name});
    this.valueChanged.emit(value);

    this.editing = false;
  }

  // Show tooltip after 2 seconds
  onMouseEnter() {
    if (this.editing)
      return;

    this.tooltipTimer = setTimeout(() => {
      this.showTooltip = true;
    }, 1500);  // 2 seconds delay
  }

  // Clear tooltip timer and hide tooltip
  onMouseLeave() {
    clearTimeout(this.tooltipTimer);
    this.showTooltip = false;
  }

  getLabel(truncate: boolean = true) {
    const siteId = this.parentForm.get(this.siteIdControlName).value;
    const siteName = this.parentForm.get(this.siteNameControlName).value;
    const siteNumber = this.parentForm.get(this.siteNumberControlName).value

    if (!siteId)
      return " ";

    let label = siteName;
    if (siteNumber !== null && siteNumber !== '')
      label = siteNumber + ' / ' + label;

    if (!truncate)
      return label;

    if (label.length > this.truncateChars) {
      this.labelTruncated = true;
    } else {
      this.labelTruncated = false;
    }

    return this.truncateChars > 0 ? StringHelper.limit(label, this.truncateChars) : label;
  }

  protected readonly toolbar = toolbar;
}

