<div class="tab-pane show active fade details-view-results" id="trial-sites" role="tabpanel">
  <div class="details-view-results-head">
    <app-search-input name="searchInput" [parentForm]="searchForm" (inputChange)="onSearchInputChanged($event)"></app-search-input>
    <a class="btn btn-outline btn-outline-slim" (click)="onShowAssignCmsUserModal()">+ Assign CMS User</a>
  </div>

  <table aria-hidden="true" class="details-view-sites with-heading-columns cms-users">
    <thead>
    <tr>
      <th style="width: 10%;">Name</th>
      <th>Email Address</th>
      <th style="text-align: center;">Project<br/>Manager</th>
      <th style="text-align: center;">Designated<br/>Contact</th>
      <th style="width: 20%;"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of getCmsUsersForPage(currentPage)">
      <td> {{ user.name}}</td>
      <td> {{ user.email }}</td>
      <td style="text-align: center;">
        <app-trial-role-toggle [checked]="user.projectManager" (changed)="onRoleToggled(user.id, $event, user.designatedContact)"></app-trial-role-toggle>
      </td>
      <td style="text-align: center;">
        <app-trial-role-toggle [checked]="user.designatedContact" (changed)="onRoleToggled(user.id, user.projectManager, $event)"></app-trial-role-toggle>
      </td>
      <td class="text-right">
        <div class="site-actions">
          <a class="btn btn-outline btn-outline-slim" (click)="onUnassignCmsUser(user.id, user.name)" style="min-width: unset">Unassign</a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="no-results inside" *ngIf="trialUsers === null || trialUsers.length == 0">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no CMS users assigned to this trial.</p>
      </div>
    </div>
  </div>

  <app-pagination [currentPage]="currentPage" [totalPageCount]="pageCount"
                  (pageSelected)="onPageSelected($event)"></app-pagination>
</div>

<!-- Start: Unassign CMS user -->
<app-modal #unassignCmsUserModal customClassName="delete-site-modal">
  <div class="modal-header">
    <h2 class="modal-title">Unassign CMS User?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to unassign {{ deletingCmsUserName !== null ? deletingCmsUserName : '' }} from this trial?</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unassignCmsUserModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmUnassignCmsUser()" class="btn btn-primary">
          <span *ngIf="unassignInProgress">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!unassignInProgress">Yes, unassign</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign sites modal -->

<!-- Start: Assign CMS user -->
<app-modal #assignCmsUserModal>
  <form [formGroup]="assignCmsUserForm" (submit)="onSubmitAssignForm()">
    <div class="modal-header">
      <h2 class="modal-title">Assign CMS User?</h2>
    </div>
    <div class="modal-body text-center">
      <div class="form-group">
        <app-input-select [items]="filteredCmsUsers" inputPlaceholder="Select a CMS User..." (onFilter)="filterAssignableCmsUsers($event)" [parentForm]="assignCmsUserForm" controlName="cmsUserId" searchInputPlaceholder="Search for CMS Users..." [allowFiltering]="true"></app-input-select>
      </div>

      <div class="form-group" style="text-align: left;">
        <label class="checkbox">
          <input type="checkbox" id="isProjectManager" formControlName="projectManager" value="false">
          <span></span>
        </label>
        <label class="checkbox-label" for="isProjectManager">Project Manager</label>
      </div>

      <div class="form-group" style="text-align: left;">
        <label class="checkbox">
          <input type="checkbox" id="isDesignatedContact" formControlName="designatedContact" value="false">
          <span></span>
        </label>
        <label class="checkbox-label" for="isDesignatedContact">Designated Contact<span class="helper-text">Expense/Travel Coordinators Only</span></label>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row">
        <div class="col-6">
          <a (click)="assignCmsUserModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
        </div>

        <div class="col-6">
          <button class="btn btn-primary" [disabled]="!assignCmsUserForm.valid">
            <span *ngIf="assignCmsUserForm.get('processing').value">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              <span class="sr-only">Loading...</span>
            </span>
            <span *ngIf="!assignCmsUserForm.get('processing').value">Assign</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</app-modal>
<!-- End: Assign sites modal -->
