export class SiteAutocomplete {
  constructor(public id: string, public irgSiteId: string, public name: string, public line1: string, public line2: string, public highlight: boolean = false, public visible: boolean = true, public apiConsumerName: string = null) {
  }

  addressAsString() {
    let lines:string[] = [];

    if (this.line1)
      lines.push(this.line1);

    if (this.line2)
      lines.push(this.line2);

    return lines.join(", ");
  }

  truncatedSiteName(limit: number = 45) {
    return this.name.length > limit ? this.name.substring(0, limit) + '...' : this.name;
  }
}
