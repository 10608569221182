<div class="tab-pane show active fade details-view-results" id="trial-contacts" role="tabpanel">

  <div class="trial-info">
    <div class="trial-info-top">
      <form [formGroup]="form">

        <div class="row">
          <div class="col-3">
            <div class="checkbox-wrap timeline-enabled">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="trialInfoEnabled" formControlName="trialInfoEnabled">
                  <span></span>
                </label>
                <label class="checkbox-label" for="trialInfoEnabled">Enable Trial Info</label>
              </div>
            </div>
          </div>

          <div class="col-3" [ngClass]="{'visibility-hidden': !this.trialInfoEnabled}">
            <div class="checkbox-wrap timeline-enabled">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="timelineEnabled" formControlName="timelineEnabled">
                  <span></span>
                </label>
                <label class="checkbox-label" for="timelineEnabled">Enable Timeline</label>
              </div>
            </div>
          </div>

          <div class="col-3" [ngClass]="{'visibility-hidden': !this.trialInfoEnabled}">
            <div class="form-group trial-start">
              <label class="date-label">Trial Start <sup *ngIf="isRequired('startDate')">*</sup></label>
              <input type="text" #startDateInput class="date" data-toggle="datepicker" formControlName="startDate" (blur)="dateChanged('startDate')">
            </div>
          </div>

          <div class="col-3" [ngClass]="{'visibility-hidden': !this.trialInfoEnabled}">
            <div class="form-group trial-end">
              <label class="date-label">Trial End <sup *ngIf="isRequired('endDate')">*</sup></label>
              <input type="text" #endDateInput class="date" data-toggle="datepicker" formControlName="endDate" (blur)="dateChanged('endDate')">
            </div>
          </div>

        </div>

      </form>
    </div>

    <div class="trial-info-main" [ngClass]="{'visibility-hidden': !trialInfoLoaded || !this.trialInfoEnabled}">
      <div class="row">
        <div class="col-12 col-lg-7">
          <div class="fake-field disabled" style="max-width: 300px; margin-bottom: 20px;" (click)="onDropdownClick()" *ngIf="trialInfoChanged">{{ getCultureByCode(form.get('selectedCulture').value).text }}</div>

          <div class="language-select" [class.visibility-hidden]="trialInfoChanged">
            <app-input-select [items]="filteredCultures" [parentForm]="form" controlName="selectedCulture" [allowFiltering]="true" [allowClear]="true" (onFilter)="onCultureFiltered($event)" searchInputPlaceholder="Search..."></app-input-select>
          </div>
        </div>
      </div>

      <div class="row" [formGroup]="cultureForm">
        <div class="col-12 col-lg-6">
          <div class="checkbox-wrap culture-published" [ngClass]="{'visibility-hidden': form.get('selectedCulture').value === 'en'}">
            <div class="form-group group-publish">
              <label class="checkbox">
                <input type="checkbox" formControlName="published">
                <span></span>
              </label>
              <label class="checkbox-label">Published</label>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6">

        </div>

        <div class="col-12 col-lg-6">
          <app-video-upload #videoUpload [parentForm]="cultureForm" controlName="videoFilename" (invalid)="onVideoInvalid($event)"></app-video-upload>
        </div>

        <div class="col-12 col-lg-6">
          <div class="form-group group-information">
            <label>Information <sup *ngIf="isRequired('information', cultureForm)">*</sup></label>
            <textarea class="form-control" formControlName="information"></textarea>
          </div>
        </div>

        <div class="col-12" [ngClass]="{'visibility-hidden': form.get('timelineEnabled').value === false}">
          <div class="timeline-events">
            <a (click)="timelinePreview.onPreview()" class="btn btn-primary btn-slim preview-btn" *ngIf="eventList.length > 0">Preview timeline</a>

            <h2>Timeline Events</h2>

            <app-trial-timeline-event *ngFor="let timelineEvent of eventList; let i = index" [first]="i == 0" [last]="i == eventList.length-1" (edit)="onEditTimelineEvent(timelineEvent)" (deleted)="onDeleteTimelineEvent(timelineEvent)" [timelineEvent]="timelineEvent" [isPreview]="false"></app-trial-timeline-event>

            <div class="add-timeline-event" (click)="onShowAddTimelineEventModal()">+ Add timeline event</div>
          </div>
        </div>
      </div>
    </div>


    <div class="trial-info-bottom">
      <a (click)="onSave()" class="btn btn-primary" [ngClass]="{'disabled': !changed || !cultureForm.valid }">
        <span *ngIf="isProcessing">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        </span>
        <span *ngIf="!isProcessing">Save</span>
      </a>
    </div>
  </div>

</div>

<app-modal #timelineEventModal customClassName="timeline-event">
  <div class="modal-header">
    <h2 class="modal-title">{{ timelineForm.controls['title'].value }}</h2>
  </div>
  <div class="modal-body" (mousemove)="updateTimelineFormValues()">
    <form class="modal-form-inline" [formGroup]="timelineForm">
      <input style="display: none;" type="file" #uploadImageInput accept='image/*' (change)="preview(uploadImageInput.files)">

      <table>
        <tr>
          <th><label>Date</label></th>
          <td>
            <div class="form-group">
              <input type="text" #timelineDate class="date" data-toggle="datepicker" formControlName="date">
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Type of 'box'</label></th>
          <td style="padding-bottom: 20px;">
            <app-dropdown-input #timelineBoxType [options]="boxTypes" [parentForm]="timelineForm" name="boxType"></app-dropdown-input>
          </td>
        </tr>
        <tr>
          <th><label>Heading</label></th>
          <td>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="heading">
            </div>
          </td>
        </tr>
        <tr>
          <th style="vertical-align: top"><label>Description</label></th>
          <td>
            <div class="form-group">
              <textarea class="form-control" formControlName="description"></textarea>
            </div>
          </td>
        </tr>
        <tr>
          <th class="vertical-align-top"><label>Image</label></th>
          <td colspan="2" class="timeline-add-image">
            <div class="form-group">
              <ul class="images">
                <li *ngIf="timelineAttachment !== null">
                  <img [src]="timelineAttachment.imageUrl" alt=""/>
                  <a (click)="onRemoveAttachment()" class="remove">
                    <i class="far fa-times" aria-hidden="true"></i>
                  </a>
                </li>
                <li *ngIf="timelineAttachment === null || timelineAttachment.filename === null || timelineAttachment.filename === ''" class="add-image"><a (click)="onAddImage()">+</a></li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="timelineEventModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a class="btn btn-primary" [ngClass]="{'disabled': !timelineForm.valid}" (click)="onSaveTimelineEvent()">
          <span *ngIf="timelineFormProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!timelineFormProcessing">{{ timelineForm.controls['buttonLabel'].value }}</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>

<app-modal #removeTimelineAttachmentModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Image?</h2>
  </div>
  <div class="modal-body">
  </div>
  <div class="modal-footer">
    <button (click)="removeTimelineAttachmentModal.hide()" class="btn btn-secondary btn-full-span" style="margin-bottom: 20px;">Cancel</button>

    <button (click)="onConfirmRemoveTimelineAttachment()" class="btn btn-primary btn-full-span">Confirm</button>
  </div>
</app-modal>

<!-- Start: Unsaved changed modal -->
<app-modal #unsavedChangedModal>
  <div class="modal-header">
    <h2 class="modal-title">Unsaved Changes</h2>
  </div>
  <div class="modal-body text-center">
    <p>You have unsaved changes on the current page, do you want to save them?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unsavedChangedModal.hide()" class="btn btn-secondary" data-dismiss="modal">Keep editing</a>
      </div>

      <div class="col-6">
        <a class="btn btn-primary" (click)="onSave()" [ngClass]="{'disabled': !cultureForm.valid }">
          <span>Save changes</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unsaved changed modal -->

<!-- Start: Remove timeline event modal -->
<app-modal #removeTimelineEventModal>
  <div class="modal-header">
    <h2 class="modal-title">Delete Timeline Event</h2>
  </div>
  <div class="modal-body text-center">
    <p>Delete this timeline event?</p>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="removeTimelineEventModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a class="btn btn-primary" (click)="onConfirmDeleteTimelineEvent()">
          <span>Delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Remove timeline event modal -->

<app-preview-timeline #timelinePreview [timelineEvents]="eventList"></app-preview-timeline>
