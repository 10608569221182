<div class="view-head">
  <div class="row">

    <div class="col-12 text-center">
      <h2 class="heading-small">Edit Site Details</h2>
    </div>

  </div>
</div>

<div class="view-body">

  <div class="row">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

      <div class="content-panel">

        <form [formGroup]="form" (submit)="onFormSubmit()">

          <table class="simple-form" aria-hidden="true">
            <tr>
              <th style="width: 130px;"><label>Site Name</label></th>
              <td>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="name">
                </div>
              </td>
            </tr>
            <tr>
              <th><label>Global IRG Site Id</label></th>
              <td>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="irgSiteId">
                </div>
              </td>
            </tr>
            <tr [ngClass]="{'visibility-hidden': hideAddressInput}">
              <th><label>Country</label></th>
              <td>
                <div class="form-group">
                  <app-autosuggest-dropdown-input #countrySelect [required]="true" [options]="countryOptions" placeholder="Select Country..." [parentForm]="form" controlName="country"></app-autosuggest-dropdown-input>
                </div>
              </td>
            </tr>
            <tr>
              <th class="valign-top"><label>Address</label></th>
              <td>
                <div class="form-group">
                  <textarea class="form-control" formControlName="address" maxlength="200" [disabled]="true"></textarea>
                </div>
              </td>
            </tr>
            <tr>
              <th class="valign-top"><label>Site Address</label></th>
              <td>
                <app-input-address #siteAddress [parentForm]="form" controlName="siteAddress" [countryRequired]="true" [countryDisabled]="siteAddressCountryDisabled"></app-input-address>
              </td>
            </tr>
            <tr>
              <th><label>Timezone</label></th>
              <td>
                <div class="form-group">
                  <app-timezone-select #timezoneSelect [parentForm]="form" controlName="timezone"></app-timezone-select>
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <td>
                <div class="btn-wrap">
                  <div class="row">
                    <div class="col-6">
                      <a routerLink="/site" class="btn btn-secondary">Cancel</a>
                    </div>
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary" [disabled]="form.invalid">
                        <span *ngIf="isFormProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                          </span>
                        <span *ngIf="!isFormProcessing">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

        </form>

      </div>

    </div>
  </div>

</div>
