import { TrialPublicView } from './../../features/trial/trial-details/trial-public-view.model';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TrialAutocomplete } from "./trial-autocomplete.model";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { TrialList } from "../../core/models/trial-list.model";
import { TrialService } from "../../core/services/trial.service";
import { LogHelper } from "../../core/helpers/log.helper";
import { ObjectHelper } from '../../core/helpers/object.helper';
import { PatientService } from '../../core/services/patient.service';
import { TrialState } from 'app/core/constants/trial-constants';

@Component({
  selector: 'app-trial-autocomplete',
  templateUrl: './trial-autocomplete.component.html',
  styleUrls: ['./trial-autocomplete.component.scss']
})
export class TrialAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef;

  @Output() valueChanged = new EventEmitter<TrialAutocomplete>();

  @Input('form') targetForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() selected: TrialAutocomplete = null;
  @Input() placeholder: string;
  @Input() returnTrialId = false;
  @Input() showAllOnOpen = true;
  @Input() patientId: string;
  @Input() publicList: boolean;
  @Input() enableClearSelection = false;
  @Input() liveTrials: boolean = false;
  @Input() minChars = 2;
  @Input() truncate: number = 25;
  @Input('showApiEnabledTrials') showApiEnabledTrials: boolean = true;

  form: UntypedFormGroup;

  filteredOptions: TrialAutocomplete[] = [];
  resultsAreLoading = false;
  showDropdown = false;
  searchHasValue = false;
  label = '';
  trialState?: TrialState;
  trialPageSize = 9999;
  displayValue: string = '';
  development = true;

  constructor(private _trialService: TrialService, private _patientService: PatientService) {
  }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      input: new UntypedFormControl('', [this.optionSelectedValidator.bind(this)])
    });

    this.form.get('input').valueChanges.subscribe(value => {
      this.searchHasValue = value !== '';
    });

    this.trialState = this.liveTrials ? TrialState.Live : null;
  }

  ngAfterViewInit(): void {
    this.form.get('input').valueChanges.subscribe(value => {
      if (value.length >= this.minChars) {
        this.filter(value);
      } else if (value === '') {
        this.filteredOptions = [];
      }
    });
  }

  reset() {
    this.selected = null;
    this.searchHasValue = false;
    this.form.patchValue({ input: '' });
    this.filter(null);
  }

  onClick(): void {
    if (!this.targetForm.get(this.controlName).disabled) {
      this.showDropdown = !this.showDropdown;

      if (this.showDropdown) {
        if (this.showAllOnOpen)
          this.filter(null);

        setTimeout(() => {
          this.input?.nativeElement.focus();
        }, 500);
      }
    }
  }

  optionSelectedValidator(control: UntypedFormControl): { [s: string]: boolean } | null {
    if (this.selected === null || this.selected === undefined) {
      return {'noOptionSelected': true};
    }

    return null;
  }

  setInitialValue(id: string, text: string): void {
    this.selected = new TrialAutocomplete(id, text);
    this.displayValue = text.substring(0, this.truncate) + (text.length > this.truncate ? '...' : '');
  }

  setPatientId(patientId: string) {
    this.patientId = patientId;

    this.filter(null);
  }

  filter(value: string): void {
    this.resultsAreLoading = true;

    if (ObjectHelper.isUndefinedNullOrEmpty(this.patientId)) {
      this.retrieveTrials(value);
    } else {
      this.retrieveTrialForPatient(this.patientId, value);
    }
  }

  onSelectOption(option: TrialAutocomplete): void {
    this.selected = option;
    this.displayValue = option.label.substring(0, this.truncate) + (option.label.length > this.truncate ? '...' : '');
    this.targetForm.patchValue({[this.controlName]: this.returnTrialId ? option.id : option.label.split(" ")[0]});
    this.form.patchValue({input: ''});
    this.filteredOptions = [];
    this.valueChanged.emit(this.selected);
  }

  /**
   * Gets a list of trials for a specific patient
   * @param patientId
   * @param value
   * @private
   */
  private retrieveTrialForPatient(patientId: string, value: string) {
    this._patientService.retrievePatientTrials(patientId, value, 1, this.trialPageSize).subscribe({
      next: (trialList: TrialList) => {
        this.filteredOptions = [];
        trialList.results.forEach(result => {
          this.filteredOptions.push(new TrialAutocomplete(result.id, result.label));
        });
        this.resultsAreLoading = false;
      },
      error: (error) => {
        LogHelper.log(error);
        this.resultsAreLoading = false;
      }
    });
  }

  /**
   * Gets a list of trials
   * @param value
   * @private
   */
  private retrieveTrials(value: string) {
    if(this.publicList){
      this._trialService.getTrialsPublic(value, this.trialPageSize, this.trialState).subscribe({
        next: (response: TrialPublicView[]) =>{
          this.filteredOptions = response;
        }
      })

      this.resultsAreLoading = false;
      return;
    }

    this._trialService.retrieveTrials(1, value, this.trialPageSize, this.trialState).subscribe({
      next: (trialList: TrialList) => {
        this.filteredOptions = [];

        trialList.results.forEach(result => {
          if (!this.showApiEnabledTrials && !result.apiEnabled) {
            this.filteredOptions.push(new TrialAutocomplete(result.id, result.label, result.diaryEnabled));
          } else if (this.showApiEnabledTrials) {
            this.filteredOptions.push(new TrialAutocomplete(result.id, result.label, result.diaryEnabled));
          }
        });
        this.resultsAreLoading = false;
      },
      error: (error) => {
        LogHelper.log(error);
        this.resultsAreLoading = false;
      }
    });
  }

  /**
   * Clears the selected trial
   */
  onClearSelection(closeDropdown = false): void {
    if (this.targetForm.get(this.controlName).disabled) {
      return;
    }

    this.selected = null;
    this.form.patchValue({ input: '' });
    this.targetForm.patchValue({ [this.controlName]: '' });
    this.valueChanged.emit(null);

    if (closeDropdown) {
      setTimeout(() => {
        this.showDropdown = false
      }, 100);
    }
  }
}
