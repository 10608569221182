<div class="trials">

  <div class="patient-detail-head">
    <div class="back-button">
      <div backBtn class="back-btn"></div>
    </div>

    <div class="row">
      <div class="col-6">
        <span class="patient-name">
          <span class="patient-state-badge status-{{ patient.patientState | lowercase }}" *ngIf="patientLoaded">{{patient.patientState}}</span>
          <span *ngIf="patientLoaded">{{ patient.getFullName() }}</span>
          <ngx-skeleton-loader class="d-block" *ngIf="!patientLoaded"></ngx-skeleton-loader>
        </span>
      </div>

      <div class="col-6">
        <div class="buttons" *ngIf="patientLoaded">
          <a class="btn btn-outline btn-light" *ngIf="!patient.patientNotOnApp" (click)="onSendToPatient()">Email App Invitation</a>
          <a class="btn btn-secondary" (click)="onOpenNotificationPopup()" *ngIf="patient.trialAllowActivityCentre">Send
            Notification</a>
          <a (click)="patientEditModal.show(patient.id)" class="btn btn-primary" *ngIf="authService.hasPermission(Permissions.PatientWrite)">Edit</a>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="patient-info-boxes" style="margin-bottom: 20px;">
          <div class="box-label">
            <label>Patient Code</label>
            <ngx-skeleton-loader class="d-block" *ngIf="!patientLoaded"></ngx-skeleton-loader>
            <p *ngIf="patientLoaded">{{ patient.id !== undefined ? patient.patientCode : ''}}</p>
          </div>

          <div class="box-label">
            <label>Patient Email Address</label>
            <ngx-skeleton-loader class="d-block" *ngIf="!patientLoaded"></ngx-skeleton-loader>
            <p *ngIf="patient.id !== undefined">
              {{ patient.externalEmail ? patient.externalEmail : patient.email}}
            </p>
            <span class="badge badge-info" style="padding: 1px 5px 0 5px; font-weight: 400;" *ngIf="patientLoaded && patient.apiConsumerName !== null && patient.apiConsumerName !== ''">{{patient.apiConsumerName}}</span>
          </div>

          <div class="box-label">
            <label>Home Address</label>
            <ngx-skeleton-loader class="d-block" *ngIf="!patientLoaded"></ngx-skeleton-loader>
            <p *ngIf="patientLoaded">{{ patient.id !== undefined && patient.getFullAddress() !== '' ? patient.getFullAddress() : '-' }}</p>
          </div>

          <div class="box-label">
            <label>Trial Code</label>
            <ngx-skeleton-loader class="d-block" *ngIf="!patientLoaded"></ngx-skeleton-loader>
            <p *ngIf="patientLoaded"><a routerLink="/trial/{{patient.trialId}}">{{ patient.trialLabel }}</a></p>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="patient.id !== undefined && patient.note !== null && patient.note !== ''">
      <div class="col-12">
        <div class="patient-important-information">
          <label>Important Information</label>
          <p>{{ patient.note }}</p>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="patient.id !== undefined">
      <div class="col-6">
        <div class="patient-consent">
          <p class="added-by" *ngIf="patient.consent && patient.consentType.toLowerCase() === 'app'">Consented via app: {{
              patient.consentDate | date: 'd MMM yyyy' }}</p>
          <p class="added-by" *ngIf="patient.consent && patient.consentType.toLowerCase() === 'cms'">CMS-observed consent:
            {{ patient.consentDate | date: 'd MMM yyyy' }}</p>
          <p class="added-by" *ngIf="!patient.consent">!!!CONSENT NOT YET OBSERVED!!!</p>
        </div>
      </div>

      <div class="col-6">
        <div class="patient-approved" style="text-align: right;">
          <p class="added-by">Approved by {{ patient.approvedByName }} on {{ patient.approvedDate | date: 'd MMM yyyy' }}
            at {{ patient.approvedDate | date: 'h:mm aaa' }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="details-view">

    <div class="tabs-wrap" [ngClass]="{'visibility-hidden': patient.id === undefined}">
      <div class="mobile-tabs">
        <select id="nav-tabs-select" class="selectpicker">
          <option value="#patient-visits-anchor" *ngIf="authService.hasPermission(Permissions.PatientVisitsRead)">Visits
          </option>
          <option value="#patient-expenses-anchor" *ngIf="authService.hasPermission(Permissions.PatientExpenseWrite)">
            Expenses</option>
          <option value="#patient-additional-details-anchor"
            *ngIf="authService.hasPermission(Permissions.PatientDetailsRead)">Additional Details</option>
          <option value="#patient-notes-anchor" *ngIf="authService.hasPermission(Permissions.PatientNotesRead)">Notes
          </option>
          <option value="#patient-activity-anchor" *ngIf="authService.hasPermission(Permissions.PatientActivityRead)">
            Activity</option>
          <option value="#patient-change-log-anchor"
            *ngIf="authService.hasPermission(Permissions.PatientChangeLogRead)">Change Log</option>
        </select>
      </div>

      <ul class="nav nav-tabs" [ngClass]="{'visibility-hidden': patient.id === undefined}">
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientVisitsRead)">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'visits'}" (click)="onSelectTab('visits')">Visits</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientExpenseWrite)">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'expenses'}"
            (click)="onSelectTab('expenses')">Expenses</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientDetailsRead)">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'additional-details'}"
            (click)="onSelectTab('additional-details')">Additional Details</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientNotesRead)">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'notes'}" (click)="onSelectTab('notes')">Notes</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientActivityRead)">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'activity'}"
            (click)="onSelectTab('activity')">Activity</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientChangeLogRead)">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'change-log'}"
            (click)="onSelectTab('change-log')">Change Log</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientPaymentInfoRead)">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'payment-info'}"
            (click)="onSelectTab('payment-info')">Payment Info</a>
        </li>
        <li class="nav-item" *ngIf="authService.hasPermission(Permissions.PatientDetailsRead) && patient.diaryEnabled">
          <a class="nav-link" [ngClass]="{'active': selectedTab == 'diary'}"
             (click)="onSelectTab('diary')">Maze E-Diary</a>
        </li>
      </ul>
    </div>

    <div class="tab-content" id="details-view-tab-content" *ngIf="patient.id">

      <app-patient-detail-visits #visitsTab *ngIf="selectedTab === 'visits'"></app-patient-detail-visits>

      <app-patient-detail-expenses #expensesTab *ngIf="selectedTab === 'expenses'" [patientId]="patient.id"
        [patientTrialId]="patientTrialId"
        [trialAllowsExpenses]="patient.trialAllowsExpenses"></app-patient-detail-expenses>

      <app-patient-detail-additional #additionalDetailsTab (patientInfoUpdated)="loadPatient()"
        *ngIf="selectedTab === 'additional-details'" [patient]="patient"></app-patient-detail-additional>

      <app-patient-detail-notes #notesTab *ngIf="selectedTab === 'notes'"
        [patientId]="patient.id"></app-patient-detail-notes>

      <app-patient-detail-activity #activityTab *ngIf="selectedTab === 'activity'"
        [patientId]="patient.id"></app-patient-detail-activity>

      <app-patient-detail-change-log #changeLogTab *ngIf="selectedTab === 'change-log'"
        [patientTrialId]="patientTrialId"></app-patient-detail-change-log>

      <app-patient-payment-info [trialId]="patient.trialId" [patientId]="patientTrialId" [patientBasicInfo]="patientBasicInfo" #paymentInfoTab
        *ngIf="selectedTab === 'payment-info'"></app-patient-payment-info>

      <app-patient-diary *ngIf="selectedTab === 'diary'" [patientTrialId]="patientTrialId"></app-patient-diary>

    </div>

  </div>

</div>

<!-- Start: Notification modal -->
<app-modal #notificationModal>
  <div class="modal-header">
    <h2 class="modal-title">Send Notification</h2>
  </div>
  <div class="modal-body">
    <form class="modal-form-full-width" [formGroup]="notificationForm">
      <div class="form-group">
        <label>Title</label>
        <input type="text" formControlName="title" maxlength="50">
      </div>
      <div class="form-group">
        <label>Content</label>
        <textarea class="form-control" formControlName="content" maxlength="175"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="notificationModal.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onSubmitNotification()" class="btn btn-primary" [ngClass]="{'disabled': notificationForm.invalid}">
          <span *ngIf="notificationFormIsProcessing">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!notificationFormIsProcessing">Send Notification</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Notification modal -->

<app-patient-edit-modal #patientEditModal (patientUpdated)="loadPatient()"></app-patient-edit-modal>
