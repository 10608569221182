import { Permissions } from './../../../core/constants/permissions';
import { AuthService } from 'app/core/services/auth.service';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { TrialDetail } from 'app/core/models/trial-detail.model';
import { TrialService } from 'app/core/services/trial.service';
import { TranslationService } from 'app/core/services/translation.service';
import { LogHelper } from 'app/core/helpers/log.helper';
import { AlertService } from 'app/shared/alert/alert.service';
import { TemplateService } from 'app/core/services/template.service';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { TrialConstants, TrialStateString } from 'app/core/constants/trial-constants';
import { Countries } from 'app/core/constants/countries';
import { TrialUpdate } from '../../../core/services/interfaces/trial-update.interface';
import { Cultures } from 'app/core/constants/cultures';
import { GlobalConstants } from 'app/core/constants/global-constants';
import { Currencies } from 'app/core/constants/currency';
import {
  AutosuggestDropdownInputComponent
} from 'app/shared/autosuggest-dropdown-input/autosuggest-dropdown-input.component';
import { ApiConsumerService } from "../../../core/services/api-consumer.service";
import { SelectOption } from "../../../core/models/select-option.model";
import { ApiConsumerSimple } from "../../../core/models/api-consumer-simple.model";
import { ListAutocompleteComponent } from "app/shared/list-autocomplete/list-autocomplete.component";

@Component({
  selector: 'app-trial-edit',
  templateUrl: './trial-edit.component.html',
  styleUrls: ['./trial-edit.component.scss']
})
export class TrialEditComponent implements OnInit {
  @ViewChild('languageSelect') languageSelect: DropdownInputComponent;
  @ViewChild('translationVersionDropdown') translationVersionDropdown: DropdownInputComponent;
  @ViewChild('categoriesSelect') categoriesSelect: ElementRef;
  @ViewChild('visitCountElement') visitCountElement: DropdownInputComponent;
  @ViewChild('stateElement') stateElement: DropdownInputComponent;
  @ViewChild('addLanguageModal') addLanguageModal: ModalComponent;
  @ViewChild('removeLanguageModal') removeLanguageModal: ModalComponent;
  @ViewChild('therapeuticAreaSelect') therapeuticAreaSelect: DropdownInputComponent;
  @ViewChild('baseCurrencySelect') baseCurrencySelect: AutosuggestDropdownInputComponent;
  @ViewChild('imsSupplierList') imsSupplierList: ListAutocompleteComponent;

  emailTemplateOptions: { id: string, value: string}[] = [{ id: '0', value: "PatientGo" },{ id: '1', value: "StudyKik" }];
  trial = new TrialDetail();
  expenseCategories: { id: string, value: string }[] = [];
  currencyOptions: { value: string; text: string }[] = [];
  defaultForm: UntypedFormGroup;
  forms: UntypedFormGroup[] = [];
  languageSelectForm: UntypedFormGroup;
  visitCountOptions = Array<{ value: string, text: string }>();
  versionOptions: { value: string, text: string }[] = [];
  formIsProcessing = false;
  states: { value: string, text: string }[] = [];
  cultureSelectedValue: string = '';
  trialDashboardEnabled = TrialConstants.trialDashboardEnabled;
  therapeuticAreaOptions = TrialConstants.therapeuticAreaOptions;

  allCultures: { value: string, text: string }[] = [];
  culturesInUse: { value: string, text: string }[] = [];
  currentCulture = 'en';
  countries = Countries.all();
  selectedCountries: { code: string, name: string }[] = [];
  Permissions = Permissions;
  apiConsumerOptions: SelectOption[] = [];

  constructor(
    private readonly trialService: TrialService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly templateService: TemplateService,
    private readonly translationService: TranslationService,
    public authService: AuthService,
    private readonly apiConsumerService: ApiConsumerService,
    private cdr: ChangeDetectorRef) {
    for (const culture of Cultures.all()) {
      this.allCultures.push({ value: culture.culture, text: culture.name });
    }
  }

  ngOnInit() {
    this.templateService.showHeader();

    // Create options 1..50 for the visit count dropdown
    for (let i = 1; i <= 50; i++) {
      this.visitCountOptions.push({ value: i.toString(), text: i.toString() });
    }

    // Populate Currencies drop down
    for (const currency of Currencies.all()) {
      this.currencyOptions.push({ value: currency.cc, text: currency.cc });
    }

    // Build the add new trial form
    this.defaultForm = new UntypedFormGroup({
      id: new UntypedFormControl(''),
      culture: new UntypedFormControl('en'),
      state: new UntypedFormControl(this.trial.state, Validators.required),
      code: new UntypedFormControl(this.trial.code, [Validators.required, Validators.maxLength(50)]),
      internalCode: new UntypedFormControl(this.trial.internalCode, [Validators.required, Validators.maxLength(50)]),
      name: new UntypedFormControl({ value: '', disabled: true }),
      client: new UntypedFormControl({value: '', disabled: true}, [Validators.required, Validators.maxLength(200)]),
      sponsor: new UntypedFormControl(''),
      nickname: new UntypedFormControl('', Validators.maxLength(200)),
      opportunityNumber: new UntypedFormControl(''),
      protocolNumber: new UntypedFormControl('', [Validators.maxLength(200)]),
      indications: new UntypedFormControl('', Validators.maxLength(1024)),
      therapeuticArea: new UntypedFormControl(''),
      visitCount: new UntypedFormControl(this.trial.visitCount),
      description: new UntypedFormControl('', Validators.maxLength(500)),
      expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      expenseCategories: new UntypedFormControl([]),
      allowTravelRequests: new UntypedFormControl(this.trial.allowTravelRequests),
      allowExpenseRequests: new UntypedFormControl(this.trial.allowExpenseRequests),
      allowActivityCentre: new UntypedFormControl(this.trial.allowActivityCentre),
      enableSmartNotifications: new UntypedFormControl(this.trial.enableSmartNotifications),
      translationVersionId: new UntypedFormControl(this.trial.translationVersionId, Validators.required),
      rideHealthEnabled: new UntypedFormControl(false),
      dashboardEnabled: new UntypedFormControl(false),
      allowFeedbackRequests: new UntypedFormControl(false),
      termsUrl: new UntypedFormControl(this.trial.termsUrl, [Validators.required, Validators.pattern(GlobalConstants.urlValidatorPattern)]),
      privacyPolicyUrl: new UntypedFormControl(this.trial.privacyPolicyUrl, [Validators.required, Validators.pattern(GlobalConstants.urlValidatorPattern)]),
      coordinatorEmail: new UntypedFormControl(this.trial.coordinatorEmail, [Validators.required, Validators.email]),
      countries: new UntypedFormControl([]),
      expensePolicyReminders: new UntypedFormControl(),
      bookingEnabled: new UntypedFormControl(false),
      patientAutoCompletion: new UntypedFormControl(false),
      bankAccountEnabled: new UntypedFormControl(false),
      bankAccountSelfManagementEnabled: new UntypedFormControl(false),
      baseCurrency: new UntypedFormControl('', Validators.required),
      bankAccountSelfCardManagementEnabled: new UntypedFormControl(false),
      muvEnabled: new UntypedFormControl(false),
      apiEnabled: new UntypedFormControl(false),
      requestTravelEmailTemplate: new UntypedFormControl('1'),
      magicLinkExpiryDays: new UntypedFormControl(1),
      emailPatientOnVisitCreation: new UntypedFormControl(false),
      diaryEnabled: new UntypedFormControl(false),
      imsSupplier: new UntypedFormControl('', Validators.maxLength(200))
    });

    this.loadAndSetApiConsumerOptions();

    if (this.authService.hasPermission(Permissions.TrialEditSensitive)) {
      this.defaultForm.get("protocolNumber").addValidators(Validators.required);
      this.defaultForm.get("client").addValidators(Validators.required);
      this.defaultForm.get("name").addValidators(Validators.required);
    }

    this.languageSelectForm = new UntypedFormGroup({
      culture: new UntypedFormControl('en')
    });

    this.languageSelectForm.get('culture').valueChanges.subscribe(value => {
      this.currentCulture = value;
    });

    // Load translation version
    this.loadTranslationVersion().then(() => {
      // After the translation versions have been loaded, continue to load the trial etc
      this.activatedRoute.params.subscribe(params => {
        this.trialService.retrieveTrial(params.id).subscribe({
          next: trial => {
            this.trial = trial;
            this.populateTrialStates(this.trial.state);
            this.populateFormWithTrial(trial);
          },
          error: error => {
            LogHelper.log(error);
            this.alertService.showWarningAlert('Unable to load trial!');
          }
        });
      });

      this.listenToTrialNameValues();
    });

    // Update country tags
    this.defaultForm.get('countries').valueChanges.subscribe(c => {
      this.selectedCountries = [];
      c.forEach(country => {
        this.selectedCountries.push(this.countries.find(c => c.code === country));
      });
    });

    this.cdr.detectChanges();
  }

  /**
   * Retrieves and sets the api consumer options to be used in the api consumer dropdown
   */
  loadAndSetApiConsumerOptions(): void {
    this.apiConsumerService.getAllApiConsumers().subscribe({
      next: (apiConsumers: any) => {
        this.apiConsumerOptions = apiConsumers.map((apiConsumer: ApiConsumerSimple) => {
          const consumerName = apiConsumer.enabled ? apiConsumer.name : `${apiConsumer.name} (Temporarily disabled)`;
          return new SelectOption(apiConsumer.id, consumerName);
        });
      }
    });
  }

  populateTrialStates(state: string) {
    if (state === TrialStateString.Pending) {
      this.states.push({ value: '1', text: 'Pending' });
      this.states.push({ value: '0', text: 'Live' });
      this.states.push({ value: '3', text: 'Cancelled' });
    }

    if (state === TrialStateString.Live) {
      this.states.push({ value: '0', text: 'Live' });
      this.states.push({ value: '2', text: 'Closed' });
    }

    if (state === TrialStateString.Closed) {
      this.states.push({ value: '2', text: 'Closed' });
      this.stateElement.disabled = true;
    }

    if (state === TrialStateString.Cancelled) {
      this.states.push({ value: '3', text: 'Cancelled' });
      this.stateElement.disabled = true;
    }
  }

  formsValid(): boolean {
    return this.defaultForm.valid && this.forms.every((group) => group.valid);
  }

  getStateValue(state: string) {
    for (let s of this.states) {
      if (s.text === state) {
        return s.value;
      }
    }

    return '';
  }

  isCultureInUse(cultureCode: string): boolean {
    let inUse = false;
    this.culturesInUse.forEach(culture => {
      if (culture.value.toLowerCase() === cultureCode.toLowerCase()) {
        inUse = true;
      }
    });

    return inUse;
  }

  loadTranslationVersion() {
    return new Promise((resolve) => {
      this.versionOptions = [];
      this.translationService.list().subscribe({
        next: (versions: any) => {
          versions.forEach(version => {
            this.versionOptions.push({ value: version.id, text: version.version });
          });

          return resolve(null);
        }
      });
    });
  }

  trialHasCategory(id: string): boolean {
    this.expenseCategories.forEach(category => {
      if (category.id === id)
        return true;
    });

    return false;
  }

  populateFormWithTrial(trial: TrialDetail) {
    // Populate categories
    const trialCategories: string[] = trial.categories.map(category => category.id);
    this.defaultForm.patchValue({
      state: this.getStateValue(trial.state),
      code: trial.code,
      internalCode: trial.internalCode,
      name: trial.name,
      description: trial.description,
      client: trial.client,
      sponsor: trial.sponsor ? trial.sponsor : '',
      nickname: trial.nickname ? trial.nickname : '',
      opportunityNumber: trial.opportunityNumber,
      protocolNumber: trial.protocolNumber,
      indications: trial.indications,
      therapeuticArea: trial.therapeuticArea,
      visitCount: trial.visitCount,
      expenseCategories: trialCategories,
      allowTravelRequests: trial.allowTravelRequests,
      allowExpenseRequests: trial.allowExpenseRequests,
      allowActivityCentre: trial.allowActivityCentre,
      enableSmartNotifications: trial.enableSmartNotifications,
      supportEmail: trial.supportEmail,
      supportPhone: trial.supportPhone,
      termsUrl: trial.termsUrl,
      privacyPolicyUrl: trial.privacyPolicyUrl,
      coordinatorEmail: trial.coordinatorEmail,
      translationVersionId: trial.translationVersionId,
      rideHealthEnabled: trial.rideHealthEnabled,
      dashboardEnabled: trial.dashboardEnabled,
      allowFeedbackRequests: trial.allowFeedbackRequests,
      countries: trial.countries,
      expensePolicyReminders: trial.expensePolicyReminders,
      bookingEnabled: trial.bookingEnabled,
      patientAutoCompletion: trial.patientAutoCompletion,
      bankAccountEnabled: trial.bankAccountEnabled,
      bankAccountSelfManagementEnabled: trial.bankAccountSelfManagementEnabled,
      baseCurrency: trial.baseCurrency,
      bankAccountSelfCardManagementEnabled: trial.bankAccountSelfCardManagementEnabled,
      muvEnabled: trial.muvEnabled,
      apiEnabled: trial.apiEnabled,
      apiConsumerIds: trial.apiConsumerIds,
      requestTravelEmailTemplate: trial.requestTravelEmailTemplate !== null ? trial.requestTravelEmailTemplate.toString() : '0',
      magicLinkExpiryDays: trial.magicLinkExpiryDays,
      emailPatientOnVisitCreation: trial.emailPatientOnVisitCreation,
      diaryEnabled: trial.diaryEnabled,
      imsSupplier: trial.imsSupplier
    });

    if (trial.baseCurrency !== "") {
      this.baseCurrencySelect.disable();
    }

    if (this.authService.hasPermission(Permissions.TrialEditSensitive)) {
      if (trial.therapeuticArea) {
        this.therapeuticAreaSelect.setValue(trial.therapeuticArea.toString())
      } else {
        this.therapeuticAreaSelect.reset()
      }

      if (trial.baseCurrency) {
        this.baseCurrencySelect.setSelectedOption(trial.baseCurrency);
      } else {
        this.baseCurrencySelect.onClear();
      }

      this.defaultForm.get('client').enable();
    }

    // State
    this.stateElement.setValue(this.getStateValue(trial.state.toString()));

    // Visit count
    this.visitCountElement.setValue(trial.visitCount.toString());

    // Translation version
    this.translationVersionDropdown.setValue(trial.translationVersionId);
  }

  onStateChanged(state: string) {
    this.defaultForm.patchValue({ 'state': state });
  }

  /**
   * Called when the add trial form is submitted
   */
  onFormSubmit() {
    if (this.defaultForm.valid) {
      this.formIsProcessing = true;

      const updateTrial: TrialUpdate = {
        trialState: +this.defaultForm.get('state').value,
        trialCode: this.defaultForm.get('code').value,
        internalCode: this.defaultForm.get('internalCode').value,
        name: this.defaultForm.get('name').value,
        description: this.defaultForm.get('description').value,
        client: this.defaultForm.get('client').value,
        sponsor: this.defaultForm.get('sponsor').value,
        nickname: this.defaultForm.get('nickname').value,
        opportunityNumber: this.defaultForm.get('opportunityNumber').value,
        protocolNumber: this.defaultForm.get('protocolNumber').value,
        indications: this.defaultForm.get('indications').value,
        therapeuticArea: +this.defaultForm.get('therapeuticArea').value,
        baseCurrency: this.defaultForm.get('baseCurrency').value,
        visitCount: +this.visitCountElement.selectedValue,
        allowTravelRequests: this.defaultForm.get('allowTravelRequests').value,
        allowExpenseRequests: this.defaultForm.get('allowExpenseRequests').value,
        allowActivityCentre: this.defaultForm.get('allowActivityCentre').value,
        enableSmartNotifications: this.defaultForm.get('enableSmartNotifications').value,
        expenseCategories: this.defaultForm.get('expenseCategories').value,
        termsUrl: this.defaultForm.get('termsUrl').value,
        privacyPolicyUrl: this.defaultForm.get('privacyPolicyUrl').value,
        coordinatorEmail: this.defaultForm.get('coordinatorEmail').value,
        translationVersionId: this.defaultForm.get('translationVersionId').value,
        rideHealthEnabled: this.defaultForm.get('rideHealthEnabled').value,
        dashboardEnabled: this.defaultForm.get('dashboardEnabled').value,
        allowFeedbackRequests: this.defaultForm.get('allowFeedbackRequests').value,
        countries: this.defaultForm.get('countries').value,
        expensePolicyReminders: this.defaultForm.get('expensePolicyReminders').value,
        bookingEnabled: this.defaultForm.get('bookingEnabled').value,
        patientAutoCompletion: this.defaultForm.get('patientAutoCompletion').value,
        bankAccountEnabled: this.defaultForm.get('bankAccountEnabled').value,
        bankAccountSelfManagementEnabled: this.defaultForm.get('bankAccountSelfManagementEnabled').value,
        bankAccountSelfCardManagementEnabled: this.defaultForm.get('bankAccountSelfCardManagementEnabled').value,
        muvEnabled: this.defaultForm.get('muvEnabled').value,
        requestTravelEmailTemplate: +this.defaultForm.get('requestTravelEmailTemplate').value,
        magicLinkExpiryDays: +this.defaultForm.get('magicLinkExpiryDays').value,
        emailPatientOnVisitCreation: this.defaultForm.get('emailPatientOnVisitCreation').value,
        diaryEnabled: this.defaultForm.get('diaryEnabled').value,
        imsSupplier: this.defaultForm.get('imsSupplier').value
      };

      this.trialService.updateTrial(this.trial.id, updateTrial).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Trial Updated Successfully');
          void this.router.navigate(['/trial', this.trial.id]).then();
        },
        error: error => {
          LogHelper.log(error);
          this.formIsProcessing = false;
          const message = error.error ? error.error.title : 'Sorry, there was a problem';
          this.alertService.showWarningAlert(message);
        }
      });
    }
  }

  onTherapeuticAreaChanged(therapeuticArea: string) {
    this.defaultForm.patchValue({ therapeuticArea: therapeuticArea });
  }

  onBaseCurrencySelected(baseCurrency: string) {
    this.defaultForm.patchValue({ baseCurrency: baseCurrency });
  }

  onConfirmedRemoveLanguage() {
    let index = -1;
    for (let i = 0; i < this.forms.length; i++) {
      if (this.forms[i].get('culture').value === this.currentCulture) {
        index = i;
      }
    }

    if (index >= 0) {
      let cultureIndex = -1;
      for (let i = 0; i < this.culturesInUse.length; i++) {
        if (this.currentCulture === this.culturesInUse[i].value) {
          cultureIndex = i;
        }
      }

      this.forms.splice(index, 1);
      this.culturesInUse.splice(cultureIndex, 1);
      this.currentCulture = 'en';
      this.languageSelectForm.patchValue({ culture: 'en' });
      this.languageSelect.setValue('en');
    }

    this.removeLanguageModal.hide();
  }

  onAddLanguage(culture: { value: string, text: string }) {
    if (!this.isCultureInUse(culture.value)) {
      this.culturesInUse.push(culture);
      let form = new UntypedFormGroup({
        culture: new UntypedFormControl(culture.value),
        name: new UntypedFormControl(''),
        description: new UntypedFormControl('', Validators.maxLength(1000)),
        expensePolicy: new UntypedFormControl('', Validators.maxLength(5000)),
      });
      this.forms.push(form);
      this.currentCulture = culture.value;
      this.languageSelectForm.patchValue({ culture: culture.value });
      this.languageSelect.setValue(culture.value);
      this.addLanguageModal.hide();
    }
  }

  listenToTrialNameValues() {
    this.defaultForm.get('client').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });

    this.defaultForm.get('sponsor').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });

    this.defaultForm.get('nickname').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });

    this.defaultForm.get('opportunityNumber').valueChanges.subscribe((value) => {
      this.setTrialNameValue();
    });
  }

  setTrialNameValue() {
    const client = this.defaultForm.get('client').value;
    const sponsor = this.defaultForm.get('sponsor').value;
    const nickname = this.defaultForm.get('nickname').value;
    const opportunityNumber = this.defaultForm.get('opportunityNumber').value;

    const value = `${client}${client && (sponsor) ? ' / ' : ''}${sponsor}${nickname && (client || sponsor) ? ' / ' : ''}${nickname}${opportunityNumber && (client || sponsor || nickname) ? ' - ' : ''}${opportunityNumber}`;

    this.defaultForm.controls['name'].setValue(value);
  }

  removeCountryFromTrial(countryCode: string): void {
    let index = this.defaultForm.get('countries').value.indexOf(countryCode);

    if (index >= 0) {
      let countries = this.defaultForm.get('countries').value;
      countries.splice(index, 1);
      this.defaultForm.patchValue({ countries: countries });
    }
  }
}
