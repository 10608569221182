import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ModalComponent } from "../../../shared/modal/modal.component";
import { CreateSiteModalComponent } from "../../../shared/create-site-modal/create-site-modal.component";
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { SiteAutocompleteComponent } from "../../../shared/site-autocomplete/site-autocomplete.component";
import { PatientDetail } from "../../../core/models/patient-detail.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TravelRequestedOptions } from "../../../core/constants/travel-requested-options";
import { CaregiverTravellingOptions } from "../../../core/constants/caregiver-travelling-options";
import { VisitAttendanceOptions } from "../../../core/constants/visit-attendance-options";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientService } from "../../../core/services/patient.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { TemplateService } from "../../../core/services/template.service";
import { AuthService } from "../../../core/services/auth.service";
import { endDateAfterStartDateValidator } from "../../../core/validators/end-after-start-date.validator";
import { SiteAutocomplete } from "../../../shared/site-autocomplete/site-autocomplete.model";
import { SiteListItem } from "../../../core/models/site-list.model";
import { DateHelper, IllyTime } from "../../../core/helpers/date-helper";
import { PatientAddVisit } from "../../../core/services/interfaces/patient-add-visit.interface";
import { LogHelper } from "../../../core/helpers/log.helper";
import { PatientAutoCompleteComponent } from "../../../shared/patient-autocomplete/patient-autocomplete.component";
import { SelectOption } from "../../../core/models/select-option.model";
import { TrialAutocompleteComponent } from "../../../shared/trial-autocomplete/trial-autocomplete.component";
import { TrialAutocomplete } from "../../../shared/trial-autocomplete/trial-autocomplete.model";
import { ObjectHelper } from "../../../core/helpers/object.helper";
import { BookingStatusOptions } from "../../../core/constants/booking-status-options";
import { PublishedOptions } from "../../../core/constants/published-options";
import { AdminService } from "../../../core/services/admin.service";
import { ComponentBase } from "../../../core/component-base";
import { PatientUpdateAddressComponent } from "../../../shared/patient-update-address/patient-update-address.component";
import { Address } from "../../../shared/input-address/address.model";

@Component({
  selector: 'app-add-visit-modal',
  templateUrl: './add-visit-modal.component.html',
  styleUrls: ['./add-visit-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddVisitModalComponent extends ComponentBase implements OnInit, AfterViewInit {
  @ViewChild('modal') modal: ModalComponent;
  @ViewChild('createSiteModal') createSideModal: CreateSiteModalComponent;
  @ViewChild('patientUpdateAddressModal') patientUpdateAddressModal: PatientUpdateAddressComponent;
  @ViewChild('visitType') visitType: DropdownInputComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('endDateInput') endDateInput: ElementRef;
  @ViewChild('patientAutoComplete') patientAutoComplete: PatientAutoCompleteComponent;

  @ViewChild('travelRequestedSelect') travelRequestedSelect: DropdownInputComponent;
  @ViewChild('caregiverTravellingSelect') caregiverTravellingSelect: DropdownInputComponent;
  @ViewChild('attendanceSelect') attendanceSelect: DropdownInputComponent;
  @ViewChild('bookingStatusSelect') bookingStatusSelect: DropdownInputComponent;
  @ViewChild('publishedStatusSelect') publishedStatusSelect: DropdownInputComponent;
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;

  @Output('addedOrUpdated') addedOrUpdated = new EventEmitter();

  patientTrialId: string;
  isFormProcessing = false;
  patient = new PatientDetail();
  form: UntypedFormGroup;
  visitTypeOptions: { value: string, text: string }[] = [];
  travelRequestedOptions = TravelRequestedOptions.all();
  caregiverTravellingOptions = CaregiverTravellingOptions.all();
  visitAttendanceOptions = VisitAttendanceOptions.all();
  bookingStatusOptions = BookingStatusOptions.all();
  publishedOptions = PublishedOptions.all();
  selectedPatientOption: SelectOption;
  coordinatorOptions: SelectOption[] = [];
  enableSelectPatientAndTrial = true;

  constructor(private readonly router: Router, private readonly patientService: PatientService,
              private readonly alertService: AlertService, private readonly activatedRoute: ActivatedRoute,
              private readonly templateService: TemplateService, public authService: AuthService, private adminService: AdminService) {
    super();
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.visitTypeOptions.push({ value: 'Home', text: 'Home' });
    this.visitTypeOptions.push({ value: 'OnSite', text: 'On-Site' });
    this.visitTypeOptions.push({ value: 'Virtual', text: 'Virtual' });
    this.visitTypeOptions.push({ value: 'Telephone', text: 'Telephone' });

    this.form = new UntypedFormGroup({
      trialId: new UntypedFormControl('', Validators.required),
      date: new UntypedFormControl('', Validators.required),
      time: new UntypedFormControl(''),
      endDate: new UntypedFormControl(''),
      endTime: new UntypedFormControl(''),
      visitTitle: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.maxLength(2000)),
      notes: new UntypedFormControl('', Validators.maxLength(2000)),
      visitType: new UntypedFormControl('OnSite', Validators.required),
      department: new UntypedFormControl(''),
      site: new UntypedFormControl(''),
      travelRequested: new UntypedFormControl(),
      caregiverTravelling: new UntypedFormControl(),
      attendance: new UntypedFormControl(),
      patientId: new UntypedFormControl('', Validators.required),
      patientName: new UntypedFormControl(),
      bookingStatus: new UntypedFormControl('NotStarted'),
      publishedStatus: new UntypedFormControl('Draft'),
      designatedContacts: new UntypedFormControl(''),
      takeMedicationAtVisit: new UntypedFormControl(false),
      urineSample3DaysBefore: new UntypedFormControl(false),
      urineSample10DaysBefore: new UntypedFormControl(false)
    }, {
      validators: endDateAfterStartDateValidator,
      updateOn: 'change'
    });
  }

  ngAfterViewInit(): void {
    this.siteAutocomplete.minChars = 1;

    // Default visit type to on-site
    this.visitType.setValue('OnSite');

    this.patientAutoComplete?.selectedChanged.subscribe((option: SelectOption) => {
      this.selectedPatientOption = option;

      // Load patient details
      this.trialAutocomplete.setPatientId(option.value);
      this.loadPatientDetails(option.value);
    });

    this.trialAutocomplete.valueChanged.subscribe((option: TrialAutocomplete) => {
      this.form.patchValue({trialId: option.id});

      this.loadPatientDetailsForTrial(this.form.get('patientId')?.value, option.id);
    });

    // When the start date of the visit changes, check if the end date is before the start date and update
    // the visit attendance value to pending confirmation if the start date is in the future
    this.form.get('date').valueChanges.subscribe(value => {
      if (value !== undefined && value !== null && value !== '') {
        let today = new Date();
        let date = DateHelper.stringToDate(value)

        if (this.form.get('attendance').value === null || this.form.get('attendance').value === '') {
          if (date > today) {
            this.form.patchValue({attendance: 'PendingConfirmation'});
            this.attendanceSelect.setValue('PendingConfirmation');
          } else if (date < today) {
            this.form.patchValue({attendance: 'Attended'});
            this.attendanceSelect.setValue('Attended');
          }
        }
      }
    });

    // Set the default booking status
    if (this.bookingStatusSelect)
      this.bookingStatusSelect.setValue('NotStarted')

    // Set the default published status
    if (this.publishedStatusSelect)
      this.publishedStatusSelect.setValue('Draft')
  }

  loadCoordinators() {
    this.adminService.retrieveAdminUsers(
      1,
      9999,
      "ProjectCoordinatorExpenses,ProjectCoordinatorTravel").subscribe({
      next: results => {
        for (let result of results.results) {
          this.coordinatorOptions.push({value: result.id, text: result.firstname + ' ' + result.lastname});
        }
      }, error: error => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('There was a problem loading coordinators, please try again!');
      }
    });
  }

  private loadPatientDetailsForTrial(patientId: string, trialId: string): void {
    this.patientService.retrievePatientDetailFromPatientIdAndTrialId(patientId, trialId).subscribe({
      next: patient => {
        this.patient = patient;

        this.form.patchValue({designatedContacts: patient.trialDesignatedContactIds});

        // If the patient has a site, then pre-select it
        if (!ObjectHelper.isUndefinedNullOrEmpty(patient.siteId)) {
          if (patient.siteId) {
            this.form.patchValue({site: patient.siteId});
            const text = patient.siteName + ' / ' + patient.siteAddress;
            const option = new SiteAutocomplete(patient.siteId,
              '',
              text.substring(0, 30) + ' ...',
              patient.siteName,
              patient.siteAddress + ' ' + patient.siteCountry);
            this.siteAutocomplete.onSelectOption(option);
          }
        }
      },
      error: (error) => {
        LogHelper.log(error);
        this.alertService.showWarningAlert('Unable to load patient information!');
      }
    });
  }

  /**
   * Loads the patients details and then loads categories/visits for the patients trial
   * @param patientId
   */
  private loadPatientDetails(patientId: string) {
    this.patientService.retrievePatientDetail(patientId).subscribe({
      next: (patient) => {
        this.patient = patient;

        this.form.patchValue({
          patientId: patient.id,
          patientName: patient.firstname + ' ' + patient.lastname
        });
      },
      error: (error) => {
        LogHelper.log(error);
        this.alertService.showErrorAlert(error);
      }
    });
  }

  show(patientId: string = null, trialId: string = null, options: any = {}): void {
    this.loadCoordinators();
    this.resetForm();
    this.modal.show();

    if (options.hasOwnProperty('enableSelectPatientAndTrial')) {
      this.enableSelectPatientAndTrial = options.enableSelectPatientAndTrial;
    }

    if (patientId !== null) {
      this.form.patchValue({patientId: patientId})
    }

    if (trialId !== null) {
      this.patient.trialId = trialId
      this.form.patchValue({trialId: trialId});

      // Load the patient details for the trial
      this.loadPatientDetailsForTrial(patientId, trialId);
    }
  }

  hide(): void {
    this.modal.hide();
  }

  onNewSiteCreated(site: SiteListItem): void {
    this.visitType.setValue('OnSite');

    let text = site.name + ' / ' + site.address;
    let option = new SiteAutocomplete(site.id, site.irgSiteId, text.substring(0, 30) + ' ...', site.name, site.address + ' ' + site.country);

    this.siteAutocomplete.enableInput();
    this.siteAutocomplete.onSelectOption(option);
  }

  /**
   * Updates the date in the form when you move your mouse around, a bit of a bodge fix because angular doesn't
   * automatically detect changes
   */
  onMouseEnterForm() {
    if (this.dateInput.nativeElement.value != this.form.get('date')) {
      this.form.patchValue({ date: this.dateInput.nativeElement.value });
    }

    if (this.endDateInput.nativeElement.value != this.form.get('endDate')) {
      this.form.patchValue({ endDate: this.endDateInput.nativeElement.value });
    }
  }

  onVisitTypeChanged(value: string) {
    if (value !== 'OnSite') {
      this.form.controls.site.setValidators(null);
      this.form.controls.site.setValue(null);
      this.siteAutocomplete.clear();
    } else {
      this.form.controls.site.setValidators(Validators.required);
    }

    this.form.controls.site.updateValueAndValidity();

    if (value === 'Home' && (this.patient.address == null || this.patient.address == '')) {
      this.patientUpdateAddressModal.openModal(this.patient.id, 'Missing Address', 'If creating a home visit, you will need to set this patient up with a home address.');
      this.visitType.reset();
    } else {
      this.form.patchValue({ visitType: value });
    }
  }

  /**
   * Called when the patient address is updated - will reload the patient details
   */
  onPatientAddressUpdated(address: Address) {
    if (address === null || !address.hasValue())
      return;

    this.loadPatientDetails(this.patientTrialId);

    // Wait a second, and then set the trip type to home
    setTimeout(() => {
      this.form.patchValue({ visitType: 'Home' }); // Assume the user wants to create a home visit
    }, 1000);
  }

  resetForm(): void {
    this.enableSelectPatientAndTrial = true;

    // Using form.reset() breaks the autocomplete
    this.form.patchValue({
      patientId: '',
      patientName: '',
      trialId: '',
      visitTitle: '',
      date: '',
      time: '',
      endDate: '',
      endTime: '',
      description: '',
      notes: '',
      visitType: '',
      site: 'OnSite',
      travelRequested: '',
      caregiverTravelling: '',
      attendance: '',
      bookingStatus: 'NotStarted',
      publishedStatus: 'Draft',
      designatedContacts: '',
      takeMedicationAtVisit: false,
      urineSample3DaysBefore: false,
      urineSample10DaysBefore: false
    });

    this.form.get('notes')?.reset();
    this.form.get('description')?.reset();

    if (this.patientAutoComplete)
      this.patientAutoComplete.onClear();

    if (this.trialAutocomplete)
      this.trialAutocomplete.reset();

    if (this.bookingStatusSelect)
      this.bookingStatusSelect.setValue('NotStarted')

    if (this.attendanceSelect)
      this.attendanceSelect.reset();

    this.onVisitTypeChanged('OnSite');
    this.publishedStatusSelect.setValue('Draft');
  }

  onFormSubmit() {
    if (this.form.valid) {
      let time = IllyTime.parseString(this.form.get('time').value);
      let endTime = IllyTime.parseString(this.form.get('endTime').value);

      this.isFormProcessing = true;

      const dto: PatientAddVisit = {
        trialId: this.form.get('trialId').value,
        date: this.form.get('date').value,
        endDate: this.form.get('endDate').value,
        timeMinutes: time.totalMinutes(),
        endTimeMinutes: endTime.totalMinutes(),
        title: this.form.get('visitTitle').value,
        description: this.form.get('description').value,
        notes: this.form.get('notes').value,
        visitType: this.form.get('visitType').value,
        department: this.form.get('department').value,
        siteId: this.form.get('site').value,
        travelRequested: this.form.get('travelRequested').value,
        caregiverTravelling: this.form.get('caregiverTravelling').value,
        attendance: this.form.get('attendance').value,
        bookingStatus: this.form.get('bookingStatus')?.value,
        publishedStatus: this.form.get('publishedStatus')?.value,
        designatedContacts: this.form.get('designatedContacts')?.value,
        takeMedicationAtVisit: this.form.get('takeMedicationAtVisit')?.value,
        urineSample3DaysBefore: this.form.get('urineSample3DaysBefore')?.value,
        urineSample10DaysBefore: this.form.get('urineSample10DaysBefore')?.value
      };

      this.patientService.addVisit(this.form.get('patientId')?.value, dto).subscribe({
        next: () => {
          this.isFormProcessing = false;
          this.alertService.showSuccessAlert('Visit Successfully Added.');
          this.addedOrUpdated.emit();
          this.hide();
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;
          this.alertService.showWarningAlert('Unable to add visit!');
        }
      });
    }
  }

}
