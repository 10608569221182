import { SearchPage } from "./search-page.model";
import { Address } from "../../shared/input-address/address.model";

export class SiteListItem {
  public id: string;
  public name: string;
  public address: string;
  public country: string;
  public trials: { id: string, code: string, nickname: string, opportunityNumber: string, approvalStatus: number, siteNumber: string, trialApiEnabled: boolean }[] = [];
  public irgSiteId: string;
  public siteAddress: Address;
  public apiConsumerId: string;
  public apiConsumerName: string;
  public timezone: string;

  constructor(init?: Partial<SiteListItem>) {
    Object.assign(this, init);
  }

  public static empty() {
    return new SiteListItem();
  }

  public getFullAddress(characterLimit = 0) {
    let address = this.address != null ? this.address.replace(/(?:\r\n|\r|\n)/g, ' ') : '';

    if (this.siteAddress === null)
      address += ' ' + this.country;

    if (characterLimit > 0 && address.length > characterLimit) {
      return address.substring(0, characterLimit) + '...';
    }

    return address;
  }
}

export class SiteList extends SearchPage {
  results: SiteListItem[] = [];
}

export interface SitePublicView {
  id: string;
  name: string;
  address: string;
  country: string;
  apiConsumerId: string;
  apiConsumerName: string;
}
