import { Permissions } from './../../../../../core/constants/permissions';
import { AuthService } from './../../../../../core/services/auth.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteList } from '../../../../../core/models/site-list.model';
import { SiteService } from '../../../../../core/services/site.service';
import { TrialService } from '../../../../../core/services/trial.service';
import { SiteAutocompleteComponent } from '../../../../../shared/site-autocomplete/site-autocomplete.component';
import { LogHelper } from '../../../../../core/helpers/log.helper';
import { AlertService } from '../../../../../shared/alert/alert.service';
import { TrialConstants } from 'app/core/constants/trial-constants';
import { SiteContactService } from '../../../../../core/services/site-contact.service';
import { ObjectHelper } from '../../../../../core/helpers/object.helper';
import { ManageSiteContactsComponent } from '../manage-site-contacts/manage-site-contacts.component';
import { TrialAssignSite } from '../../../../../core/services/interfaces/trial-assignsite.interface';
import { TrialSiteContact } from '../../../../../core/models/trial-site-contact.model';
import { TrialDetail } from "../../../../../core/models/trial-detail.model";

@Component({
  selector: 'app-trial-assign-site',
  templateUrl: './trial-assign-site.component.html',
  styleUrls: ['./trial-assign-site.component.scss']
})
export class TrialAssignSiteComponent implements OnInit, AfterViewInit {
  @ViewChild('trialSiteContacts') trialSiteContacts: ManageSiteContactsComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('introductionDateInput') introductionDateInput: ElementRef;

  trialId: string;
  trial: TrialDetail = new TrialDetail();
  form: UntypedFormGroup;
  isFormProcessing = false;
  trialSites = new SiteList();
  sites = new SiteList();
  siteOptions: { value: string, text: string, line1: string, line2: string }[] = [];
  introductionMethodOptions = TrialConstants.introductionMethodOptions;
  approvalStatusOptions = TrialConstants.approvalStatusOptions;
  declinedReasonOptions = TrialConstants.declinedReasonOptions;

  selectedTab = 'site-info';
  hideActionButtons = false;

  assignedContacts: TrialSiteContact[] = [];

  Permissions = Permissions;

  constructor(private readonly siteService: SiteService, private readonly activatedRoute: ActivatedRoute,
    private readonly alertService: AlertService, private readonly trialService: TrialService,
    private readonly router: Router, private siteContactService: SiteContactService,
    public authService: AuthService, private cd: ChangeDetectorRef) {

    this.form = new UntypedFormGroup({
      siteNumber: new UntypedFormControl(),
      site: new UntypedFormControl('', Validators.required),
      introductionMethod: new UntypedFormControl(),
      approvalStatus: new UntypedFormControl(),
      approvalDate: new UntypedFormControl(),
      declinedReason: new UntypedFormControl(),
      declinedText: new UntypedFormControl(),
      introductionOccurred: new UntypedFormControl(false),
      introductionDate: new UntypedFormControl(),
    });

    // Reload site contacts when the site value changes
    this.form.get('site').valueChanges.subscribe(siteId => {
      this.loadContacts(this.trialId, siteId);
    });
  }

  ngOnInit() {
    // Get trial Id from params (url)
    this.activatedRoute.params.subscribe(params => {
      this.trialId = params.id;

      this.loadTrial(this.trialId);
    });
  }

  loadTrial(trialId: string) {
    this.trialService.retrieveTrial(this.trialId).subscribe({
      next: trial => {
        this.trial = trial;
      },
      error: err => {
        this.alertService.showErrorAlert(err);
      }
    });
  }

  ngAfterViewInit(): void {
    // Load sites that are assigned to this trial
    this.siteService.retrieveSites(1, this.trialId, null, null, false, 9999).subscribe({
      next: trialSiteList => {
        this.trialSites = trialSiteList;

        // Load sites for dropdown
        this.siteService.retrieveSites(1, null, null, null, false, 9999).subscribe({
          next: siteList => {
            this.sites = siteList;

            for (const site of this.sites.results) {
              if (this.showSite(site.id)) {
                const text = site.name + ' / ' + site.address;
                this.siteOptions.push({
                  value: site.id,
                  text: text.substring(0, 30) + ' ...',
                  line1: site.name,
                  line2: site.address + ' ' + site.country
                });
              }
            }

            // Site selected site if present in the URL
            this.activatedRoute.queryParams.subscribe(queryParams => {
              if (queryParams.siteId) {
                this.siteService.retrieveSite(queryParams.siteId).subscribe({
                  next: (rsp) => {
                    const text = rsp.name + ' / ' + rsp.address;
                    this.siteAutocomplete.setInitialValue(rsp.id, text.substring(0, 30) + ' ...');
                    this.form.patchValue({ site: queryParams.siteId });
                  }
                });
              }
            });
          }, error: () => {
            this.alertService.showWarningAlert('Sorry, there was a problem loading sites!');
          }
        });
      },
      error: () => {
        this.alertService.showWarningAlert('There was a problem loading assigned sites!');
      }
    });

    this.cd.detectChanges();
    if (this.authService.hasPermission(Permissions.SiteContactRead)) {
      this.trialSiteContacts.hideFormButtons.subscribe(hideFormButtons => {
        this.hideActionButtons = hideFormButtons;
      });

      this.trialSiteContacts.assignedContactsChanged.subscribe(contacts => {
        this.assignedContacts = contacts;
      })
    }
  }

  showSite(siteId: string) {
    let show = true;
    this.trialSites.results.forEach(site => {
      if (siteId === site.id) {
        show = false;
      }
    });

    return show;
  }

  loadContacts(trialId: string, siteId: string) {
    if (ObjectHelper.isUndefinedNullOrEmpty(trialId) || ObjectHelper.isUndefinedNullOrEmpty(siteId)) {
      return;
    }

    this.siteContactService.receiveByTrialAndSite(trialId, siteId, 1, 999).subscribe({
      next: (contacts) => {
        this.trialSiteContacts.setAssignedContacts(contacts.results)
      }, error: (error) => {
        LogHelper.log(error);
      }
    });
  }

  /**
   * Updates the date in the form when you move your mouse around, a bit of a bodge fix because angular doesn't
   * automatically detect changes
   */
  onMouseEnterForm() {
    if (this.dateInput.nativeElement.value != this.form.get('approvalDate')) {
      this.form.patchValue({ approvalDate: this.dateInput.nativeElement.value });
    }

    if (this.introductionDateInput.nativeElement.value != this.form.get('introductionDate')) {
      this.form.patchValue({ introductionDate: this.introductionDateInput.nativeElement.value });
    }
  }

  /**
   * Set the value of site in the form when the dropdown value is changed
   * @param value
   */
  onSiteChanged(value: string) {
    // Check to see if the site is already assigned to the trial
    for (const site of this.trialSites.results) {
      if (site.id === value) {
        this.alertService.showWarningAlert('The selected site is already assigned to the trial, please select another...');
      } else {
        this.alertService.clearAll();
      }
    }

    this.form.patchValue({ site: value });
  }

  onIntroductionMethodChanged(introductionMethod: string) {
    this.form.patchValue({ introductionMethod: introductionMethod });
  }

  onApprovalStatusChanged(approvalStatus: string) {
    this.form.patchValue({ approvalStatus: approvalStatus });
    this.form.patchValue({ approvalDate: null });
    this.form.patchValue({ declinedReason: null });
    this.form.patchValue({ declinedText: null });
  }

  onDeclinedReasonChanged(declinedReason: string) {
    this.form.patchValue({ declinedReason: declinedReason });
  }

  onHandleTabClick(selected: string) {
    this.selectedTab = selected;
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.isFormProcessing = true;

      const assignSite: TrialAssignSite = {
        siteNumber: this.form.get('siteNumber').value,
        introductionMethod: this.form.get('introductionMethod').value ?? 0,
        approvalStatus: this.form.get('approvalStatus').value ?? 0,
        approvalDate: this.form.get('approvalDate').value,
        declinedReason: this.form.get('declinedReason').value ?? 0,
        declinedText: this.form.get('declinedText').value,
        siteContacts: this.assignedContacts,
        introductionOccurred: this.form.get('introductionOccurred').value,
        introductionDate: this.form.get('introductionDate').value
      };

      this.trialService.assignSite(this.trialId, this.form.get('site')!.value, assignSite).subscribe({
        next: () => {
          this.alertService.showSuccessAlert('Site Successfully Added to Trial.');
          this.router.navigate(['/trial', this.trialId]).then();
        },
        error: error => {
          LogHelper.log(error);
          this.isFormProcessing = false;

          this.alertService.showErrorAlert(error);
        }
      });
    }
  }
}
