import { Component, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DatePipe, NgForOf, NgIf } from "@angular/common";
import { SharedModule } from "../../../../shared/shared.module";
import { ModalViewport } from "../../../../shared/modal-v2/modal-viewport";
import { ModalV2Component } from "../../../../shared/modal-v2/modal-v2.component";
import { PatientService } from "../../../../core/services/patient.service";
import { PatientDetail } from "../../../../core/models/patient-detail.model";
import { AlertService } from "../../../../shared/alert/alert.service";
import { RouterLink } from "@angular/router";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { TabItem } from "../../../../shared/tabulated-content/tab-item.model";
import { TrialPolicy } from "../../../trial/trial-details/trial-policy.model";
import { OrderBy } from "../../../../core/models/expense.model";
import { TravelResult } from "../../../../core/models/travel-results.model";
import { TravelRequestService } from "../../../../core/services/travel-request.service";
import { Permissions } from "../../../../core/constants/permissions";
import { DateHelper } from "../../../../core/helpers/date-helper";
import { AuthService } from "../../../../core/services/auth.service";
import { TabulatedContentComponent } from 'app/shared/tabulated-content/tabulated-content.component';

@Component({
  selector: 'app-visit-patient-detail-modal',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgIf,
    ReactiveFormsModule,
    SharedModule,
    RouterLink,
    DatePipe,
    NgForOf,
    NgIf
  ],
  templateUrl: './visit-patient-detail-modal.component.html',
  styleUrl: './visit-patient-detail-modal.component.scss'
})
export class VisitPatientDetailModalComponent {
  @ViewChild('modal') modal: ModalV2Component;
  @ViewChild('tabulatedContent') tabulatedContent: TabulatedContentComponent;

  @Input('visitId') visitId: string;
  @Input('patientId') patientId: string;
  @Input('patientTrialId') patientTrialId: string;

  viewPort: ModalViewport = ModalViewport.Medium;
  patient: PatientDetail;
  policy: TrialPolicy;
  loading = true;
  tabs: TabItem[] = [
    new TabItem({ title: 'Patient Details', visible: true }),
    new TabItem({ title: 'Travel Budget Info', visible: true }),
    new TabItem({ title: 'Travel Requests', visible: true })
  ];

  travelRequestResults: TravelResult;
  travelRequestsLoaded = false;

  constructor(private _patientService: PatientService, private _travelRequestService: TravelRequestService, public authService: AuthService, private _alertService: AlertService) {

  }

  show(patientId: string, patientTrialId: string, visitId: string = null): void {
    this.modal.show();
    this.patientId = patientId;
    this.patientTrialId = patientTrialId;
    this.visitId = visitId;
    this.travelRequestResults = new TravelResult();
    this.loadPatient(patientTrialId);
    this.loadPolicy(patientTrialId);
    this.loadTravelRequests();

    if (visitId !== null && visitId !== '')
      this.loadTravelRequests();
  }

  showTravelBudgetInfo(patientTrialId: string) {
    this.modal.show();
    this.tabulatedContent.switchTab(1);
    this.loadPolicy(patientTrialId);
    this.tabs.forEach(tab => {
      if (tab.title !== 'Travel Budget Info') {
        tab.visible = false;
      }
    });
  }

  hide(): void {
    this.patient = null;
    this.patientId = null;
    this.patientTrialId = null;
    this.modal.hide();
  }

  loadPolicy(patientTrialId: string) {
    this.loading = true;
    this._patientService.getPolicy(patientTrialId).subscribe({
      next: (policy: TrialPolicy) => {
        this.policy = policy;
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
        this._alertService.showErrorAlert(error);
      }
    });
  }

  loadPatient(patientTrialId: string): void {
    this.loading = true;

    this._patientService.retrievePatientDetailFromPatientTrialId(patientTrialId).subscribe({
      next: patient => {
        LogHelper.log(patient);
        this.patient = patient;
      },
      error: err => {
        this._alertService.showErrorAlert(err);
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  loadTravelRequests(page = 1) {
    // Only load travel requests if the visit Id has been set
    if (this.visitId === null || this.visitId === '')
      return;

    this.travelRequestsLoaded = false;

    this._travelRequestService.retrieveTravelRequests(page, null, this.visitId, null, OrderBy.Oldest, true).subscribe({
      next: results => {
        this.travelRequestResults = results;
        this.travelRequestsLoaded = true;

        if (results.results.length > 0) {
          let travelRequestTab = this.tabs.find(t => t.title === 'Travel Requests');
          travelRequestTab.visible = true;
        } else {
          // Hide travel requests tab
          this.tabs.find(t => t.title === 'Travel Requests').visible = false;
        }
      },
      error: (error) => {
        LogHelper.log(error);
        this.travelRequestsLoaded = true;
        this._alertService.showWarningAlert('There was a problem loading results!');
      }
    });
  }

  renderAccommodationType(type: number): string {
    if (type === undefined || type === null)
      return '';

    return type === 0 ? ' (Per Visit)' : ' (Per Night)';
  }

  renderBudget(budget?: number): string {
    if (budget === undefined || budget === null)
      return '-';

    return budget.toString();
  }

  travelRequestStateBadgeStyle(state: string) {
    switch (state.toLowerCase()) {
      case 'requested':
        return 'badge-warning';
      case 'approved':
        return 'badge-success';
      case 'rejected':
        return 'badge-danger';
    }
  }

  /**
   * Converts time in minutes to a human readable string
   * @param visitTimeMinutes
   */
  visitTime(visitTimeMinutes: number): string {
    if (visitTimeMinutes == null) {
      return '';
    }

    return DateHelper.minutesToTimeStr(visitTimeMinutes);
  }

  protected readonly PatientDetail = PatientDetail;
  protected readonly Permissions = Permissions;
}
