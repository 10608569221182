export class VisitScheduleModel {
  visitTitle: string;
  scheduleIndex: number;
}

export class UpdateVisitScheduleRequest extends VisitScheduleModel {
  templateId: string;
}

export class VisitScheduleViewModel {
  public templateId: string;
  public scheduleIndex: number;
  public title: string;
  public keyVisit: boolean;
  public amount?: number;
  public editable: boolean = true;

  constructor(init?: Partial<VisitScheduleViewModel>) {
    Object.assign(this, init);
  }
}
