export class PatientDiaryConfig {
  public id: string;
  public timezone: string;
  public patientTrialId: string;
  public created: Date;
  public startDate: Date;
  public endDate: Date;
  public dosage: number;
  public dosageText: string;
  public visitRemindersEnabled: boolean;
  public dailyDoseRemindersEnabled: boolean = false;
  public urineRemindersEnabled: boolean;
  public urineReminderTimeMinutes: number;
  public dailyDoseReminderTimeMinutes: number;
  public dailyDoseReminderTimeMinutesUtc: number;
  public visitRemindersAppEnabled: boolean;
  public visitRemindersEmailEnabled: boolean;
  public urineRemindersAppEnabled: boolean;
  public urineRemindersEmailEnabled: boolean;
  public dailyDoseRemindersAppEnabled: boolean;
  public dailyDoseRemindersEmailEnabled: boolean;
}
