import { Component, Input } from '@angular/core';
import { LogHelper } from "../../../../../core/helpers/log.helper";
import { FileDownloadService } from "../../../../../core/services/file-download.service";
import { Lightbox } from "ngx-lightbox";

@Component({
  selector: 'app-expense-image',
  templateUrl: './expense-image.component.html',
  styleUrl: './expense-image.component.scss'
})
export class ExpenseImageComponent {
  @Input('thumbnailUrl') thumbnailUrl: string;
  @Input('filename') filename: string;

  intervalDelay: number = 4000; // 2 seconds between attempting to load an image
  currentRetryAttempt: number = 0;
  isImageProcessing = false;
  retryLimit = 5;
  interval: number;
  isLoading = false;
  imageLoadFailed = false;

  constructor(private _fileDownloadService: FileDownloadService, private lightbox: Lightbox) {
  }

  viewImage() {
    const album: { src: string, caption: string, thumb: string, downloadUrl: string }[] = [];

    this.isLoading = true;
    this._fileDownloadService.downloadExpenseFile(this.filename).subscribe({
      next: (blob) => {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          album.push({ src: event.target.result, caption: '', thumb: '', downloadUrl: '' });
        };
        reader.readAsDataURL(blob);

        this.lightbox.open(album, 0);
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        LogHelper.log(err);
      }
    });
  }

  onImageError(event: Event) {
    if (!this.imageLoadFailed) {
      const img: HTMLImageElement = event.target as HTMLImageElement;
      this.isImageProcessing = true;

      this.interval = window.setTimeout(() => {
        img.src = img.src.split('#')[0] + `#${(new Date()).getTime()}`;
        this.currentRetryAttempt++;

        // check if retry limit has been hit
        if (this.currentRetryAttempt >= this.retryLimit) {
          LogHelper.log(`Loading image ${img.src} has failed ${this.retryLimit} or more times. No additional attempts will be made.`);
          this.isImageProcessing = false;
          this.imageLoadFailed = true;
          clearInterval(this.interval);
        }
      }, this.intervalDelay);
    }
  }

  onImageLoad(event: Event) {
    if (!this.imageLoadFailed) {
      this.isImageProcessing = false;
      clearInterval(this.interval);
    }
  }
}
