import {TimelineEvent} from "./TimelineEvent";
import {environment} from "../../../environments/environment";

export class TrialInfo {
  public trialInfoId: string;
  public culture: string;
  public videoFilename: string;
  public videoUrl: string;
  public published: boolean;
  public information: string;
  public events: TimelineEvent[] = [];

  constructor(init?: Partial<TrialInfo>) {
    Object.assign(this, init);
  }

  public static map(obj: any): TrialInfo {
    let info = new TrialInfo();
    info.trialInfoId = obj.trialInfoId;
    info.culture = obj.culture;
    info.videoFilename = obj.videoFilename;
    info.videoUrl = obj.videoFilename !== null && obj.videoFilename !== '' ? environment.videoBaseUrl + obj.videoFilename : '';
    info.published = obj.published;
    info.information = obj.information;

    obj.events.forEach(event => {
      info.events.push(new TimelineEvent().map(event));
    });

    return info;
  }
}
