import { Component, Input } from '@angular/core';
import { TrialApiConsumer } from "../../../../core/models/trial-detail.model";

@Component({
  selector: 'app-trial-integration',
  templateUrl: './trial-integration.component.html',
  styleUrl: './trial-integration.component.scss'
})
export class TrialIntegrationComponent {

  @Input('apiConsumers') apiConsumers: TrialApiConsumer[] = [];

}
