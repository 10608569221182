import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import moment from "moment";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SiteList, SiteListItem, SitePublicView } from "../models/site-list.model";
import { ObjectHelper } from '../helpers/object.helper';
import { Address } from "../../shared/input-address/address.model";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient) {
  }

  /**
   * Export sites change log
   * @param dateFrom
   * @param dateTo
   */
  exportSites(dateFrom: string, dateTo: string) {
    let body = {
      dateFrom: !ObjectHelper.isUndefinedNullOrEmpty(dateFrom) ? moment(dateFrom + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: !ObjectHelper.isUndefinedNullOrEmpty(dateTo) ? moment(dateTo + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null
    }

    return this.http.post(environment.apiUrl + '/site/export', body);
  }

  /**
   * Delete a site
   * @param siteId
   */
  removeSite(siteId: string) {
    return this.http.delete(environment.apiUrl + '/site/' + siteId);
  }

  /**
   * Return a single site by Id
   * @param siteId side Id
   */
  retrieveSite(siteId: string) {
    return this.http.get<SiteListItem>(environment.apiUrl + "/site/" + siteId);
  }

  /**
   * Retrieves a list of sites
   * @param page the page number of results to get
   * @param trialId return sites assigned to this trial Id (optional)
   * @param keywords return sites matching this keyword (optional)
   * @param country filter by country
   * @param excludeApiConsumerSites exclude API consumer sites from the results
   * @param pageSize the number of results to return per page
   */
  retrieveSites(page, trialId: string, keywords: string, country: string, excludeApiConsumerSites: boolean = false, pageSize = null) {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', pageSize != null ? pageSize : environment.pageSize.toString());

    if (trialId != null) {
      params = params.set('trialId', trialId);
    }

    if (keywords != null) {
      params = params.set('keywords', keywords);
    }

    if (country != null && country !== '') {
      params = params.set('country', country);
    }

    params = params.set('excludeApiConsumerSites', excludeApiConsumerSites);

    return this.http.get<SiteList>(environment.apiUrl + '/site', { params: params }).pipe(map(rsp => {
      rsp.results = rsp.results.map(r => new SiteListItem(r));
      return rsp;
    }));
  }

  retrieveSitesPublic(trialId: string, keywords: string): Observable<SitePublicView[]> {
    let url = `${environment.apiUrl}/site/public-list`;
    let params = new HttpParams();

    if (keywords) {
      params = params.set("keywords", keywords);
    }

    if (trialId) {
      params = params.set("trialId", trialId);
    }

    return this.http.get<SitePublicView[]>(url, { params: params });
  }

  /**
   * Update an existing site
   * @param siteId site Id
   * @param name updated site name
   * @param address updated site address
   * @param country updated site country
   * @param irgSiteId
   * @param siteAddress
   * @param timezone
   */
  updateSite(siteId: string, name: string, address: string, country: string, irgSiteId: string, siteAddress: Address, timezone: string) {
    const body = {
      name: name,
      address: address,
      country: country,
      irgSiteId: irgSiteId,
      siteAddress: siteAddress,
      timezone
    };

    return this.http.put(environment.apiUrl + "/site/" + siteId, body);
  }

  /**
   * Creates a new site
   * @param name site name
   * @param address site address
   * @param country site country
   * @param irgSiteId
   * @param siteAddress
   * @param apiConsumerId
   * @param timezone
   */
  createSite(name: string, address: string, country: string, irgSiteId: string, siteAddress: Address, apiConsumerId: string, timezone: string) {
    let body = {
      name: name,
      address: address,
      country: country,
      irgSiteId: irgSiteId,
      siteAddress: siteAddress,
      apiConsumerId: apiConsumerId,
      timezone: timezone
    };

    return this.http.post<SiteListItem>(environment.apiUrl + '/site', body);
  }
}
