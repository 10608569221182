<div class="caxton-card-action-selector" [class.visibility-hidden]="selectedCardIds.length === 0" [class.has-action]="actionForm.get('action').value">
  <form [formGroup]="actionForm">
      <div class="inner">

          <span class="selection-count">{{ cardCountLabel() }}</span>

          <div class="action-select">
              <app-dropdown-input #actionSelect
                                  [options]="actions"
                                  [parentForm]="actionForm"
                                  name="action"
                                  placeholder="Select action..."
                                  [disabled]="selectedCardIds.length === 0">
              </app-dropdown-input>
          </div>

          <button type="button" class="btn btn-primary" (click)="clearSelection()" *ngIf="selectedCardIds.length > 0">Deselect All</button>
  </div>
  </form>
</div>

<!-- Start: Assign cards form -->
<div class="caxton-card-action-selector__form" [class.visibility-hidden]="!shouldShowForm(CaxtonCardAction.Assign)">
    <form [formGroup]="assignForm">
        <div class="form-group">
            <label>Trial</label>
            <app-trial-autocomplete #trialAutocomplete (valueChanged)="onAssignCardTrialFilterChanged($event)" [form]="assignForm"
                                    controlName="trialId" placeholder="Select a trial..."></app-trial-autocomplete>
        </div>
        <div class="form-group" [hidden]="assignForm.get('sendToPatient').value">
            <label>Site</label>
            <app-dropdown-input #siteAutocomplete [options]="assignCardsSiteOptions" [parentForm]="assignForm" [name]="'siteId'"></app-dropdown-input>
        </div>
        <div class="form-group" [hidden]="assignForm.get('sendToPatient').value">
            <label>Site contact</label>
            <textarea formControlName="siteContact"></textarea>
        </div>
        <div class="form-group" [hidden]="!assignForm.get('sendToPatient').value">
            <label>Patient details</label>
            <input type="text" class="form-control" formControlName="patientDetails">
        </div>

        <div class="form-group">
            <label class="checkbox">
                <input formControlName="sendToPatient" id="send-to-patient" type="checkbox" value="true">
                <span></span>
            </label>
            <label class="checkbox-label" for="all-trials">Send to patient?</label>
        </div>

        <div class="card-action-footer">
            <div class="row">
                <div class="col-6">
                    <a (click)="clearAction()" class="btn btn-secondary btn-full">Cancel</a>
                </div>

                <div class="col-6">
                    <button (click)="onSubmitAssignCards()" class="btn btn-primary btn-full"
                            [disabled]="assignForm.get('isBusy').value || assignForm.invalid">
            <span *ngIf="assignForm.get('isBusy').value">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
                        <span *ngIf="!assignForm.get('isBusy').value">Assign Cards</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End: Assign cards form -->

<!-- Start: Set expiry date form -->
<div class="caxton-card-action-selector__form" [class.visibility-hidden]="!shouldShowForm(CaxtonCardAction.ExpiryDate)">
    <form [formGroup]="expiryDateForm">
        <div class="form-group">
            <label>Expiry Date</label>
            <app-datepickerv2-input [parentForm]="expiryDateForm" controlName="expiryDate"></app-datepickerv2-input>
        </div>

        <div class="card-action-footer">
            <div class="row">
                <div class="col-6">
                    <a (click)="clearAction()" class="btn btn-secondary btn-full">Cancel</a>
                </div>

                <div class="col-6">
                    <button class="btn btn-primary btn-full" (click)="onSubmitSetExpiryDate()"
                            [disabled]="expiryDateForm.get('isBusy').value || expiryDateForm.invalid">
            <span *ngIf="expiryDateForm.get('isBusy').value">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
                        <span *ngIf="!expiryDateForm.get('isBusy').value">Set Expiry Date</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End: Set expiry date form -->

<!-- Start: Sent form -->
<div class="caxton-card-action-selector__form" [class.visibility-hidden]="!shouldShowForm(CaxtonCardAction.Sent)">
    <form [formGroup]="sentForm">
        <div class="form-group">
            <label>Courier Cost</label>
            <textarea formControlName="courierCost"></textarea>
        </div>

        <div class="form-group">
            <label>Tracking No.</label>
            <textarea formControlName="trackingNumber"></textarea>
        </div>

        <div class="card-action-footer">
            <div class="row">
                <div class="col-6">
                    <a (click)="clearAction()" class="btn btn-secondary btn-full">Cancel</a>
                </div>

                <div class="col-6">
                    <button (click)="onSubmitSentForm()" class="btn btn-primary btn-full"
                            [disabled]="sentForm.get('isBusy').value || sentForm.invalid">
            <span *ngIf="sentForm.get('isBusy').value">
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </span>
                        <span *ngIf="!sentForm.get('isBusy').value">Mark as Sent</span>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<!-- End: Send form -->
