export class PatientDiaryEntry {
  public id: string;
  public date: Date;
  public submittedDate: Date;
  public pillsTaken: number;
  public takeAtTime: string;
  public timezone: string;
  public fasting: boolean;
  public state: string;
  public timeTakenMinutes: number;
  public dosage: number;
  public dosageText: string;
  public dailyDoseReminderTimeMinutes: number;
  public dailyDoseReminderTimeMinutesUtc: number;
  public dosageTimezone: string;
  public notificationDateTimeUtc: Date;
  public notificationSentDateTimeUtc: Date;

  constructor(init?: Partial<PatientDiaryEntry>) {
    Object.assign(this, init);

    const now = new Date();
    const entryDate = new Date(init.date);
    if (!init.state && entryDate < now)
      this.state = 'Missed';
  }

  public getPillsTaken(): string {
    if (!this.state)
      return '-';

    return this.pillsTaken > 0 ? this.pillsTaken.toString() : '0';
  }
}
