import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FileAttachment } from "../../../../core/models/file-attachment.model";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { PatientService } from "../../../../core/services/patient.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { concatMap, from, of } from "rxjs";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { switchMap } from "rxjs/operators";

@Component({
  selector: 'app-manage-trip-tickets',
  templateUrl: './manage-trip-tickets.component.html',
  styleUrls: ['./manage-trip-tickets.component.scss']
})
export class ManageTripTicketsComponent implements OnInit {
  @ViewChild('fileUploadInput') fileUploadInput: ElementRef;

  @Output('changed') changed = new EventEmitter<boolean>();

  attachments: FileAttachment[] = [];
  removedAttachments: FileAttachment[] = [];

  constructor(private _patientService: PatientService, private _alertService: AlertService) {
  }

  ngOnInit(): void {
  }

  reset(): void {
    this.attachments = [];
    this.removedAttachments = [];
    this.changed.emit(false);
  }

  /**
   * Initialises the attachments for the component
   * @param attachments
   */
  initialiseAttachments(attachments: FileAttachment[]) {
    this.attachments = attachments;
  }

  /**
   * Opens the file browser when the user clicks the upload button
   * @param event
   */
  onOpenFileBrowser(event: any) {
    event.preventDefault();
    const element = this.fileUploadInput.nativeElement as HTMLElement;
    element.click();
  }

  /**
   * Called when the user selects a file
   * @param event
   */
  onFileSelected(event) {
    this.changed.emit(true);

    if (event?.target?.files.length) {
      for (let file of event.target.files) {
        // Check the file extensions is valid
        if (!this.isPdfFile(file.name)) {
          this._alertService.showWarningAlert('Invalid file '+file.name+'. Only PDF files are allowed');
          continue;
        }

        // Does the file exist in the removed attachments? If so, we need to remove it
        const existingRemoved = this.removedAttachments.find(a => a.originalFileName === file.name);
        if (existingRemoved !== null && existingRemoved !== undefined) {
          const index = this.removedAttachments.indexOf(existingRemoved);
          this.removedAttachments.splice(index, 1);
        }

        // Check that the file hasn't already been uploaded
        const existing = this.attachments.find(a => a.originalFileName === file.name);
        if (existing !== null && existing !== undefined) {
          this._alertService.showWarningAlert('A file with the name ' + file.name + ' has already been added to the trip');
          continue;
        }

        let attachment = new FileAttachment({
          contentType: 'application/pdf',
          originalFileName: file.name,
          weight: this.attachments.length + 1
        });

        let fr = new FileReader();

        fr.onload = (fileLoadEvent: any) => {
          let pdf = fileLoadEvent.target.result.split(',')[1];
          attachment.content = new Blob(this.convertToByteArray(window.atob(pdf)), { type: 'application/pdf' });
          this.attachments.push(attachment);
        };
        fr.readAsDataURL(file);
      }

      event.target.value = '';
    }
  }

  isPdfFile(filename: string): boolean {
    const extension = filename.split('.').pop()?.toLowerCase();
    return extension === 'pdf';
  }

  /**
   * Removes the attachment at the specified index
   * @param index
   */
  remove(index: number): void {
    this.changed.emit(true);

    let attachment = this.attachments[index];

    // Only add the attachment to deleted attachments if has been uploaded
    if (attachment.id !== undefined && attachment.id !== null && attachment.id !== '') {
      this.removedAttachments.push(this.attachments[index]);
    }

    // Remove the attachment from the attachments array so that it is no longer displayed
    this.attachments.splice(index, 1);
  }

  /**
   * Handles when a user drops a file on the drop zone - will re-order the tickets
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    this.changed.emit(true);
    moveItemInArray(this.attachments, event.previousIndex, event.currentIndex);
  }

  /**
   * Converts a string to a byte array
   * @param input
   */
  convertToByteArray(input) {
    const sliceSize = 512;
    const bytes: Uint8Array[] = [];

    for (let offset = 0; offset < input.length; offset += sliceSize) {
      const slice = input.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);

      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      bytes.push(byteArray);
    }

    return bytes;
  }

  renderOriginalFileName(originalFileName: string): string {
    const length = 40;
    if (originalFileName.length >  length) {
      return originalFileName.substring(0, length) + '...';
    }

    return originalFileName;
  }

  /**
   * Uploads ticket attachments for a specified trip, one at a time, in the order they appear in the `attachments` array.
   * If an error occurs during the upload of any ticket, the process is halted and an error is logged and displayed via an alert.
   * If there are no attachments to upload, the method resolves the promise immediately.
   *
   * @param {string} tripId - The unique identifier of the trip for which tickets are being uploaded.
   * @returns {Promise<void>} A promise that resolves once all tickets have been successfully uploaded,
   *                          or rejects if an error occurs during the upload process.
   */
  uploadTicketsForTrip(tripId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let deleteObservable = (this.removedAttachments.length > 0) ?
        this._patientService.deleteTripTicket(tripId, this.removedAttachments.map(a => a.id)) :
        of(null);

      deleteObservable.pipe(
        switchMap(() => {
          let toUpload = this.attachments.filter(a => a.content !== undefined && a.content !== null);
          if (toUpload.length > 0) {
            const uploadTasks = toUpload
              .map(attachment => () => this._patientService.uploadTripTicket(tripId, attachment.originalFileName, 0, attachment.content));

            return from(uploadTasks)
              .pipe(
                concatMap(uploadFunction => uploadFunction())
              );
          } else {
            return of(null);  // Resolve immediately if there are no attachments
          }
        })
      ).subscribe({
        next: () => {
          // Handle success of each individual file upload
          LogHelper.info('Ticket Upload', 'Successfully uploaded a ticket');
        },
        complete: () => {
          // All files (if any) have been uploaded successfully or there were none to upload
          LogHelper.info('Ticket Upload', 'All tickets uploaded successfully or there were none to upload');
          resolve();
        },
        error: (error) => {
          this._alertService.showErrorAlert(error);
          reject(error);  // Reject the promise on error
        }
      });
    });
  }


  setAttachmentsOrder(): void {
    let fileNames = this.attachments.map(a => a.originalFileName);
  }
}
