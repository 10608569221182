import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { TrialContactsComponent } from './trial-contacts/trial-contacts.component';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { TrialDetail } from 'app/core/models/trial-detail.model';
import { TrialService } from 'app/core/services/trial.service';
import { NavigationState } from 'app/core/state/navigation.state';
import { LogHelper } from 'app/core/helpers/log.helper';
import { AuthService } from 'app/core/services/auth.service';
import { AlertService } from 'app/shared/alert/alert.service';
import { UpdateLastRouteAction } from 'app/core/actions/navigation.action';
import { TemplateService } from 'app/core/services/template.service';
import { ModalComponent } from 'app/shared/modal/modal.component';
import { TrialConstants } from 'app/core/constants/trial-constants';
import { Permissions } from '../../../core/constants/permissions';
import { RoleNames } from "../../../core/constants/role-names";
import { SiteAutocompleteComponent } from "../../../shared/site-autocomplete/site-autocomplete.component";
import { TrialAdminUser } from "../../../core/models/trial-admin-user.model";

@Component({
  selector: 'app-trial-details',
  templateUrl: './trial-details.component.html'
})
export class TrialDetailsComponent implements OnInit, AfterViewInit {
  // Export
  @ViewChild('dateFrom') dateFrom: ElementRef;
  @ViewChild('dateTo') dateTo: ElementRef;
  @ViewChild('exportTripsModal') exportTripsModal: ModalComponent;
  @ViewChild('contactModal') contactModal: ModalComponent;
  @ViewChild('contactsComponent') contactsComponent: TrialContactsComponent;
  @ViewChild('siteAutocomplete') siteAutocomplete: SiteAutocompleteComponent;

  exportIsProcessing = false;
  exportForm: UntypedFormGroup;
  Permissions = Permissions;

  trial = new TrialDetail();
  selectedTab = 'sites';
  preSelectedTab = '';
  trialInfoTabEnabled = TrialConstants.trialInfoTabEnabled;

  payments = false;
  therapeuticAreaOptions = TrialConstants.therapeuticAreaOptions;
  roleNames = RoleNames;
  exportOptions: { value: string, text: string }[] = [{ value: 'default', text: 'Default trial data'}, { value: 'diary', text: 'Maze e-diary'}];

  constructor(public authService: AuthService, private readonly trialService: TrialService, private readonly activatedRoute: ActivatedRoute, private readonly router: Router,
    private readonly templateService: TemplateService, private readonly alertService: AlertService, private readonly location: Location, private readonly store: Store<NavigationState>) {
  }

  ngOnInit() {
    this.templateService.showHeader();

    this.activatedRoute.params.subscribe(params => {
      this.loadTrial(params.id);
    });

    // Export sites form
    this.exportForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(),
      dateTo: new UntypedFormControl(),
      type: new UntypedFormControl('default'),
      site: new UntypedFormControl(),
      allData: new UntypedFormControl(false),
      allSites: new UntypedFormControl(false)
    });

    // Reset dates when all data is selected
    this.exportForm.get('allData').valueChanges.subscribe(allData => {
      if (allData) {
        this.exportForm.patchValue({dateFrom: null, dateTo: null});
      }
    })

    this.store.dispatch(new UpdateLastRouteAction(this.location.path()));
  }

  ngAfterViewInit() {
    // Wait a second to allow the trial to be loaded before selecting another tab
    setTimeout(() => {
      this.activatedRoute.fragment.subscribe(fragment => {
        if (fragment) {
          this.selectedTab = fragment;
        }
      });
    }, 1000);
  }

  /**
   * Loads a trial and populates a local property
   * @param trialId
   */
  loadTrial(trialId: string) {
    this.trialService.retrieveTrial(trialId).subscribe({
      next: (trial) => {
        this.trial = trial;
      },
      error: (error) => {
        LogHelper.log(error);
        if (error.status === 403) {
          this.alertService.showWarningAlert('You do not have permission to view this trial.');
          void this.router.navigate(['/trial']);
        } else {
          this.alertService.showWarningAlert(`Unable to load trial: ${error.error.title}`);
        }
      }
    });
  }

  onUserRoleChanged(updatedUser: TrialAdminUser) {
    let user = this.trial.trialUsers.find(user => user.id === updatedUser.id);
    if (!user)
      return;

    user.projectManager = updatedUser.projectManager;
    user.designatedContact = updatedUser.designatedContact;
  }

  onConfirmSelectTab(tabName: string) {
    this.loadTrial(this.trial.id);
    this.contactsComponent.hasChanges = false;
    this.contactModal.hide();
    this.selectedTab = tabName;
    this.preSelectedTab = '';
  }

  onSelectTab(tabName: string) {
    this.preSelectedTab = tabName;
    if (this.selectedTab === 'contacts' && this.contactsComponent.hasChanges) {
      this.contactModal.show();
      this.selectedTab = 'contacts';
    } else {
      this.selectedTab = tabName;
    }
  }

  /**
   * Updates the angular form when you mouse over the export button, it pulls the dates from the fields and puts them
   * into the form. It's a bodge to fix an issue with the jQuery datepicker and angular
   */
  updateExportFormValues() {
    this.exportForm.patchValue({
      dateFrom: this.dateFrom.nativeElement.value,
      dateTo: this.dateTo.nativeElement.value
    });
  }

  onShowTripExportModal() {
    this.exportForm.patchValue({
      dateFrom: '',
      dateTo: '',
      type: 'default',
      site: '',
      allData: false,
      allSites: false
    });
    this.siteAutocomplete.clear();

    this.exportTripsModal.show();
  }

  onExportChangeLog() {
    this.exportIsProcessing = true;
    const from = this.exportForm.get('dateFrom').value;
    const to = this.exportForm.get('dateTo').value;

    this.trialService.exportTrips(
      this.trial.id,
      from,
      to,
      this.exportForm.get('type').value,
      this.exportForm.get('site').value
    ).subscribe({
      next: () => {
        this.alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.exportTripsModal.hide();
        this.exportIsProcessing = false;
      },
      error: error => {
        LogHelper.log(error);
        this.exportIsProcessing = false;
        this.exportTripsModal.hide();
        this.alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }
}
