<div class="tab-pane show active fade details-view-results" id="trial-sites" role="tabpanel">
  <div class="details-view-results-head">
    <a class="btn btn-outline btn-outline-slim" routerLink="/trial/{{ trialId }}/site/assign"
       *ngIf="authService.hasPermission(Permissions.TrialSiteAssign) && !apiEnabled">+ Assign Site</a>
  </div>

  <table aria-hidden="true" class="details-view-sites with-heading-columns">
    <thead>
    <tr>
      <th style="width: 10%;">Site Number</th>
      <th>Site Name</th>
      <th>Site Address</th>
      <th style="width: 10%;">Site Status</th>
      <th style="width: 20%;"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let site of results.results">
      <td class="site-id"> {{ site.trials[0].siteNumber }} <span class="badge badge-info" *ngIf="site.apiConsumerId">{{ site.apiConsumerName }}</span></td>
      <td> {{ site.name }}</td>
      <td> {{ site.getFullAddress() }}</td>
      <td> {{ this.mapStatus(site.trials[0].approvalStatus) }}</td>
      <td class="text-right">
        <div class="site-actions">
          <a routerLink="/trial/{{ trialId }}/site/{{ site.id }}/edit" class="btn btn-secondary btn-edit"
             *ngIf="authService.hasPermission(Permissions.TrialSiteEdit)">Edit</a>
          <a class="btn btn-outline btn-outline-slim" (click)="onUnassignSite(site.id)"
             *ngIf="authService.hasPermission(Permissions.TrialSiteUnassign) && !apiEnabled" style="min-width: unset">Unassign</a>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

  <div class="no-results inside" *ngIf="results.results.length == 0">
    <div class="inner">
      <div class="image">
        <img src="assets/img/no-results-illustration.png" alt="No results found" />
      </div>
      <div class="content">
        <h2>No Results Found</h2>
        <p>There are no sites assigned to this trial.</p>
      </div>
    </div>
  </div>

  <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
    (pageSelected)="loadSites($event)"></app-pagination>
</div>

<!-- Start: Unassign site modal -->
<app-modal #unassignSiteModel customClassName="delete-site-modal">
  <div class="modal-header">
    <h2 class="modal-title">Unassign Site?</h2>
  </div>
  <div class="modal-body text-center">
    <div class="delete-modal-text">
      <p>Are you sure you want to unassign this site?</p>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="unassignSiteModel.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <a (click)="onConfirmUnassignSite()" class="btn btn-primary">
          <span *ngIf="siteIsUnassigning">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="sr-only">Loading...</span>
          </span>
          <span *ngIf="!siteIsUnassigning">Yes, delete</span>
        </a>
      </div>
    </div>
  </div>
</app-modal>
<!-- End: Unassign sites modal -->
