import { Component, Input, Renderer2 } from '@angular/core';
import { ModalViewport } from "./modal-viewport";
import { TAB } from "@angular/cdk/keycodes";

@Component({
  selector: 'app-modal-v2',
  templateUrl: './modal-v2.component.html',
  styleUrls: ['./modal-v2.component.scss']
})
export class ModalV2Component {
  visible = false;
  @Input() customClassName?= '';
  @Input() scrollable = false;
  @Input() tabulated = false;

  @Input() deleteIndex = -1;
  @Input() deleteId: string;

  @Input() viewPort: ModalViewport = ModalViewport.Small;

  constructor(private renderer: Renderer2) { }

  hide() {
    this.visible = false;
    this.renderer.removeClass(document.body, 'modal-open');
  }

  show() {
    this.visible = true;
    this.renderer.addClass(document.body, 'modal-open');

  }

  showUsingDeleteIndex(index: number) {
    this.deleteIndex = index;
    this.show();
  }

  showUsingDeleteId(id: string) {
    this.deleteId = id;
    this.show();
  }

  resetDeleteIndexAndId() {
    this.deleteIndex = -1;
    this.deleteId = null;
  }

  protected readonly TAB = TAB;
}
