import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiConsumerSimple } from "../models/api-consumer-simple.model";
import { map } from 'rxjs';
import { ApiConsumer } from "../models/api-consumer.model";
import { ApiConsumerList } from "../models/ApiConsumerList.model";

;

@Injectable({
  providedIn: 'root'
})
export class ApiConsumerService {
  private _endpoint = '/apiconsumer';

  constructor(private readonly http: HttpClient) {
  }

  /**
   * Returns a list of all API consumers
   */
  getAllApiConsumers() {
    return this.http.get<ApiConsumerSimple>(environment.apiUrl + this._endpoint + '/all');
  }

  /**
   * Updates the resource with the specified ID.
   *
   * @param {string} id - The ID of the resource to update.
   * @param {string} name - The new name of the resource.
   * @param {string} webhookUrl - The new webhook URL of the resource.
   * @param {string} webhookVersion - The new webhook version of the resource.
   * @param {boolean} enabled - Whether the resource should be enabled or not.
   *
   * @return {Promise<Response>} A promise that resolves to the HTTP response returned by the server.
   */
  update(id: string, name: string, webhookUrl: string, webhookVersion: string, enabled: boolean) {
    const body = {
      name,
      webhookUrl,
      webhookVersion,
      enabled
    };

    return this.http.put(environment.apiUrl + this._endpoint + '/' + id, body);
  }

  /**
   * Creates a new record using the provided information.
   *
   * @param {string} name - The name of the record.
   * @param {string} webhookUrl - The URL for the webhook associated with the record.
   * @param {string} webhookVersion - The version of the webhook associated with the record.
   * @param {boolean} enabled - Whether the record is enabled or not.
   *
   * @return {Promise<any>} A promise that resolves with the result of the creation request.
   */
  create(name: string, webhookUrl: string, webhookVersion: string, enabled: boolean) {
    const body = {
      name,
      webhookUrl,
      webhookVersion,
      enabled
    };

    return this.http.post(environment.apiUrl + this._endpoint, body);
  }

  /**
   * Retrieve a list of API consumers based on page number and optional keywords.
   *
   * @param {number} page - The page number to retrieve.
   * @param {string} keywords - Optional keywords to filter the results.
   *
   * @return {Observable<ApiConsumerList>} - An Observable that emits a list of API consumers.
   */
  retrieveApiConsumers(page: number, keywords: string) {
    let params = new HttpParams().set('page', page.toString());

    if (keywords != null) {
      params = params.set('keywords', keywords);
    }

    return this.http.get<ApiConsumerList>(environment.apiUrl + this._endpoint, { params: params }).pipe(map(rsp => {
      rsp.results = rsp.results.map(r => new ApiConsumer(r));
      return rsp;
    }));
  }
}
