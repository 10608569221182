<div class="tab-pane show active fade details-view-results" role="tabpanel">
  <div class="details-view-results-head">
    <div class="buttons-left" *ngIf="isDevelopmentEnv">
      <a class="btn btn-outline btn-outline-slim" (click)="onClearVisitReminders()">Clear Visit Reminders</a>
      <a class="btn btn-outline btn-outline-slim" (click)="onClearUrineReminders()">Clear Urine Reminders</a>
      <a class="btn btn-outline btn-outline-slim" style="min-width: auto; width: auto;" (click)="onResetDiary()" *ngIf="diaryConfig">Reset Diary</a>
    </div>

    <a class="btn btn-outline btn-outline-slim" (click)="diaryConfigModal.show()">Settings</a>
    <button type="button" class="btn btn-secondary btn-slim" (click)="onShowAmendDosageModal()" [disabled]="!shouldShowAmendDosageButton()">Amend Dosage</button>
  </div>

  <table aria-hidden="true" class="details-view-sites with-heading-columns">
    <thead>
    <tr>
      <th>Date</th>
      <th>Take At</th>
      <th>Dosage</th>
      <th>Amount Taken</th>
      <th>Time Taken</th>
      <th>Fasting</th>
      <th>Timezone</th>
      <th>Date/Time Entered</th>
    </tr>
    </thead>
      <tr *ngFor="let entry of entries">
          <td>{{ entry.date | date: 'd MMM yyyy' }}</td>
          <td>{{ getMinutesExpressedAsTime(entry.dailyDoseReminderTimeMinutes) }}</td>
        <td><div style="max-width: 360px; word-break: break-all;">{{ entry.dosage }} / {{ entry.dosageText }}</div></td>
          <td>{{ entry.getPillsTaken() }}</td>
          <td>{{ getMinutesExpressedAsTime(entry.timeTakenMinutes) }}</td>
          <td>{{ getFasting(entry) }}</td>
          <td>{{ getTimezoneWithOffset(entry.dosageTimezone) }}</td>
          <td><span *ngIf="!entry.submittedDate">-</span> <span *ngIf="entry.submittedDate">{{ convertSubmittedDateTimeToPatientTimezone(entry) }}</span></td>
      </tr>
    <tbody>
    </tbody>
  </table>

  <!-- Add no results and pagination -->
</div>

<app-diary-config-modal #diaryConfigModal [hasSubmissions]="hasSubmissions" [patientTrialId]="patientTrialId" (changed)="onDiaryConfigChanged()"></app-diary-config-modal>

<!-- Start: Amend Dosage Modal -->
<app-modal #dosageModal class="amend-dosage-modal">
  <div class="modal-header">
    <h2 class="modal-title">Amend Dosage</h2>
    <button type="button" class="close" (click)="dosageModal.hide()" aria-label="Close">
      <span aria-hidden="true"><i class="far fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="dosageForm">

      <div class="start-end-dates">
        <div class="field-group">
          <div class="field-group-inner">
            <label>Apply Dosage From</label>
            <div class="input-wrap">
              <app-datepickerv2-input [parentForm]="dosageForm" controlName="startDate"></app-datepickerv2-input>
            </div>
          </div>
        </div>

        <div class="field-group">
          <div class="field-group-inner">
            <label>Dose (amount of pills)</label>
            <div class="input-wrap">
              <input type="number" formControlName="dosage">
            </div>
          </div>
        </div>

        <div class="field-group">
          <div class="field-group-inner">
            <label>Dose (text)</label>
            <div class="input-wrap">
              <input type="text" formControlName="dosageText">
            </div>
          </div>
        </div>
      </div>

      <div class="timezone-dose-time">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label>Timezone</label>
              <app-timezone-select #timezoneSelect (optionChanged)="onTimezoneChanged($event)" [parentForm]="dosageForm" controlName="dosageTimezone"></app-timezone-select>
            </div>
          </div>
        </div>

        <div class="field-group">
          <div class="field-group-inner">
            <label>Dosage Time</label>
            <div class="input-wrap">
              <div class="input-wrap type-time">
                <mat-form-field>
                  <input type="time" matInput formControlName="dailyDoseReminderTime">
                </mat-form-field>
              </div>
            </div>
          </div>
          <span class="helper-text" *ngIf="shouldShowUtcTime('dailyDoseReminderTime')">{{ dosageForm.get('dailyDoseReminderTimeUtc').value }} UTC</span>
        </div>
      </div>

    </form>
  </div>

  <div class="modal-footer">
    <button type="button" (click)="onDosageFormSubmit()" [disabled]="dosageForm.invalid || dosageForm.get('isBusy').value" class="btn btn-primary">
      <span *ngIf="dosageForm.get('isBusy').value">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </span>
      <span *ngIf="!dosageForm.get('isBusy').value">Update Dosage</span>
    </button>
  </div>
</app-modal>

<!-- End: Amend Dosage Modal -->
