import { AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';

export const SHORT_DATE_FORMAT = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: '[table-inline-cell-short-date]',
  templateUrl: './table-inline-cell-short-date.component.html',
  styleUrl: './table-inline-cell-short-date.component.scss',
  encapsulation: ViewEncapsulation.None,
  providers: [
    provideMomentDateAdapter(SHORT_DATE_FORMAT),
  ]
})
export class TableInlineCellShortDateComponent implements AfterViewInit {
  @Output('valueChanged') valueChanged = new EventEmitter();

  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('minWidth') minWidth: string;
  @Input('editable') editable: boolean;

  ngAfterViewInit() {
    this.parentForm.get(this.controlName).valueChanges.subscribe((value) => {
      this.valueChanged.emit();
    });
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());
    this.parentForm.get(this.controlName).setValue(ctrlValue);
    datepicker.close();
  }

}
