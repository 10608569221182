export class StringHelper {
  public static limit(str: string, charLimit: number) {
    if (str === undefined || str === null) {
      return '';
    }

    if (str.length <= charLimit) {
      return str;
    }

    return str.slice(0, charLimit) + '...';
  }

  public static truncateMiddle(str: string, charLimit: number): string {
    if (str === undefined || str === null) {
      return '';
    }

    // Adjust the charLimit to account for the length of the ellipsis
    // This adjustment ensures the final string is not longer than the original charLimit
    const effectiveCharLimit = charLimit - 3;

    if (str.length <= charLimit) {
      return str;
    }

    // Calculate the number of characters to show from the start and end of the string
    const frontChars = Math.ceil(effectiveCharLimit / 2);
    const backChars = Math.floor(effectiveCharLimit / 2);

    return str.slice(0, frontChars) + '...' + str.slice(-backChars);
  }


  public static isNullOrEmpty(str: string) {
    return str === undefined || str === null || str === '';
  }

  public static join(array: string[], separator: string, emptyValue: string) {
    if (array === undefined || array === null || array.length === 0) {
      return emptyValue;
    }

    return array.join(separator);
  }

  public static addSpaces(str: string): string {
    if (str === undefined || str === null) {
      return '';
    }

    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  public static getFileExtension(filename: string): string {
    const extensionIndex = filename.lastIndexOf('.');
    if (extensionIndex === -1) {
      return '';
    }

    const extension = filename.slice(extensionIndex + 1);
    return extension.toLowerCase();
  }

  public static convertToCamelCase(input: string): string {
    // Remove special characters and replace them with spaces
    const stringWithSpaces = input.replace(/[^a-zA-Z0-9]/g, ' ');

    // Split the string into words
    const words = stringWithSpaces.split(' ');

    const camelCasedWords = words.map((word, index) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words and remove spaces
    return camelCasedWords.join('');
  }

  public static isEmailValid(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  public static formatTextAsHTML(text: string): string {
    // Convert links to anchor tags
    let formattedText = StringHelper.convertLinksToAnchorTags(text);

    // Wrap paragraphs in <p> elements
    formattedText = StringHelper.wrapParagraphsAndInsertBreaks(formattedText);

    return formattedText;
  }

  public static convertLinksToAnchorTags(text: string): string {
    return text.replace(/(https?:\/\/\S+)/g, '<a href="$1">$1</a>');
  }

  // Helper function to wrap paragraphs in <p> elements
  public static wrapParagraphsAndInsertBreaks(text: string): string {
    // First, replace single new lines with <br>
    text = text.replace(/(\r\n|\r|\n)/g, '<br>');

    // Then, wrap paragraphs in <p> elements
    const paragraphs = text.split('<br><br>'); // Split the text by double <br> to identify paragraphs
    return paragraphs.map(paragraph => `<p>${paragraph}</p>`).join('\r\n');
  }

}
