<div [formGroup]="parentForm" class="select-field" [class.allow-clear]="allowClear" [ngStyle]="{'min-width': minWidth ? minWidth : 'auto', 'max-width': maxWidth ? maxWidth : 'none'}">

  <span class="clear-selection" (click)="onClear()" *ngIf="allowClear && parentForm.get(this.controlName).value"><i class="far fa-times"></i></span>

  <span *ngIf="!editable" class="label-only">{{ getSelectionText() }}</span>
  <mat-select (selectionChange)="onValueChanged($event)" [formControlName]="controlName" *ngIf="editable"
              [multiple]="false"
              [placeholder]="inputPlaceholder" panelClass="visits-caregiver-travelling hide-checkbox">
    <mat-option class="search" *ngIf="displaySearch">
      <input type="text" [formControl]="searchForm.controls.search" (click)="$event.stopPropagation();">
      <i class="far fa-search" aria-hidden="true"></i>
    </mat-option>
    <mat-option *ngFor="let item of displaySearch ? filteredItems : items" [value]="item.value">
      {{ item.text }}
    </mat-option>
  </mat-select>
</div>
