import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from "@angular/forms";
import { SelectOption } from "../../core/models/select-option.model";
import { StringHelper } from "../../core/helpers/string-helper";

@Component({
  selector: '[table-inline-cell-select]',
  templateUrl: './table-inline-cell-select.component.html',
  styleUrls: ['./table-inline-cell-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableInlineCellSelectComponent implements AfterViewInit {
  @Input('items') items: SelectOption[] = [];
  @Input('parentForm') parentForm: UntypedFormGroup;
  @Input('controlName') controlName: string;
  @Input('inputPlaceholder') inputPlaceholder: string;
  @Input('minWidth') minWidth: string;
  @Input('maxWidth') maxWidth: string;
  @Input('displaySearch') displaySearch: boolean = false;
  @Input('filteredItems') filteredItems: SelectOption[] = [];
  @Input('editable') editable = false;
  @Input('maxLength') maxLength = 17;
  @Output('valueChanged') valueChanged = new EventEmitter();
  @Input('allowClear') allowClear: boolean = false;

  searchForm = new FormGroup({
    search: new FormControl<string>('')
  });

  constructor(private cd: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.searchForm.controls.search.valueChanges.subscribe(value => this.filteredItems = this.items.filter(i => i.text?.toLowerCase().includes(value?.toLowerCase())));
    this.cd.detectChanges();
  }

  onClear(): void {
    this.parentForm.patchValue({[this.controlName]: null});
    this.valueChanged.emit({value: null});
  }

  onValueChanged(event: any) {
    this.valueChanged.emit(event);
  }

  getSelectionText(): string {
    const id = this.parentForm.get(this.controlName).value;
    if (id === null || id === '')
      return '';

    let text = this.items.find(i => i.value === id)?.text || ''
    if (text.length > this.maxLength) {
      return StringHelper.limit(text, this.maxLength);
    }

    return this.items.find(i => i.value === id)?.text || '';
  }
}
