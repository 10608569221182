import { SharedEmitterService } from './../../../../shared/share-emitter/shared-emitter.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import {
  PatientTrip
} from "../../../patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";
import { Permissions } from "../../../../core/constants/permissions";
import { AuthService } from "../../../../core/services/auth.service";
import { TripBookingStatus } from "../../../../core/constants/trip-booking-status";
import { StringHelper } from "../../../../core/helpers/string-helper";
import moment from "moment";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { IllyTime } from "../../../../core/helpers/date-helper";
import { UpdateTripDto } from "../../../../core/services/interfaces/update-trip.interface";
import { AlertService } from "../../../../shared/alert/alert.service";
import { PatientService } from "../../../../core/services/patient.service";
import { OverBudgetRequestDetailsStatus } from 'app/core/models/project-management.model';
import { UpdatePatientTripStatusRequest } from 'app/core/models/patient-trip.model';
import { ModalV2Component } from 'app/shared/modal-v2/modal-v2.component';

@Component({
  selector: '[trip-table-row]',
  templateUrl: './trip-table-row.component.html',
  styleUrl: './trip-table-row.component.scss'
})
export class TripTableRowComponent implements OnInit, AfterViewInit {
  @Input('trip') trip: PatientTrip;
  @Input('tripIndex') tripIndex: number = 0;

  @Output('showEditModal') showEditModal = new EventEmitter<string>();
  @Output('tripForOverBudgetSelected') tripForOverBudgetSelected = new EventEmitter<boolean>();

  @ViewChild('recalculateAmountBcModal') recalculateAmountBcModal: ModalV2Component;

  form: UntypedFormGroup;
  tripBookingStatusOptions = TripBookingStatus.all();
  isUpdateProcessing: boolean = false;
  tripLoaded: boolean = false;

  protected readonly Permissions = Permissions;
  OverBudgetRequestDetailsStatus = OverBudgetRequestDetailsStatus;
  constructor(public _authService: AuthService, public _alertService: AlertService,
    public _patientService: PatientService, private el: ElementRef,
    private renderer: Renderer2, private sharedEmitterService: SharedEmitterService) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      bookingStatus: new UntypedFormControl(''),
      departureDate: new UntypedFormControl(),
      departureTime: new UntypedFormControl(),
      arrivalDate: new UntypedFormControl(''),
      arrivalTime: new UntypedFormControl(''),
      ticketConfirmationNo: new UntypedFormControl(),
      reference: new UntypedFormControl(),
      bookingProvider: new UntypedFormControl(),
      carrier: new UntypedFormControl(),
      invoiceNo: new UntypedFormControl(),
      quotedAmount: new UntypedFormControl(),
      quotedCurrency: new UntypedFormControl(),
      quotedAmountBc: new UntypedFormControl(),
      quotedCurrencyBc: new UntypedFormControl({ value: null, disabled: true })
    });
  }

  ngAfterViewInit() {
    this.form.patchValue({
      bookingStatus: this.trip.bookingStatus,
      departureDate: !StringHelper.isNullOrEmpty(this.trip.departureDate) ? moment(this.trip.departureDate).format('DD/MM/YYYY') : null,
      departureTime: !StringHelper.isNullOrEmpty(this.trip.departureDate) && this.trip.departureTimeMinutes !== null ? moment(this.trip.departureDate).format('HH:mm') : null,
      arrivalDate: !StringHelper.isNullOrEmpty(this.trip.arrivalDate) ? moment(this.trip.arrivalDate).format('DD/MM/YYYY') : null,
      arrivalTime: !StringHelper.isNullOrEmpty(this.trip.arrivalDate) && this.trip.arrivalTimeMinutes !== null ? moment(this.trip.arrivalDate).format('HH:mm') : null,
      reference: this.trip.reference,
      ticketConfirmationNo: this.trip.ticketOrConfirmationNo,
      carrier: this.trip.carrier,
      bookingProvider: this.trip.bookingProvider,
      invoiceNo: this.trip.invoiceNo,
      quotedAmount: this.trip.quotedAmount ? (this.trip.quotedAmount / 100).toFixed(2).toString() : '',
      quotedAmountBc: this.trip.quotedAmountBC ? (this.trip.quotedAmountBC / 100).toFixed(2).toString() : '',
      quotedCurrency: this.trip.quotedCurrency,
      quotedCurrencyBc: this.trip.trialBaseCurrency
    });

    this.setValidation();

    this.tripLoaded = true;
  }

  onTripSelected(checked: boolean) {
    this.tripForOverBudgetSelected.emit(checked);
  }

  addClassToParent(className: string) {
    const parentRow = this.el.nativeElement.parentElement;
    if (this.el.nativeElement.tagName === 'TR') {
      this.renderer.addClass(this.el.nativeElement, className);
    }
  }

  removeClassFromParent(className: string) {
    const parentRow = this.el.nativeElement.parentElement;
    if (this.el.nativeElement.tagName === 'TR') {
      this.renderer.removeClass(this.el.nativeElement, className);
    }
  }

  setValidation(): void {
    const tripType = this.trip.type;
    if (tripType === 'GroundTransport' || tripType === 'Flight' || tripType === 'Train') {
      this.form.get('departureDate').setValidators(Validators.required);
      this.form.get('departureDate').updateValueAndValidity();
      this.form.get('arrivalDate').clearValidators();
      this.form.get('arrivalDate').setErrors(null);
      this.form.get('arrivalTime').clearValidators();
      this.form.get('arrivalTime').setErrors(null); this.form.get('arrivalDate').updateValueAndValidity();
    } else if (tripType === 'Accommodation') {
      this.form.get('arrivalDate').clearValidators();
      this.form.get('arrivalDate').setErrors(null);
      this.form.get('departureDate').clearValidators();
      this.form.get('departureDate').setErrors(null);
      this.form.get('departureTime').clearValidators();
      this.form.get('departureTime').setErrors(null);
    }
  }

  onHandleShowEditTripModal() {
    this.showEditModal.emit(this.trip.id);
  }

  onChangesMade(force = false): void {
    if (!this.canSaveChanges(force))
      return;

    this.updateTrip();
  }

  canSaveChanges(force = false) {
    return this.tripLoaded || force;
  }

  updateTripStatus() {
    if (!this.canSaveChanges())
      return;

    let request: UpdatePatientTripStatusRequest = {
      patientTripId: this.trip.id,
      tripBookingStatus: this.form.get('bookingStatus').value
    }

    this._patientService.updatePatientTripStatus(request).subscribe({
      next: result => {

        if(request.tripBookingStatus === 'ObResponded'){
          this.sharedEmitterService.overBudgetRequestClosedOpened.emit(this.trip.visitId);
        }

        if (result.overBudgetRequestClosed) {
          this.sharedEmitterService.overBudgetRequestClosedEvent.emit(this.trip.visitId);
        }
      },
      error: error => {
        this._alertService.showErrorResponse(error.error);
      }
    });
  }

  /**
   * Formats a date and time string into a moment object
   * @param date
   * @param time
   * @param isArrivalDate
   * @private
   */
  private formatDateTimeString(date: string | Date, time: IllyTime, isArrivalDate = false): moment.Moment {
    let response = null;

    if (date instanceof Date) {
      date = moment(date).format('DD/MM/YYYY');
    }

    if (date !== '' && date !== null && time?.isValid) {
      // Both date and time were supplied
      response = moment(date + ' ' + time.to24HourString(), 'DD/MM/YYYY HH:mm');
    } else if (date !== '' && date !== null && (time == null || !time.isValid)) {
      // Only a date was provided
      if (isArrivalDate) {
        // Set time to end of day as the date doesn't have a time
        response = moment(date + ' 23:59', 'DD/MM/YYYY HH:mm');
      } else {
        response = moment(date, 'DD/MM/YYYY');
      }
    }

    return response;
  }

  private getDateFormat(time: IllyTime): string {
    return time !== null && time?.isValid ? 'YYYY/MM/DD HH:mm:ss' : 'YYYY/MM/DD'
  }

  private getTripTypeAsInt(type: string): number | null {
    switch (type) {
      case "Ground Transport":
        return 0;
      case "Flight":
        return 1;
      case "Train":
        return 2;
      case "Accommodation":
        return 3;
      case "Rental Car":
        return 4;
    }

    return null;
  }

  updateTrip(recalculateAmountBc?: boolean): void {
    this.isUpdateProcessing = true;
    let validationError = '';

    const departureTime = !StringHelper.isNullOrEmpty(this.form.get('departureTime').value) ? IllyTime.parseString(this.form.get('departureTime').value) : null;
    const departureDate = this.formatDateTimeString(this.form.get('departureDate').value, departureTime);
    const arrivalTime = !StringHelper.isNullOrEmpty(this.form.get('arrivalTime').value) ? IllyTime.parseString(this.form.get('arrivalTime').value) : null;
    const arrivalDate = this.formatDateTimeString(this.form.get('arrivalDate').value, arrivalTime, true);

    let formIsValid = true;

    if (arrivalDate && arrivalDate.valueOf() < departureDate.valueOf()) {
      validationError = 'Arrival date is before departure date.';
      formIsValid = false;
    }

    if (!formIsValid) {
      this._alertService.showWarningAlert(validationError);
      return;
    }

    this.form.patchValue({ processing: true });

    const dto: UpdateTripDto = {
      tripType: this.getTripTypeAsInt(this.trip.type),
      groundTransportTravelType: this.trip.groundTransportTravelType,
      tripDirection: this.trip.direction,
      departureDate: departureDate ? departureDate.format(this.getDateFormat(departureTime)) : null,
      departureLocation: this.trip.departureLocation,
      departureTimeMinutes: departureTime !== null ? departureTime.totalMinutes() : null,
      arrivalDate: arrivalDate ? arrivalDate.format(this.getDateFormat(arrivalTime)) : null,
      arrivalLocation: this.trip.arrivalLocation,
      arrivalTimeMinutes: arrivalTime !== null ? arrivalTime.totalMinutes() : null,
      accommodationLocation: this.trip.accommodationLocation,
      notes: this.trip.notes,
      internalNotes: this.trip.internalNotes,
      ticketRemoved: false,
      carrier: this.form.get('carrier').value,
      bookingProvider: this.form.get('bookingProvider').value,
      paymentCardUsed: this.trip.paymentCardUsed,
      ticketConfirmationNo: this.form.get('ticketConfirmationNo').value,
      reference: this.form.get('reference').value,
      bookingStatus: this.form.get('bookingStatus').value,
      invoiceNo: this.form.get('invoiceNo').value,
      quotedAmount: this.form.get('quotedAmount').value,
      quotedCurrency: this.form.get('quotedCurrency').value,
      overBudget: this.trip.overBudgetRequestRaised,
      quotedAmountBC: this.form.get('quotedAmountBc').value,
      recalculateAmountBc: recalculateAmountBc
    };

    // Update the trip and upload any attachments (if any)
    this._patientService.updateTrip(this.trip.id, dto).subscribe({
      next: trip => {
        this.isUpdateProcessing = false;
        if (!trip.updateSucceeded) {
          this.recalculateAmountBcModal.show();
          return;
        }

        this.recalculateAmountBcModal.hide();
        this.trip.quotedAmountBC = trip.quotedAmountBC,

          this.form.patchValue({
            quotedAmountBc: trip.quotedAmountBC ? (trip.quotedAmountBC / 100).toFixed(2).toString() : '',
          });

        LogHelper.log('Saving trip at index ' + this.tripIndex);
        this.addClassToParent('saved');

        setTimeout(() => {
          this.removeClassFromParent('saved');
        }, 1000);
      },
      error: error => {
        this.isUpdateProcessing = false;
        LogHelper.log(error);
        this.form.patchValue({ processing: false });
        this._alertService.showWarningAlert(`Unable to update trip: ${error.error.title}`);
      }
    });
  }

  getTripTypeIcon(type: string): string {
    switch (type) {
      case "Ground Transport":
        return "fas fa-taxi-bus";
      case "Rental Car":
        return "fas fa-taxi";
      case "Flight":
        return "fas fa-plane";
      case "Train":
        return "fas fa-train";
      case "Accommodation":
        return "fas fa-bed";
    }
  }
}
