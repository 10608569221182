<app-modal-v2 #modal class="trips-over-budget-request-modal" [tabulated]="true" [viewPort]="viewPort">
  <div class="modal-header">
    <h2 class="modal-title">Over budget request</h2>
  </div>
  <div class="modal-body" *ngIf="overBudgetRequestDetailsForTripsViewModel">
    <div class="visit-details">
      <div class="visit-detail">
        <label>Visit Title</label>
        <span>{{overBudgetRequestDetailsForTripsViewModel.visitTitle}}</span>
      </div>
      <div class="visit-detail">
        <label>Visit Date</label>
        <span>{{overBudgetRequestDetailsForTripsViewModel.visitDate | date: 'dd MMM yyyy'}}</span>
      </div>
      <div class="visit-detail">
        <label>Caregiver Travelling?</label>
        <span>{{overBudgetRequestDetailsForTripsViewModel.careGiverTravelling | enumToText:CaregiverTravelling}}</span>
      </div>
    </div>
    <app-tabulated-content #tabulatedContent (onTabChange)="submitAvailable = $event === tabs.length - 1" [inModal]="true" [tabs]="tabs" [showNavButtons]="true">
      <section *ngFor="let typeGroup of overBudgetRequestDetailsForTripsViewModel.tripTypeGroups">
        <div *ngFor="let typeSubGroup of typeGroup.subGroups; let i = index">
          <div class="trips-details">
            <label class="ground-transport-type" *ngIf="typeSubGroup.groundTransportTravelType !== null">
              {{typeSubGroup.groundTransportTravelType | enumToText: GroundTransportTravelType}}
            </label>
            <table class="pgo-data-grid full-width">
              <thead>
                <tr>
                  <th>
                    <ng-container [ngSwitch]="typeGroup.type">
                      <ng-container *ngSwitchCase="PatientTripType.Accommodation">Check-in:</ng-container>
                      <ng-container *ngSwitchCase="PatientTripType.RentalCar">Pick-up:</ng-container>
                      <ng-container *ngSwitchDefault>Departure:</ng-container>
                    </ng-container>
                  </th>
                  <th>
                    <ng-container [ngSwitch]="typeGroup.type">
                      <ng-container *ngSwitchCase="PatientTripType.Accommodation">Check-out:</ng-container>
                      <ng-container *ngSwitchCase="PatientTripType.RentalCar">Drop-off:</ng-container>
                      <ng-container *ngSwitchDefault>Arrival:</ng-container>
                    </ng-container>
                  </th>
                  <th>Actual Cost</th>
                  <th>Direction</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let trip of typeSubGroup.tripsDetails">
                  <td>{{trip.startDate | date: 'dd MMM yy'}}</td>
                  <td>{{trip.endDate | date: 'dd MMM yy'}}</td>
                  <td>{{trip.amount}} {{trip.currency}}</td>
                  <td>{{trip.tripDirection !== null ? (trip.tripDirection | enumToText: PatientTripDirection) : ''}}</td>
                </tr>
              </tbody>
            </table>
            <div class="category-specific-text">
              <p>{{typeSubGroup.specificCategoryTrialPolicyText}}</p>
            </div>
            <form
              [formGroup]="overBudgetRequestForms[typeGroup.type.toString()+typeSubGroup.groundTransportTravelType?.toString()]">
              <div class="row trips-summary">
                <div class="col-md-7 required-information">
                  <label [ngClass]="{'required': typeSubGroup.specificCategoryTrialPolicyText}">Required Information</label>
                  <textarea placeholder="Enter required information..." class="form-control w-100"
                    formControlName="requiredInformation"></textarea>
                </div>
                <div class="col-md-5 cost-wrapper">
                  <div class="cost actual-cost">
                    <label>Actual Cost Policy Currency</label>
                    <span>{{typeSubGroup.actualCostInBudgetCurrency}}</span>
                    <span>{{typeSubGroup.budgetCurrency}}</span>
                  </div>
                  <div class="cost budget-cost">
                    <label>Policy Budget Limit</label>
                    <input class="form-control" type="number" formControlName="budgetCost" />
                    <span>{{typeSubGroup.budgetCurrency}}</span>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </app-tabulated-content>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="hideModal()">Cancel</button>
    <button class="btn btn-primary" [disabled]="!submitAvailable || isAnyFormInvalid || loading"
      (click)="submitOverBudgetRequest()">
      <span *ngIf="loading">
        <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
      </span>
      <span *ngIf="!loading">Submit</span>
    </button>
  </div>
</app-modal-v2>
