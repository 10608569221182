import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-trial-role-toggle',
  templateUrl: './trial-role-toggle.component.html',
  styleUrl: './trial-role-toggle.component.scss'
})
export class TrialRoleToggleComponent {
  @Output('changed') changed = new EventEmitter<boolean>();

  @Input('checked') checked: boolean = false;

  onToggle() {
    this.checked = !this.checked;
    this.changed.emit(this.checked);
  }
}
