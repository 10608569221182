import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrialsListComponent } from './trials-list/trials-list.component';
import { SharedModule } from "../../shared/shared.module";
import { TrialAddComponent } from './trial-add/trial-add.component';
import { TrialEditComponent } from './trial-edit/trial-edit.component';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TrialDetailsComponent } from './trial-details/trial-details.component';
import { TrialAssignSiteComponent } from "./trial-details/trial-sites/trial-assign-site/trial-assign-site.component";
import { TrialChangeLogComponent } from "./trial-details/trial-change-log/trial-change-log.component";
import { TrialSitesComponent } from "./trial-details/trial-sites/trial-sites.component";
import { TrialNewSiteComponent } from "./trial-details/trial-sites/trial-new-site/trial-new-site.component";
import { TrialPatientsComponent } from "./trial-details/trial-patients/trial-patients.component";
import { TrialPoliciesComponent } from "./trial-details/trial-policies/trial-policies.component";
import { TrialContactsComponent } from './trial-details/trial-contacts/trial-contacts.component';
import { TrialReportComponent } from './trial-report/trial-report.component';
import { TrialInfoComponent } from './trial-details/trial-info/trial-info.component';
import { TrialPaymentsComponent } from './trial-details/trial-payments/trial-payments.component';
import { TrialPolicyAddComponent } from './trial-details/trial-policies/trial-policy-add/trial-policy-add.component';
import { TrialPolicyRowComponent } from './trial-details/trial-policies/trial-policy-row/trial-policy-row.component';
import { TrialPolicyEditComponent } from './trial-details/trial-policies/trial-policy-edit/trial-policy-edit.component';
import { TrialUpdateSiteComponent } from './trial-details/trial-sites/trial-update-site/trial-update-site.component';
import { AdminModule } from '../admin/admin.module';
import {
  ManageSiteContactsComponent
} from './trial-details/trial-sites/manage-site-contacts/manage-site-contacts.component';
import {
  TrialAssignPatientModalComponent
} from './trial-details/trial-assign-patient-modal/trial-assign-patient-modal.component';
import { TrialMailboxComponent } from './trial-details/trial-mailbox/trial-mailbox.component';
import { TrialVisitScheduleComponent } from './trial-details/trial-visit-schedule/trial-visit-schedule.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { TrialBookingsComponent } from "./trial-bookings/trial-bookings.component";
import {
  TrialBookingConfigModalComponent
} from "./trial-bookings/trial-bookings-config/trial-booking-config-modal/trial-booking-config-modal.component";
import { TrialBookingsConfigComponent } from "./trial-bookings/trial-bookings-config/trial-bookings-config.component";
import { TrialUsersComponent } from './trial-details/trial-users/trial-users.component';
import {
  CreateExpenseRuleModalComponent
} from './trial-details/trial-policies/create-expense-rule-modal/create-expense-rule-modal.component';
import {
  UpdateExpenseRuleModalComponent
} from './trial-details/trial-policies/update-expense-rule-modal/update-expense-rule-modal.component';
import {
  TrialPolicyMileageRatesComponent
} from './trial-details/trial-policies/trial-policy-mileage-rates/trial-policy-mileage-rates.component';
import {
  TrialPolicyExpenseRulesComponent
} from './trial-details/trial-policies/trial-policy-expense-rules/trial-policy-expense-rules.component';
import {
  CreateMileageRateRuleModalComponent
} from './trial-details/trial-policies/create-mileage-rate-rule-modal/create-mileage-rate-rule-modal.component';
import {
  UpdateMileageRateRuleModalComponent
} from './trial-details/trial-policies/update-mileage-rate-rule-modal/update-mileage-rate-rule-modal.component';
import { MatIconModule } from '@angular/material/icon';
import {
  TrialPolicyRulesListComponent
} from './trial-details/trial-policies/trial-policy-rules-list/trial-policy-rules-list.component';
import {
  TrialPolicyMileageRatesListComponent
} from './trial-details/trial-policies/trial-policy-mileage-rates-list/trial-policy-mileage-rates-list.component';
import { VisitModule } from "../visit/visit.module";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { TrialIntegrationComponent } from "./trial-details/trial-integration/trial-integration.component";
import { NgxEditorModule } from "ngx-editor";
import { TrialRoleToggleComponent } from "./trial-details/trial-users/trial-role-toggle/trial-role-toggle.component";

@NgModule({
  declarations: [
    TrialsListComponent,
    TrialAddComponent,
    TrialEditComponent,
    TrialDetailsComponent,
    TrialAssignSiteComponent,
    TrialSitesComponent,
    TrialNewSiteComponent,
    TrialPatientsComponent,
    TrialPoliciesComponent,
    TrialChangeLogComponent,
    TrialContactsComponent,
    TrialReportComponent,
    TrialInfoComponent,
    TrialPaymentsComponent,
    TrialPolicyAddComponent,
    TrialPolicyRowComponent,
    TrialPolicyEditComponent,
    TrialUpdateSiteComponent,
    ManageSiteContactsComponent,
    TrialAssignPatientModalComponent,
    TrialVisitScheduleComponent,
    TrialMailboxComponent,
    TrialBookingsComponent,
    TrialBookingConfigModalComponent,
    TrialBookingsConfigComponent,
    TrialUsersComponent,
    TrialPolicyExpenseRulesComponent,
    CreateExpenseRuleModalComponent,
    UpdateExpenseRuleModalComponent,
    TrialPolicyMileageRatesComponent,
    CreateMileageRateRuleModalComponent,
    UpdateMileageRateRuleModalComponent,
    TrialPolicyRulesListComponent,
    TrialPolicyMileageRatesListComponent,
    TrialIntegrationComponent,
    TrialRoleToggleComponent
  ],
    imports: [
        CommonModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        DragDropModule,
        AdminModule,
        MatInputModule,
        MatIconModule,
        VisitModule,
        FormsModule,
        NgxEditorModule
    ],
  exports: [
    TrialPolicyRulesListComponent,
    TrialPolicyMileageRatesListComponent
  ]
})
export class TrialModule { }
