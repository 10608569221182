import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TrialAdminUser } from "../../../../core/models/trial-admin-user.model";
import { SelectOption } from "../../../../core/models/select-option.model";
import { LogHelper } from "../../../../core/helpers/log.helper";
import { AdminService } from "../../../../core/services/admin.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { TrialService } from "../../../../core/services/trial.service";

@Component({
  selector: 'app-trial-users',
  templateUrl: './trial-users.component.html',
  styleUrls: ['./trial-users.component.scss']
})
export class TrialUsersComponent implements OnInit, AfterViewInit {
  @ViewChild('assignCmsUserModal') assignCmsUserModal: ModalComponent;
  @ViewChild('unassignCmsUserModal') unassignCmsUserModal: ModalComponent;

  @Input('trialId') trialId: string;
  @Input('trialUsers') trialUsers: TrialAdminUser[] = [];

  @Output('refreshTrial') refreshTrial = new EventEmitter();
  @Output('userRoleChanged') userRoleChanged = new EventEmitter<TrialAdminUser>();

  filteredTrialUsers: TrialAdminUser[] = [];

  pageSize = 25;
  currentPage = 1;
  pageCount = 1;
  unassignInProgress = false;

  // Used for assign CMS user dropdown
  allCmsUsers: SelectOption[] = [];
  filteredCmsUsers: SelectOption[] = [];

  assignCmsUserForm: UntypedFormGroup;
  deletingCmsUserId: string;
  deletingCmsUserName: string;

  searchForm: UntypedFormGroup;

  constructor(private _adminService: AdminService, private _alertService: AlertService, private _trialService: TrialService) { }

  ngOnInit(): void {
    this.searchForm = new UntypedFormGroup({
      searchInput: new UntypedFormControl('')
    });

    this.assignCmsUserForm = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      cmsUserId: new UntypedFormControl('', Validators.required),
      projectManager: new UntypedFormControl(false),
      designatedContact: new UntypedFormControl(false)
    });

    this.loadAvailableCmsUsers();
  }

  ngAfterViewInit() {
    this.filteredTrialUsers = this.trialUsers;
    this.recalculatePageCount();
  }

  /**
   * Unassigns a CMS user.
   *
   * @param {string} id - The ID of the CMS user to unassign.
   * @param {string} name - The name of the CMS user to unassign.
   * @return {void}
   */
  onUnassignCmsUser(id: string, name: string): void {
    this.deletingCmsUserId = id;
    this.deletingCmsUserName = name;
    this.unassignCmsUserModal.show();
  }

  /**
   * Updates the filteredTrialUsers array based on the provided searchInput.
   *
   * @param {string} searchInput The search input value entered by the user.
   *
   * @return {void}
   */
  onSearchInputChanged(searchInput: string): void {
    LogHelper.log('searchInputChanged ' + searchInput)
    this.filteredTrialUsers = this.trialUsers.filter(u => u.name.toLowerCase().includes(searchInput.toLowerCase()));
  }

  /**
   * Updates the current page to the specified page number.
   *
   * @param {number} page - The page number to be selected.
   * @return {void}
   */
  onPageSelected(page = 1): void {
    this.currentPage = page;
  }

  /**
   * Handles the confirmation of unassigning a CMS user.
   *
   * @returns {void}
   */
  onConfirmUnassignCmsUser(): void {
    this.unassignInProgress = true;

    this._trialService.unassignAdministrator(this.trialId, this.deletingCmsUserId).subscribe({
      next: () => {
        this._alertService.showSuccessAlert('CMS user unassigned successfully!');
        this.trialUsers = this.trialUsers.filter(u => u.id !== this.deletingCmsUserId);
        this.onSearchInputChanged(this.searchForm.controls.searchInput.value);
        this.recalculatePageCount();
        this.unassignCmsUserModal.hide();
        this.refreshTrial.emit();
      },
      error: error => {
        this._alertService.showErrorAlert(error);
      },
      complete: () => {
        this.unassignInProgress = false;
      }
    });
  }

  /**
   * Get CMS users for a specific page.
   *
   * @param {number} page - The page number.
   * @returns {Array} - The CMS users for the given page.
   */
  getCmsUsersForPage(page = 1) {
    const start = (page - 1) * this.pageSize;
    const end = start + this.pageSize;

    return this.filteredTrialUsers.slice(start, end);
  }

  /**
   * Recalculates the total page count based on the number of trial users and page size.
   * @private
   * @returns {void}
   */
  private recalculatePageCount(): void {
    this.pageCount = Math.ceil(this.trialUsers.length / this.pageSize);

    if (this.getCmsUsersForPage(this.currentPage).length === 0) {
      this.currentPage--;

      if (this.currentPage < 1)
        this.currentPage = 1;
    }
  }

  onRoleToggled(administratorId: string, projectManager: boolean, designatedContact: boolean): void {
    this._trialService.assignAdministrator(this.trialId, administratorId, projectManager, designatedContact).subscribe({
      next: rsp => {
        this._alertService.clearAll();
        this._alertService.showSuccessAlert('Administrator role updated');
        this.userRoleChanged.emit(new TrialAdminUser({id: administratorId, projectManager: projectManager, designatedContact: designatedContact}));
      },
      error: err => {
        this._alertService.clearAll();
        this._alertService.showWarningAlert('There was a problem setting the administrator role, please try again!');
      }
    })
  }

  /**
   * Loads the available CMS (Content Management System) users.
   * Calls the 'retrieveAdminUsers' method of the '_adminService' object to get a list of admin users.
   * Populates the 'allCmsUsers' array with objects containing the id and full name of each admin user.
   * Displays a warning alert if there is an error while loading the users.
   *
   * @returns {void}
   */
  loadAvailableCmsUsers(): void {
    this._adminService.retrieveAdminUsers(
      1,
      9999,
      "ProductAdministrator,ProjectCoordinatorExpenses,ProjectCoordinatorTravel,StartupCoordinator,ProjectManager").subscribe({
      next: results => {
        for (let result of results.results) {
          this.allCmsUsers.push({value: result.id, text: result.firstname + ' ' + result.lastname});
        }
      }, error: error => {
        LogHelper.log(error);
        this._alertService.showWarningAlert('There was a problem loading coordinators, please try again!');
      }
    });
  }

  /**
   * Resets the assignCmsUserForm and shows the modal window for assigning CMS users.
   *
   * @return {void}
   */
  onShowAssignCmsUserModal() {
    this.assignCmsUserForm.reset();

    // Only show CMS users that are not already assigned to the trial
    this.filteredCmsUsers = this.trialUsers !== undefined && this.trialUsers !== null ? this.allCmsUsers.filter(t => !this.trialUsers.some(u => u.id === t.value)) : this.allCmsUsers;

    // Show the modal window
    this.assignCmsUserModal.show();
  }

  /**
   * Called when the user filters the list of available CMS in the assign CMS user modal window
   * @param searchInput
   */
  filterAssignableCmsUsers(searchInput: string) {
    this.filteredCmsUsers = this.trialUsers !== undefined && this.trialUsers !== null ? this.allCmsUsers.filter(t => !this.trialUsers.some(u => u.id === t.value)) : this.allCmsUsers;

    this.filteredCmsUsers = this.filteredCmsUsers.filter((user) => user.text.toLowerCase().includes(searchInput.toLowerCase()));
  }

  /**
   * Submits the assign form for assigning a CMS user as an administrator to a trial.
   *
   * @return {void}
   */
  onSubmitAssignForm() {
    let form = this.assignCmsUserForm;

    if (form.valid) {
      form.controls.processing.setValue(true);

      this._trialService.assignAdministrator(this.trialId, form.controls.cmsUserId.value, form.controls.projectManager.value, form.controls.designatedContact.value).subscribe({
        next: (rsp) => {
          this._alertService.showSuccessAlert('CMS user assigned successfully!');
          this.assignCmsUserModal.hide();
          if (this.trialUsers === undefined || this.trialUsers === null)
            this.trialUsers = [];
          this.trialUsers.push(new TrialAdminUser({id: rsp.administratorId, name: rsp.name, email: rsp.email, projectManager: rsp.projectManager, designatedContact: rsp.designatedContact}));
          this.trialUsers.sort((a, b) => a.name.localeCompare(b.name));
          this.recalculatePageCount();
        }, error: error => {
          LogHelper.log(error);
          this._alertService.showWarningAlert('There was a problem assigning the user, please try again!');
        }, complete: () => {
          form.controls.processing.setValue(false);
        }
      });
    }
  }

}
