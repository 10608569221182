<div class="view-head">
  <div class="row">

    <div class="col-12 text-center">
      <h2 class="heading-small">Assign New Site</h2>
    </div>

  </div>
</div>

<div class="view-body">

  <div class="row">
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

      <div class="content-panel">

        <form [formGroup]="form">

          <table class="simple-form">
            <tr>
              <th><label>Site Name</label></th>
              <td>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="name">
                </div>
              </td>
            </tr>
            <tr>
              <th><label>Global IRG Site Id</label></th>
              <td>
                <div class="form-group">
                  <input type="text" class="form-control" formControlName="irgSiteId">
                </div>
              </td>
            </tr>
            <tr>
              <th class="valign-top"><label>Site Address</label></th>
              <td>
                <div class="form-group">
                  <app-input-address [parentForm]="form" controlName="siteAddress" [countryRequired]="true"></app-input-address>
                </div>
              </td>
            </tr>
            <tr>
              <th><label>Timezone</label></th>
              <td>
                <div class="form-group">
                  <app-timezone-select #timezoneSelect [parentForm]="form" controlName="timezone"></app-timezone-select>
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <td>
                <div class="checkbox-wrap last">
                  <div class="form-group">
                    <label class="checkbox">
                      <input type="checkbox" id="apiConsumer" formControlName="apiConsumer" value="true">
                      <span></span>
                    </label>
                    <label class="checkbox-label" for="apiConsumer">Site belongs to API consumer?</label>
                  </div>
                </div>
              </td>
            </tr>
            <tr [ngClass]="{'visibility-hidden': !form.get('apiConsumer').value}">
              <th><label for="visit-count">API Consumer</label></th>
              <td>
                <div class="form-group">
                  <app-dropdown-input #apiConsumerSelect id="apiConsumerId" [options]="apiConsumerOptions"
                                      [parentForm]="form" name="apiConsumerId" placeholder="Select Api Consumer..."></app-dropdown-input>
                </div>
              </td>
            </tr>
            <tr>
              <th></th>
              <td>
                <div class="btn-wrap">
                  <div class="row">
                    <div class="col-6">
                      <a routerLink="/trial/{{ trialId }}/site/assign" class="btn btn-secondary">Cancel</a>
                    </div>
                    <div class="col-6">
                      <button class="btn btn-primary" (click)="onFormSubmit()" [disabled]="!form.valid">
                        <span *ngIf="isFormProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="sr-only">Loading...</span>
                          </span>
                        <span *ngIf="!isFormProcessing">Add</span>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>

        </form>

      </div>

    </div>
  </div>

</div>
