<app-modal #modal [customClassName]="'over-budget-request-modal'">
  <div *ngIf="overBudgetRequestDetailsViewModel">
    <div class="modal-header">
      <h2>Over budget request</h2>
    </div>
    <div id="modalBody" class="modal-body">
      <form [formGroup]="overBudgetRequestForm">
        <div class="request-details" *ngIf="ruleExists">
          <label>Rule name:<span>{{overBudgetRequestDetailsViewModel.ruleName}} </span></label>
          <label>Categories:<span>{{overBudgetRequestDetailsViewModel.categories.join(", ")}}</span></label>
        </div>
        <div class="over-budget-expenses">
          <table class="pgo-data-grid full-width">
            <thead>
              <tr>
                <th></th>
                <th>Category</th>
                <th>Date</th>
                <th>Status</th>
                <th>Amount</th>
                <th>Currency</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let expense of overBudgetRequestDetailsViewModel.expenseClaims; let i = index"
                [ngClass]="{'grey-background': i % 2 === 1}">
                <td><mat-icon *ngIf="expense.receiptMissing">warning</mat-icon></td>
                <td>
                  {{expense.category}}
                  <app-expense-images *ngIf="!expense.receiptMissing"
                    [imagery]="expense.receiptUrls"></app-expense-images>
                </td>
                <td [ngClass]="{'align-top': !expense.receiptMissing}">{{expense.created | date: 'dd/MM/yy'}}</td>
                <td [ngClass]="{'align-top': !expense.receiptMissing}">
                  <label class="badge" [ngClass]="getBadgeClass(expense.state)">{{expense.state | enumToText:
                    ExpenseClaimState}}</label>
                </td>
                <td [ngClass]="{'align-top': !expense.receiptMissing}">{{expense.amount / 100}}</td>
                <td [ngClass]="{'align-top': !expense.receiptMissing}">{{expense.currency}}</td>
              </tr>
            </tbody>
          </table>
          <div class="over-budget-additional-information">
            <div class="additional-information">
              <div class="info">
                <label>Caregiver Travelling ?</label>
                <span>{{overBudgetRequestDetailsViewModel.careGiver | enumToText:CaregiverTravelling}}</span>
              </div>
              <div class="info">
                <label>Blank approval for no receipts ?</label>
                <span>{{overBudgetRequestDetailsViewModel.blanketApprovalForNoReceipts ? 'Yes' : 'No'}}</span>
              </div>
            </div>
            <div class="over-budget-summary">
              <div class="costs-wrapper">
                <label>Actual Costs</label>
                <div class="costs-group">
                  <div class="costs" *ngFor="let cost of overBudgetRequestDetailsViewModel.budgetDetails">
                    <div class="cost">
                      <span>{{cost.actualCost}}</span>
                      <span>{{cost.localCurrency}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="costs-wrapper">
                <label>Actual Cost Policy Currency</label>
                <div class="cost border-bottom-0">
                  <input class="form-control" [ngClass]="{'field-disabled': overBudgetRequestDetailsViewModel.actualBudgetCost !== null}"
                      type="number" formControlName="actualCostInBudgetCurrency" />
                  <span>{{overBudgetRequestDetailsViewModel.actualBudgetCostCurrency}}</span>
                </div>
              </div>
              <div class="costs-wrapper">
                <label>Policy Budget Limit</label>
                <div class="cost gap-none">
                  <input class="form-control" type="number" formControlName="budgetCost"
                    [ngClass]="{'field-disabled': ruleExists}" />
                  <span class="budget-currency">{{overBudgetRequestDetailsViewModel.actualBudgetCostCurrency}}</span>
                </div>
              </div>
            </div>
          </div>
          <p class="over-budget-requirement" *ngIf="overBudgetRequestDetailsViewModel.overBudgetRequirement">
            {{overBudgetRequestDetailsViewModel.overBudgetRequirement}}</p>
          <div class="form-group required-information">
            <label [ngClass]="{'required': overBudgetRequestDetailsViewModel.overBudgetRequirement !== null}">Required
              information</label>
            <textarea class="form-control w-100" formControlName="requiredInformation"></textarea>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button class="btn btn-secondary" (click)="hideModal()">Cancel</button>
      <button class="btn btn-primary" [disabled]="overBudgetRequestForm.invalid || loading" (click)="submitOverBudgetRequest()">
        <span *ngIf="loading">
          <span aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
        </span>
        <span *ngIf="!loading">Submit</span>
      </button>
    </div>
  </div>
</app-modal>
