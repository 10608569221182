<app-modal #modalWindow customClassName="size-md">
  <div class="modal-header">
    <h2 class="modal-title">Edit API Consumer</h2>
  </div>
  <div class="modal-body">

    <form [formGroup]="form" (submit)="onFormSubmit()">

      <table class="simple-form">
        <tr>
          <th><label>Consumer Name</label></th>
          <td>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="name">
            </div>
          </td>
        </tr>
        <tr>
          <th><label>Webhook Url</label></th>
          <td>
            <div class="form-group">
              <input type="text" class="form-control" formControlName="webhookUrl">
            </div>
          </td>
        </tr>
        <tr>
          <th><label for="visit-count">Webhook Version</label></th>
          <td>
            <div class="form-group">
              <app-dropdown-input #webhookVersionSelect id="webhookVersion" [options]="webhookVersions"
                                  [parentForm]="form" name="webhookVersion" placeholder="Select Version..."></app-dropdown-input>
            </div>
          </td>
        </tr>
        <tr>
          <th></th>
          <td>
            <div class="checkbox-wrap last">
              <div class="form-group">
                <label class="checkbox">
                  <input type="checkbox" id="enabled" formControlName="enabled" value="true">
                  <span></span>
                </label>
                <label class="checkbox-label" for="apiConsumer">Enabled</label>
              </div>
            </div>
          </td>
        </tr>

      </table>

    </form>

  </div>
  <div class="modal-footer">
    <div class="row">
      <div class="col-6">
        <a (click)="modalWindow.hide()" class="btn btn-secondary" data-dismiss="modal">Cancel</a>
      </div>

      <div class="col-6">
        <button type="button" (click)="onFormSubmit()" class="btn btn-primary" [disabled]="isProcessing || !form.valid">
          <span *ngIf="isProcessing">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </span>
          <span *ngIf="!isProcessing">Save API Consumer</span>
        </button>
      </div>
    </div>
  </div>
</app-modal>
