import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { ReactiveFormsModule } from "@angular/forms";
import { PatientApprovedComponent } from './patient-list/patient-approved/patient-approved.component';
import { PatientPendingComponent } from './patient-list/patient-pending/patient-pending.component';
import { PatientRejectedComponent } from './patient-list/patient-rejected/patient-rejected.component';
import {
  PatientDeletionRequestComponent
} from './patient-list/patient-deletion-request/patient-deletion-request.component';
import { RouterModule } from "@angular/router";
import { PatientDetailVisitsComponent } from './patient-detail/patient-detail-visits/patient-detail-visits.component';
import {
  PatientDetailExpensesComponent
} from './patient-detail/patient-detail-expenses/patient-detail-expenses.component';
import {
  PatientDetailAdditionalComponent
} from './patient-detail/patient-detail-additional/patient-detail-additional.component';
import { PatientDetailNotesComponent } from './patient-detail/patient-detail-notes/patient-detail-notes.component';
import {
  PatientDetailChangeLogComponent
} from './patient-detail/patient-detail-change-log/patient-detail-change-log.component';
import {
  PatientVisitDetailComponent
} from './patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { LightboxModule } from "ngx-lightbox";
import {
  PatientDetailActivityComponent
} from './patient-detail/patient-detail-activity/patient-detail-activity.component';
import { MomentModule } from "ngx-moment";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { PatientPaymentInfoComponent } from './patient-detail/patient-payment-info/patient-payment-info.component';
import { PatientFeedbackComponent } from "./patient-list/patient-feedback/patient-feedback.component";
import {
  PatientFeedbackRowComponent
} from './patient-list/patient-feedback/patient-feedback-row/patient-feedback-row.component';
import { SharedModule } from "../../shared/shared.module";
import {
  PatientBankCoverFeeComponent
} from './patient-detail/patient-payment-info/patient-bank-cover-fee/patient-bank-cover-fee.component';
import { NgxMaskModule } from 'ngx-mask';
import { ExpenseModule } from '../expense/expense.module';
import { VisitModule } from "../visit/visit.module";
import { PatientRegisterComponent } from './patient-register/patient-register.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { PatientRegistrationsComponent } from './patient-list/patient-registrations/patient-registrations.component';
import { CoreModule } from "../../core/core.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { NgxMatInputTelComponent } from "ngx-mat-input-tel";
import { PatientDiaryComponent } from "./patient-detail/patient-diary/patient-diary.component";
import { DiaryConfigModalComponent } from "./patient-detail/diary-config-modal/diary-config-modal.component";

@NgModule({
  declarations: [
    PatientDetailComponent,
    PatientListComponent,
    PatientApprovedComponent,
    PatientPendingComponent,
    PatientRejectedComponent,
    PatientDeletionRequestComponent,
    PatientDetailVisitsComponent,
    PatientDetailExpensesComponent,
    PatientDetailAdditionalComponent,
    PatientDetailNotesComponent,
    PatientDetailChangeLogComponent,
    PatientVisitDetailComponent,
    PatientDetailActivityComponent,
    PatientPaymentInfoComponent,
    PatientFeedbackComponent,
    PatientFeedbackComponent,
    PatientFeedbackRowComponent,
    PatientBankCoverFeeComponent,
    PatientRegisterComponent,
    PatientRegistrationsComponent,
    PatientDiaryComponent,
    DiaryConfigModalComponent
  ],
    imports: [
        LightboxModule,
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        RouterModule,
        DragDropModule,
        MatAutocompleteModule,
        MatInputModule,
        MomentModule,
        ClipboardModule,
        NgxMaskModule,
        ExpenseModule,
        VisitModule,
        AngularSignaturePadModule,
        CoreModule,
        NgxSkeletonLoaderModule,
        NgxMatInputTelComponent,
        ReactiveFormsModule,
        ReactiveFormsModule,
        ReactiveFormsModule,
        DatePipe
    ]
})
export class PatientModule { }
