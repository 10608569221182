export class ApiConsumer {
  public id: string;
  public name: string;
  public enabled: boolean;
  public webhookUrl: string;
  public webhookVersion: string;
  public apiKey: string;

  constructor(init?: Partial<ApiConsumer>) {
    Object.assign(this, init);
  }
}
