import { Permissions } from './../../../core/constants/permissions';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TravelRequestService } from "../../../core/services/travel-request.service";
import { AuthService } from "../../../core/services/auth.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { TravelResult } from "../../../core/models/travel-results.model";
import { TemplateService } from "../../../core/services/template.service";
import { DateHelper } from "../../../core/helpers/date-helper";
import { DropdownInputComponent } from 'app/shared/dropdown-input/dropdown-input.component';
import { SelectOption } from 'app/core/models/select-option.model';
import { LogHelper } from 'app/core/helpers/log.helper';
import { TravelRequest } from 'app/core/models/travel-request.model';

@Component({
  selector: 'app-travel-request-list',
  templateUrl: './travel-request-list.component.html'
})
export class TravelRequestListComponent implements OnInit, AfterViewInit {
  searchForm: UntypedFormGroup;
  keywords: string;
  page: number = 1;
  results: TravelResult;
  Permissions = Permissions;
  orderByOptions: SelectOption[] = [];
  dataLoaded: boolean = false;
  loaderArray: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  @ViewChild('orderBySelect') orderBySelect: DropdownInputComponent;

  constructor(public authService: AuthService, private readonly templateService: TemplateService, private readonly alertService: AlertService,
    private readonly travelRequestService: TravelRequestService, private readonly router: Router, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.searchForm = new UntypedFormGroup({
      keywords: new UntypedFormControl('')
    });

    this.initOrderBySelection();
    this.loadTravel(1, null);
  }

  ngAfterViewInit(): void {
    this.orderBySelect.setValue("0");
    this.subscribeOrderByChanges();
    this.cd.detectChanges();
  }

  subscribeOrderByChanges() {
    this.orderBySelect?.selectValueChanged.subscribe(() => {
      this.loadTravel(1, this.keywords);
    })
  }

  initOrderBySelection() {
    this.orderByOptions.push({ value: '0', text: 'Trial' });
    this.orderByOptions.push({ value: '1', text: 'Patient' });
    this.orderByOptions.push({ value: '2', text: 'Newest' });
    this.orderByOptions.push({ value: '3', text: 'Oldest' });
  }

  loadTravel(page: number, keywords: string) {
    this.dataLoaded = false;
    this.page = page;
    this.keywords = keywords;
    let orderBy = this.orderBySelect ? +this.orderBySelect.selectedValue : 0;
    this.travelRequestService.retrieveTravelRequests(page, keywords, null, 0, orderBy).subscribe({
      next: results => {
        this.results = results;
        this.dataLoaded = true;
      },
      error: (error) => {
        LogHelper.log(error);
        this.dataLoaded = true;
        this.alertService.showWarningAlert('There was a problem loading results!');
      }
    });
  }

  /**
   * Converts time in minutes to a human readable string
   * @param visitTimeMinutes
   */
  visitTime(visitTimeMinutes: number): string {
    if (visitTimeMinutes == null) {
      return '';
    }

    return DateHelper.minutesToTimeStr(visitTimeMinutes);
  }

  /**
   * Called when the value of the search input changes, it loads trials based on the value of the field
   */
  onSearchInputChanged(keywords: string) {
    this.keywords = keywords;

    this.loadTravel(1, keywords);
  }

  onRemoveClick(requestId: string) {
    this.travelRequestService.removeRequest(requestId).subscribe({
      next: () => {
        this.loadTravel(this.results.currentPage, this.keywords);
      },
      error: () => {
        this.alertService.showWarningAlert('There was a problem removing the request!');
      }
    });
  }

  onRequestClick(patientId: string, visitId: string) {
    this.router.navigate(['/patient', patientId, 'visit', visitId]);
  }

  setWip(travelRequest: TravelRequest) {
    this.travelRequestService.setWip(travelRequest.id).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("Travel request successfully set as WIP.");
        this.loadTravel(this.page, this.keywords);
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("Travel request failed to be set as WIP. Please try again or contact support.");
      }
    })
  }

  stopWip(travelRequest: TravelRequest) {
    this.travelRequestService.stopWip(travelRequest.id).subscribe({
      next: () => {
        this.alertService.showSuccessAlert("WIP successfully stopped.");
        this.loadTravel(this.page, this.keywords);
      },
      error: err => {
        LogHelper.log(err);
        this.alertService.showWarningAlert("WIP failed to be stopped. Please try again or contact support.");
      }
    })
  }
}
