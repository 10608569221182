import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { DropdownInputComponent } from "../../../../shared/dropdown-input/dropdown-input.component";
import { SelectOption } from "../../../../core/models/select-option.model";
import { ApiConsumerService } from "../../../../core/services/api-consumer.service";
import { AlertService } from "../../../../shared/alert/alert.service";
import { TemplateService } from "../../../../core/services/template.service";
import { ApiConsumer } from "../../../../core/models/api-consumer.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-edit-api-consumer-modal',
  templateUrl: './edit-api-consumer-modal.component.html',
  styleUrl: './edit-api-consumer-modal.component.scss'
})
export class EditApiConsumerModalComponent {
  @ViewChild('modalWindow') modalWindow: ModalComponent;
  @ViewChild('webhookVersionSelect') webhookVersionSelect: DropdownInputComponent;

  @Output('updated') updated = new EventEmitter();

  form: UntypedFormGroup;
  isProcessing = false;
  webhookVersions: SelectOption[] = [];
  apiConsumer: ApiConsumer = null;

  constructor(private _apiConsumerService: ApiConsumerService, private _alertService: AlertService,
              private _templateService: TemplateService) { }

  ngOnInit() {
    this._templateService.showHeader();

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      webhookUrl: new UntypedFormControl('', Validators.required),
      webhookVersion: new UntypedFormControl('V1', Validators.required),
      enabled: new UntypedFormControl(false)
    });

    this.webhookVersions.push(new SelectOption("V0", 'V0'));
    this.webhookVersions.push(new SelectOption("V1", 'V1'));

    this.webhookVersionSelect.setValue("V1");
  }

  show(apiConsumer: ApiConsumer): void {
    this.apiConsumer = apiConsumer;

    this.form.patchValue({
      name: apiConsumer.name,
      webhookUrl: apiConsumer.webhookUrl,
      webhookVersion: apiConsumer.webhookVersion,
      enabled: apiConsumer.enabled
    });
    this.webhookVersionSelect.setValue(apiConsumer.webhookVersion);

    this.modalWindow.show();
  }

  onFormSubmit() {
    if (!this.form.valid)
      return;

    this.isProcessing = true;
    this._apiConsumerService.update(
      this.apiConsumer.id,
      this.form.get('name').value,
      this.form.get('webhookUrl').value,
      this.form.get('webhookVersion').value,
      this.form.get('enabled').value,
    ).subscribe({
      next: rsp => {
        this.isProcessing = false;
        this.updated.emit();
        this.modalWindow.hide();
        this._alertService.showSuccessAlert('API Consumer has been updated');
      },
      error: error => {
        this._alertService.showErrorAlert(error);
        this.isProcessing = false;
      }
    });

  }
}
