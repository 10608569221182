import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from '../../../../core/services/admin.service';
import { LogHelper } from '../../../../core/helpers/log.helper';
import { AuthService } from '../../../../core/services/auth.service';
import { AlertService } from '../../../../shared/alert/alert.service';
import { SettingsService } from '../../../../core/services/settings.service';
import { TemplateService } from '../../../../core/services/template.service';
import { RoleNames } from 'app/core/constants/role-names';
import { AdminUser, UpdateAdminUserRequest } from "../../../../core/models/admin-user.model";
import { ManageAdminTrialsComponent } from "../manage-admin-trials/manage-admin-trials.component";
import { AdministratorTrial } from "../../../../core/models/administrator-trial.model";
import { environment } from 'environments/environment';

@Component({
  selector: 'app-edit-admin-user',
  templateUrl: './edit-admin-user.component.html',
  styleUrls: ['./edit-admin-user.component.scss']
})
export class EditAdminUserComponent implements OnInit {
  @ViewChild('manageTrials') manageTrials: ManageAdminTrialsComponent;

  dataHasLoaded = false;
  form: UntypedFormGroup;
  isFormProcessing = false;
  updatingAdminUserId: string;
  editRolesAvailable = true;

  selectedRoles: string[] = [];
  initialRoles: string[] = [];
  availableRoles: string[] = [
    RoleNames.ProductAdministrator,
    RoleNames.ProjectCoordinatorExpenses,
    RoleNames.ProjectCoordinatorTravel,
    RoleNames.StartupCoordinator,
    RoleNames.ProjectManager
  ]
  rolesRetrieved: boolean = false;

  currentAdminId = '';
  currentUserId = '';
  selectedTab = 'info';
  hideFormButtons = false;
  administrator: AdminUser = new AdminUser();

  isMyProfile: boolean = false;

  constructor(private authService: AuthService,
    private adminService: AdminService, private alertService: AlertService,
    private router: Router, private activatedRoute: ActivatedRoute,
    private templateService: TemplateService) { }

  ngOnInit() {
    this.templateService.showHeader();

    this.form = new UntypedFormGroup({
      firstname: new UntypedFormControl('', Validators.required),
      lastname: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl({ disabled: true, value: '' }),
      muvUserId: new UntypedFormControl('')
    });

    this.activatedRoute.queryParams.subscribe(params => {
      const id = params.id;
      this.currentUserId = params.id;

      if (id == null) {
        this.alertService.showWarningAlert('User not found!');
        this.router.navigate(['/admin']);
      }

      this.adminService.retrieveAdminUser(id).subscribe({
        next: adminUser => {
          this.administrator = adminUser;
          this.manageTrials.setAdministrator(adminUser);

          this.selectedRoles = adminUser.roles;
          this.initialRoles = [...adminUser.roles];
          this.rolesRetrieved = true;
          this.updatingAdminUserId = adminUser.id;
          this.form.patchValue({
            firstname: adminUser.firstname,
            lastname: adminUser.lastname,
            email: adminUser.email,
            trialIds: adminUser.trials != null ? adminUser.trials.map(t => t.id) : [],
            muvUserId: adminUser.muvUserId
          });

          this.isMyProfile = adminUser.isCurrentAdmin && environment.environment !== "DEVELOPMENT" && environment.environment !== "STAGING";

          if (adminUser.isCurrentAdmin) {
            this.form.get("firstname").disable();
            this.form.get("lastname").disable();
          }

          this.dataHasLoaded = true;
        },
        error: error => {
          LogHelper.log(error);
          this.alertService.showWarningAlert(error);
        }
      });
    });

    this.addSystemAdminRoleToList();
  }

  addSystemAdminRoleToList() {
    if (this.authService.hasRole(RoleNames.SystemAdministrator) ||
      environment.environment === "DEVELOPMENT" ||
      environment.environment === "STAGING") {
      this.availableRoles.splice(0, 0, RoleNames.SystemAdministrator)
    }
  }

  onTabClick(selection: string) {
    this.selectedTab = selection;
  }

  onFormSubmit() {
    if (!this.form.valid)
      return;

    this.isFormProcessing = true;
    let request: UpdateAdminUserRequest = {
      id: this.updatingAdminUserId,
      firstname: this.form.get('firstname').value,
      lastname: this.form.get('lastname').value,
      roles: this.selectedRoles,
      adminTrials: this.administrator.trials,
      muvUserId: this.form.get('muvUserId').value
    }

    this.adminService.updateAdminUser(request).subscribe({
      next: () => {
        if (this.updatingAdminUserId === this.currentAdminId) {
          this.authService.getRolesFromApi().subscribe(() => {
            this.returnToAdministratorList();
          });
        } else {
          this.returnToAdministratorList();
        }
      },
      error: error => {
        this.isFormProcessing = false;
        LogHelper.log(error);
        this.alertService.showWarningAlert(error.error
          ? error.error.title
          : 'Sorry, there was a problem updating the account');
      }
    });
  }

  returnToAdministratorList() {
    this.alertService.showSuccessAlert('User successfully updated.');
    let queryParams = {
      page: this.adminService.currentPageNumber
    };
    this.router.navigate(['/admin'], { queryParams: queryParams });
  }

  selectRole(checkEvent: any, roleName: string) {
    if (checkEvent.target.checked) {
      this.selectedRoles.push(roleName);
    } else {
      this.selectedRoles = this.selectedRoles.filter(role => role !== roleName);
    }
    this.form.markAsDirty();
  }

  getRoleText(roleName: string): string {
    switch (roleName) {
      case RoleNames.SystemAdministrator:
        return "System Administrator";
      case RoleNames.ProductAdministrator:
        return "Product Administrator";
      case RoleNames.ProjectCoordinatorExpenses:
        return "Project Coordinator - Expenses";
      case RoleNames.ProjectCoordinatorTravel:
        return "Project Coordinator - Travel";
      case RoleNames.StartupCoordinator:
        return "Startup Coordinator";
      case RoleNames.ProjectManager:
        return "Project Manager";
    }
  }

  userHasRole(role: string): boolean {
    return this.selectedRoles.indexOf(role) >= 0;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onAssignTrials(adminTrials: AdministratorTrial[]): void {
    this.administrator.trials = adminTrials;
    this.form.markAsDirty();
  }

  updateFormButtonVisibility(hide: boolean): void {
    this.hideFormButtons = hide;
  }
}
