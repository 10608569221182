import { Component, forwardRef, Optional } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators
} from "@angular/forms";

@Component({
  selector: 'app-input-timepicker',
  templateUrl: './input-timepicker.component.html',
  styleUrls: ['./input-timepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimepickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTimepickerComponent),
      multi: true,
    }
  ]
})
export class InputTimepickerComponent implements ControlValueAccessor, Validator {
  time: string = '';  // The value bound to the input
  isRequired: boolean = false;

  // Inject the control container to access the form control
  constructor(@Optional() private controlContainer: ControlContainer) {}

  control: AbstractControl | null = null;

  // ControlValueAccessor methods
  onChange: any = () => {};
  onTouched: any = () => {};

  ngOnInit(): void {
    if (this.controlContainer) {
      // Get the form control from the form group
      this.control = this.controlContainer.control?.get('urineReminderTime'); // Replace 'urineReminderTime' with a dynamic way if needed

      // Check if the control has the required validator
      this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
    }
  }

  // Validator methods
  validate(control: AbstractControl): ValidationErrors | null {
    return this.isValidTime(this.time) ? null : { invalidTime: true };
  }

  writeValue(value: string): void {
    this.time = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Custom validation to ensure the input is a valid 24-hour time (HH:mm)
  isValidTime(value: string): boolean {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/; // Matches HH:mm in 24-hour format
    return regex.test(value);
  }

  // Handle changes when the user types
  onTimeChange(event: any) {
    const newValue = event.target.value;
    if (this.isValidTime(newValue)) {
      this.time = newValue;
      this.onChange(this.time);  // Notify Angular form that the value has changed
    } else {
      this.onChange(null);  // Mark the control as invalid if time is not valid
    }
  }
}
