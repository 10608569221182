<div class="input-timezone-select" [formGroup]="form">
  <div class="currency-container">
    <app-autosuggest-dropdown-input
      #timezoneSelect
      [controlName]="controlName"
      [parentForm]="form"
      [options]="allTimezones"
      [placeholder]="placeholder"
      (selectValueChanged)="onTimezoneSelectChanged($event)"
      (cleared)="onCleared()">
    </app-autosuggest-dropdown-input>
  </div>
</div>
