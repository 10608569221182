<div class="no-results" *ngIf="(results == null || results.results.length == 0) && dataLoaded">
  <div class="inner">
    <div class="image">
      <img src="assets/img/no-travel-requests.png" alt="No travel requests" />
    </div>
    <div class="content">
      <h2>No Travel Requests</h2>
    </div>
  </div>
</div>

<div>
  <div class="view-head">
    <div class="row">

      <div class="col-12 d-none d-md-block col-md-5">
        <h2>Travel Requests</h2>
      </div>

      <div class="col-12 col-md-7 text-right">
        <div class="search-container d-flex justify-content-end">
          <div class="mobile-search-icon">
            <a href="#" class="btn btn-secondary icon-only" data-toggle="search"><i class="icon icon-search"
                aria-hidden="true"></i></a>
          </div>

          <app-dropdown-input style="width: 110px; margin-right: 10px;" class="order-by-dropdown" #orderBySelect
            [options]="orderByOptions"></app-dropdown-input>

          <form [formGroup]="searchForm">
            <app-search-input name="keywords" [parentForm]="searchForm"
              (inputChange)="onSearchInputChanged($event)"></app-search-input>
          </form>
        </div>
      </div>

    </div>
  </div>

  <div class="content-panel travel-requests" *ngIf="results != null && results.results.length > 0 && dataLoaded">

    <!-- Start: Travel Request -->
    <div class="travel-request" *ngFor="let request of results.results">

      <div class="travel-request-head">
        <div class="patient">
          <span class="label"><a routerLink="/patient/{{request.patientTrialId}}">{{request.patientCode}}</a></span>
          <span class="field-value">{{request.patientName}}</span>
          <span class="field-value">{{request.patientAddress}} {{request.patientCountry}}</span>
        </div>
        <div class="trial">
          <span class="label"><a routerLink="/trial/{{request.trialId}}">{{request.trialLabel}}</a></span>
        </div>
      </div>

      <div class="travel-status">
        <span class="badge badge-warning">Travel Requested</span>
        <button (click)="setWip(request)" *ngIf="!request.isWip" class="btn btn-secondary btn-slim">Set as WIP</button>
        <button (click)="stopWip(request)" *ngIf="request.isWip" class="btn btn-secondary btn-slim btn-stop-wip">Stop
          WIP</button>
      </div>

      <table aria-hidden="true">
        <tr>
          <td>
            <span class="label">{{request.visitTitle}}</span>
            <p class="date-time">
              <span class="field-value date">{{ request.visitDate | date: 'd MMM yyyy' }}</span>
              <span class="field-value time">{{ visitTime(request.visitTimeMinutes) }}</span>
            </p>
            <span *ngIf="request.isWip" class="label submitted-date">
              Set as WIP by {{ request.updatingAdministratorFirstName }} {{ request.updatingAdministratorLastName }}
              on {{ request.wipUpdateDate | date: 'd MMM yyyy' }} at {{ request.wipUpdateDate| date: 'h:mm aaa'}}
            </span>
          </td>
          <td>
            <span class="label">{{ request.visitType }}</span>
            <p class="clinic-address">
              <span class="field-value clinic" *ngIf="request.siteName">{{request.siteName}}</span>
              <span class="field-value address">{{ request.siteAddress }} {{request.siteCountry}}</span>
            </p>
          </td>
          <td class="btn-remove">
            <a (click)="onRemoveClick(request.id)" class="btn btn-outline btn-slim"
              *ngIf="authService.hasPermission(Permissions.TravelRequestDelete)">Remove</a>
          </td>
        </tr>
        <tr *ngIf="request.apiConsumerId !== null && request.apiConsumerId !== ''">
          <td>
            <span class="label">API Consumer</span>
            <span class="field-value">{{request.apiConsumerName}}</span>
          </td>
          <td>
            <span class="label">Notes/Additional Information</span>
            <span class="field-value"><app-expandable-text [text]="request.notes"></app-expandable-text></span>
          </td>
        </tr>
        <tr *ngIf="request.apiConsumerId !== null && request.apiConsumerId !== ''">
          <td colspan="2">&nbsp;</td>
        </tr>
      </table>

    </div>
    <!-- End: Travel Request -->

    <app-pagination [currentPage]="results.currentPage" [totalPageCount]="results.totalPageCount"
      (pageSelected)="loadTravel($event, keywords)"></app-pagination>

  </div>

  <div class="content-panel travel-requests travel-loaders" *ngIf="!dataLoaded">
    <div class="travel-request" *ngFor="let _ of loaderArray">

      <div class="travel-request-head">
        <ngx-skeleton-loader style="width: 10%;"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="width: 10%;"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="width: 10%; margin-right: 10px;"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="width: 10%;"></ngx-skeleton-loader>
      </div>

      <div class="travel-status">
        <ngx-skeleton-loader class="badge-loader" style="width: 10%;"></ngx-skeleton-loader>
        <ngx-skeleton-loader style="width: 13%;"></ngx-skeleton-loader>
      </div>

      <table>
        <tr>
          <td style="width: 27%;">
            <span class="label" style="width: 25%;"><ngx-skeleton-loader></ngx-skeleton-loader></span>
            <p class="date-time" style="width: 30%;">
              <ngx-skeleton-loader></ngx-skeleton-loader>
            </p>
          </td>
          <td style="width: 50%;">
            <span class="label" style="width: 10%;"><ngx-skeleton-loader></ngx-skeleton-loader></span>
            <p class="clinic-address w-100">
              <span class="field-value clinic" style="width: 22%"><ngx-skeleton-loader></ngx-skeleton-loader></span>
              <span class="field-value address"  style="width: 40%"><ngx-skeleton-loader></ngx-skeleton-loader></span>
            </p>
          </td>
          <td style="width: 11.2%;">
            <ngx-skeleton-loader class="remove-btn-loader"></ngx-skeleton-loader>
          </td>
        </tr>
      </table>
    </div>
  </div>

</div>
