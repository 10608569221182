import { SearchPage } from "./search-page.model";
import {
  PatientTrip
} from "../../features/patient/patient-detail/patient-detail-visits/patient-visit-detail/patient-visit-detail.model";

export class VisitSearch extends SearchPage {
  public results: VisitSearchResult[] = [];

  resultsFromResponse(response: any) {
    this.fromResponse(response);

    for (let result of response.results) {
      this.results.push(new VisitSearchResult(result));
    }

    return this;
  }
}

export class VisitSearchResult {
  public id: string;
  public trialCode: string;
  public travelRequested: string;
  public tripCount: number;
  public startDate: Date;
  public endDate: Date;
  public timeMinutes: number;
  public endTimeMinutes: number;
  public careGiverTravelling: string;
  public visitType: string;
  public department: string;
  public notes: string;
  public description: string;
  public attendance: string;
  public patientCode: string;
  public patientId: string;
  public siteId: string;
  public visitTitle: string;
  public designatedContacts: string[] = [];
  public trips: PatientTrip[] = [];
  public bookingStatus: string;
  public publishedStatus: string;
  public patientTrialId: string;
  public unsentNotifications: boolean;
  public patientAddress: string;
  public visitTemplateId: string;
  public trialMuvEnabled: boolean;
  public trialId: string;
  public overBudgetRequestRaised: boolean;
  public overBudgetRequestClosed: boolean;
  public takeMedicationAtVisit: boolean;
  public urineSample3DaysBefore: boolean;
  public urineSample10DaysBefore: boolean;

  constructor(init?: Partial<VisitSearchResult>) {
    Object.assign(this, init);
  }
}
