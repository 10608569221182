import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AlertService } from 'app/shared/alert/alert.service';
import { TemplateService } from 'app/core/services/template.service';
import { ApiConsumerService } from "../../../../core/services/api-consumer.service";
import { ModalComponent } from "../../../../shared/modal/modal.component";
import { SelectOption } from "../../../../core/models/select-option.model";
import { DropdownInputComponent } from "../../../../shared/dropdown-input/dropdown-input.component";

@Component({
  selector: 'app-add-api-consumer-modal',
  templateUrl: './add-api-consumer-modal.component.html',
  styleUrls: ['./add-api-consumer-modal.component.scss']
})
export class AddApiConsumerModalComponent implements OnInit {
  @ViewChild('modalWindow') modalWindow: ModalComponent;
  @ViewChild('webhookVersionSelect') webhookVersionSelect: DropdownInputComponent;

  @Output('added') added = new EventEmitter();

  form: UntypedFormGroup;
  isProcessing = false;
  webhookVersions: SelectOption[] = [];

  constructor(private _apiConsumerService: ApiConsumerService, private _alertService: AlertService,
    private _templateService: TemplateService) { }

  ngOnInit() {
    this._templateService.showHeader();

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      webhookUrl: new UntypedFormControl('', Validators.required),
      webhookVersion: new UntypedFormControl('V1', Validators.required),
      enabled: new UntypedFormControl(false)
    });

    this.webhookVersions.push(new SelectOption("V0", 'V0'));
    this.webhookVersions.push(new SelectOption("V1", 'V1'));

    this.webhookVersionSelect.setValue("V1");
  }

  show(): void {
    this.form.reset();
    this.form.patchValue({
      webhookVersion: 'V1',
      enabled: false
    });
    this.webhookVersionSelect.setValue("V1");

    this.modalWindow.show();
  }

  onFormSubmit() {
    if (!this.form.valid)
      return;

    this.isProcessing = true;
    this._apiConsumerService.create(
      this.form.get('name').value,
      this.form.get('webhookUrl').value,
      this.form.get('webhookVersion').value,
      this.form.get('enabled').value,
    ).subscribe({
      next: rsp => {
        this.isProcessing = false;
        this.added.emit();
        this.modalWindow.hide();
        this._alertService.showSuccessAlert('API Consumer has been created');
      },
      error: error => {
        this._alertService.showErrorAlert(error);
        this.isProcessing = false;
      }
    });

  }
}
