import { TrialPublicView } from '../../features/trial/trial-details/trial-public-view.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  AlertBalances,
  CaxtonEnabledRequest,
  SetTrialBalanceAlertsRequest,
  TrialPaymentDetailsViewModel
} from 'app/features/trial/trial-details/trial-payments.model';
import { TrialList } from 'app/core/models/trial-list.model';
import { TrialDetail, TrialSupportContact, TrialWithSites } from 'app/core/models/trial-detail.model';
import { TrialPatientList } from 'app/core/models/trial-patient-list.model';
import { ChangeLog } from 'app/shared/change-log/change-log.model';
import {
  CategoryListItemViewModel,
  CreateTrialPolicyExpenseRuleRequest,
  CreateTrialPolicyMileageRateRequest,
  CreateTrialPolicyRequest,
  ExpenseRulesViewModel,
  KeyVisitAutoPaymentsRequest,
  KeyVisitAutoPaymentsViewModel,
  MileageRatesViewModel,
  SetDefaultExpenseCurrencyRequest as SetDefaultPolicyCurrencyRequest,
  SetDefaultPolicyDistanceUnitRequest,
  TrialPolicy,
  UpdateTrialPolicyExpenseRuleRequest,
  UpdateTrialPolicyMileageRateRequest,
  UpdateTrialPolicyRequest
} from 'app/features/trial/trial-details/trial-policy.model';
import { TrialReport } from 'app/core/models/trial-report.model';
import { TrialInfo } from 'app/core/models/TrialInfo';
import { SiteTrialRelation } from 'app/core/models/site-trial-relation.model';
import { TrialConstants, TrialState } from 'app/core/constants/trial-constants';
import { TrialUpdateTimelineEvent } from './interfaces/trail-updatetimelinevent.interface';
import { TrialUpdate } from './interfaces/trial-update.interface';
import { TrialCreate } from './interfaces/trial-create.interface';
import { TrialAssignSite } from './interfaces/trial-assignsite.interface';
import { TrialUpdateRelation } from './interfaces/trial-updaterelation.interface';
import { TrialAssignPatient } from './interfaces/trial-assign-patient.interface';
import { TrialUpdateTrialInfo } from './interfaces/trial-updatetrialinfo.interface';
import {
  UpdateVisitScheduleRequest,
  VisitScheduleViewModel
} from 'app/features/trial/trial-details/trial-visit-schedule.model';
import { TrialMailbox } from "../models/trial-mailbox.model";
import { LogHelper } from "../helpers/log.helper";


@Injectable({
  providedIn: 'root'
})
export class TrialService {

  constructor(private readonly http: HttpClient) {
  }

  defaultTermsUrl: string = TrialConstants.defaultTermsUrl;
  defaultPrivacyPolicyUrl: string = TrialConstants.defaultPrivacyPolicyUrl;

  /**
   * Unassigns an administrator from a trial.
   *
   * @param {string} trialId - The ID of the trial.
   * @param {string} administratorId - The ID of the administrator.
   * @return {Observable<any>} - An Observable that represents the HTTP response from the server.
   */
  unassignAdministrator(trialId: string, administratorId: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/trial/' + trialId + '/administrator/' + administratorId);
  }

  /**
   * Assigns an administrator to a trial.
   *
   * @param {string} trialId - The ID of the trial.
   * @param {string} administratorId - The ID of the administrator.
   * @param projectManager
   * @param designatedContact
   * @return {Observable<any>} - The HTTP response from the server.
   */
  assignAdministrator(trialId: string, administratorId: string, projectManager: boolean, designatedContact: boolean): Observable<any> {
    let body = {
      projectManager: projectManager || false,
      designatedContact: designatedContact || false
    };

    return this.http.put<{ administratorId: string, trialId: string, name: string, email: string, projectManager: boolean, designatedContact: boolean }>(environment.apiUrl + '/trial/' + trialId + '/administrator/' + administratorId, body);
  }

  removeMailbox(trialId: string, mailboxId: string) {
    return this.http.delete(environment.apiUrl + '/trial/' + trialId + '/mailbox/' + mailboxId);
  }

  addMailbox(trialId: string, email: string) {
    return this.http.put(environment.apiUrl + '/trial/' + trialId + '/mailbox', { email: email });
  }

  getMailboxes(trialId: string): Observable<any> {
    return this.http.get<TrialMailbox>(environment.apiUrl + '/trial/' + trialId + '/mailbox');
  }

  mailbox() {
    return this.http.get(environment.apiUrl + '/trial/mailbox');
  }

  /**
   * Assigns a patient to a trial
   * @param dto
   */
  assignPatientToTrial(dto: TrialAssignPatient): Observable<any> {
    return this.http.put(environment.apiUrl + '/trial/' + dto.trialId + '/patient/assign', dto);
  }

  /**
   * Updates the default support email/phone on a trial
   * @param trialId
   * @param supportEmail
   * @param supportPhone
   */
  updateDefaultSupportContact(trialId: string, supportEmail: string, supportPhone: string): Observable<any> {
    const body = {
      supportEmail: supportEmail,
      supportPhone: supportPhone
    };

    return this.http.put(environment.apiUrl + '/trial/' + trialId + '/contacts/default', body);
  }

  /**
   * Remove a policy from a trial
   * @param trialId
   * @param policyId
   */
  removePolicy(trialId: string, policyId: string) {
    return this.http.delete(environment.apiUrl + '/trial/' + trialId + '/policy/' + policyId);
  }

  updatePolicy(request: UpdateTrialPolicyRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/policy`, request);
  }

  createPolicy(request: CreateTrialPolicyRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/trial/policy`, request);
  }

  /**
   * Retrieves a trial policy
   * @param trialId
   * @param policyId
   */
  getPolicy(trialId: string, policyId: string): Observable<TrialPolicy> {
    return this.http.get<TrialPolicy>(environment.apiUrl + '/trial/' + trialId + '/policy/' + policyId);
  }

  /**
   * Get's policies for a trial
   * @param trialId
   */
  policies(trialId: string): Observable<TrialPolicy[]> {
    return this.http.get<TrialPolicy[]>(environment.apiUrl + '/trial/' + trialId + '/policies');
  }

  /**
   * Get trials report
   */
  report(trialIds: string[], from: string, to: string) {
    let tempFromDate = moment(from + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss');
    let tempToDate = to !== null && to !== '' ? moment(to + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss') : null;

    return this.http.post<TrialReport>(environment.apiUrl + '/trial/report',
      {
        trialIds: trialIds,
        fromDate: tempFromDate,
        toDate: tempToDate
      });
  }

  /**
   * Uploads a timeline event image
   * @param data
   */
  uploadTimelineEventImage(data: Blob) {
    return this.http.post(environment.timelineImageStorageUrl, data);
  }

  /**
   * Deleted an existing timeline event
   * @param culture
   * @param id
   */
  removeTimelineEvent(culture: string, id: string) {
    return this.http.delete(environment.apiUrl + '/trial/info/timeline/event/' + id + '/' + culture);
  }

  /**
   * Updates a existing timeline event
   * @param culture
   * @param trialId
   * @param eventId
   * @param trialId
   * @param updateTimelineEvent
   */
  updateTimelineEvent(eventId: string, trialId: string, updateTimelineEvent: TrialUpdateTimelineEvent) {
    let convertedDate = moment(updateTimelineEvent.date + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ');

    let body = {
      culture: updateTimelineEvent.culture,
      date: convertedDate,
      heading: updateTimelineEvent.heading,
      description: updateTimelineEvent.description,
      type: updateTimelineEvent.boxType,
      imageFilename: updateTimelineEvent.imageFilename
    };

    return this.http.put(environment.apiUrl + '/trial/' + trialId + '/info/timeline/event/' + eventId, body);
  }

  /**
   * Creates a new timeline event
   * @param trialId
   * @param culture
   * @param date
   * @param heading
   * @param description
   * @param boxType
   * @param imageFilename
   */
  createTimelineEvent(trialId: string, culture: string, date: string, heading: string, description: string, boxType: string, imageFilename: string) {
    let convertedDate = moment(date + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ');

    let body = {
      culture: culture,
      date: convertedDate,
      heading: heading,
      description: description,
      type: boxType,
      imageFilename: imageFilename
    };

    return this.http.post(environment.apiUrl + '/trial/' + trialId + '/info/timeline/event', body);
  }

  /**
   * Gets trial info for a trial
   * @param trialId
   */
  trialInfo(trialId: string): Observable<TrialInfo[]> {
    return this.http.get<TrialInfo[]>(environment.apiUrl + '/trial/' + trialId + '/info').pipe(map((rsp: any) => {
      const infoList: TrialInfo[] = [];

      rsp.forEach(info => {
        infoList.push(TrialInfo.map(info));
      });

      return infoList;
    }));
  }

  /**
   * Updates trial info
   * @param trialId
   * @param culture
   * @param updateTrialInfo
   */
  updateTrialInfo(trialId: string, culture: string, updateTrialInfo: TrialUpdateTrialInfo) {
    let body = {
      culture: culture,
      startDate: updateTrialInfo.startDate != null ? moment(updateTrialInfo.startDate + ' 12:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      endDate: updateTrialInfo.endDate != null ? moment(updateTrialInfo.endDate + ' 12:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      timelineEnabled: updateTrialInfo.timelineEnabled,
      videoFilename: updateTrialInfo.videoFilename,
      information: updateTrialInfo.information,
      published: updateTrialInfo.published,
      trialInfoEnabled: updateTrialInfo.trialInfoEnabled
    };

    return this.http.put(environment.apiUrl + '/trial/' + trialId + '/info', body);
  }

  /**
   * Updates a trials contacts (inc remove, add and update)
   * @param trialId
   * @param removedContacts
   * @param contacts
   */
  updateContacts(trialId: string, removedContacts: string[], contacts: TrialSupportContact[]) {
    let body = {
      removedContacts: removedContacts,
      contacts: contacts
    };

    return this.http.put(environment.apiUrl + '/trial/' + trialId + '/contacts', body);
  }

  /**
   * Exports trips for a trial
   * @param trialId
   * @param dateFrom
   * @param dateTo
   * @param type
   * @param site
   */
  exportTrips(trialId: string, dateFrom: string, dateTo: string, type: string, siteId: string) {
    let body = {
      dateFrom: dateFrom ? moment(dateFrom + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: dateTo ? moment(dateTo + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      type: type,
      siteId: siteId
    }

    LogHelper.log(body);

    return this.http.post(environment.apiUrl + '/trial/' + trialId + '/trips/export', body);
  }

  /**
   * Generate trial change log export
   * @param trialId
   * @param dateFrom
   * @param dateTo
   */
  exportChangeLog(trialId: string, dateFrom: string, dateTo: string) {
    let body = {
      dateFrom: dateFrom ? moment(dateFrom + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null,
      dateTo: dateTo ? moment(dateTo + ' 23:59', 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm:ssZ') : null
    }

    return this.http.post(environment.apiUrl + '/trial/' + trialId + '/changelog/export', body);
  }

  /**
   * Get the change log for a trial
   * @param trialId
   * @param page
   */
  changeLog(trialId: string, page = 1) {
    let params = new HttpParams().set('page', page.toString());

    return this.http.get<ChangeLog>(environment.apiUrl + '/trial/' + trialId + '/changelog', { params: params }).pipe(map(rsp => {
      return new ChangeLog(rsp);
    }));
  }

  /**
   * Retrieve details of a single trial
   * @param trialId
   */
  retrieveTrial(trialId: string) {
    return this.http.get<TrialDetail>(environment.apiUrl + '/trial/' + trialId);
  }

  /**
   * Retrieves details of a single trial for a patient
   * @param patientId
   */
  retrieveTrialForPatient(patientId: string) {
    return this.http.get<TrialDetail>(environment.apiUrl + '/trial/patient/' + patientId);
  }

  /**
   * Retrieve a list of patients on a trial
   * @param trialId
   * @param page
   */
  retrievePatients(trialId: string, page: number = 1) {
    let params = new HttpParams()
      .set('page', page.toString())
      .set('pageSize', environment.pageSize.toString());

    return this.http.get<TrialPatientList>(environment.apiUrl + '/trial/' + trialId + '/patient', { params: params });
  }

  /**
   * Unassign a site from a trial
   * @param trialId
   * @param siteId
   */
  unassignSite(trialId: string, siteId: string) {
    return this.http.delete(environment.apiUrl + '/trial/' + trialId + '/site/' + siteId);
  }

  /**
 * Get a site/trial relation
 * @param trialId
 * @param siteId
 */
  getSiteTrialRelation(trialId: string, siteId: string) {
    return this.http.get<SiteTrialRelation>(environment.apiUrl + '/trial/' + trialId + '/site/' + siteId);
  }

  /**
   * Assign an site to a trial
   * @param trialId Id of the trial
   * @param siteId
   * @param assignSite
   */
  assignSite(trialId: string, siteId: string, assignSite: TrialAssignSite) {
    const tmpDate = moment(assignSite.approvalDate !== '' ? assignSite.approvalDate : '01/01/0001' + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss');
    const body = {
      siteId: siteId,
      siteNumber: assignSite.siteNumber,
      introductionMethod: assignSite.introductionMethod,
      approvalStatus: assignSite.approvalStatus,
      approvalDate: tmpDate,
      declinedReason: assignSite.declinedReason,
      declinedText: assignSite.declinedText,
      siteContacts: assignSite.siteContacts,
      introductionOccurred: assignSite.introductionOccurred,
      introductionDate: assignSite.introductionDate !== null && assignSite.introductionDate !== '' ? moment(assignSite.introductionDate + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss') : null,
    };

    return this.http.post(environment.apiUrl + '/trial/' + trialId + '/site', body);
  }

  /**
   * Assign an site to a trial
   * @param trialId Id of the trial
   * @param siteId Id of the site being assigned to the trial
   * @param updateRelation
   */
  updateSiteTrialRelation(trialId: string, siteId: string, updateRelation: TrialUpdateRelation) {
    const tmpDate = moment(updateRelation.approvalDate ? updateRelation.approvalDate : '01/01/0001 00:00', 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss');
    const body = {
      siteId: siteId,
      siteNumber: updateRelation.siteNumber,
      introductionMethod: updateRelation.introductionMethod,
      approvalStatus: updateRelation.approvalStatus,
      approvalDate: tmpDate,
      declinedReason: updateRelation.declinedReason,
      declinedReasonText: updateRelation.declinedText,
      siteContacts: updateRelation.siteContacts,
      introductionOccurred: updateRelation.introductionOccurred,
      introductionDate: updateRelation.introductionDate !== null && updateRelation.introductionDate !== '' ? moment(updateRelation.introductionDate + ' 00:00', 'DD/MM/YYYY HH:mm').format('YYYY/MM/DD HH:mm:ss') : null,
    };

    return this.http.put(environment.apiUrl + '/trial/' + trialId + '/site/' + siteId, body);
  }

  /**
   * Update an existing trial
   * @param trialId the Id of the trial to update
   * @param updateTrial
   */
  updateTrial(trialId: string, updateTrial: TrialUpdate) {
    if (updateTrial.allowExpenseRequests === null) {
      updateTrial.allowExpenseRequests = false;
    }
    if (updateTrial.allowTravelRequests === null) {
      updateTrial.allowTravelRequests = false;
    }

    const body = {
      code: updateTrial.trialCode.replace(/\s/g, ''),
      state: updateTrial.trialState,
      internalCode: updateTrial.internalCode,
      name: updateTrial.name,
      description: updateTrial.description,
      client: updateTrial.client,
      sponsor: updateTrial.sponsor,
      nickname: updateTrial.nickname,
      opportunityNumber: updateTrial.opportunityNumber,
      protocolNumber: updateTrial.protocolNumber,
      indications: updateTrial.indications,
      therapeuticArea: updateTrial.therapeuticArea,
      visitCount: updateTrial.visitCount,
      allowTravelRequests: updateTrial.allowTravelRequests || false,
      allowExpenseRequests: updateTrial.allowExpenseRequests || false,
      allowActivityCentre: updateTrial.allowActivityCentre,
      expenseCategories: updateTrial.expenseCategories,
      enableSmartNotifications: updateTrial.enableSmartNotifications,
      termsUrl: updateTrial.termsUrl,
      privacyPolicyUrl: updateTrial.privacyPolicyUrl,
      coordinatorEmail: updateTrial.coordinatorEmail,
      translationVersionId: updateTrial.translationVersionId,
      rideHealthEnabled: updateTrial.rideHealthEnabled,
      dashboardEnabled: updateTrial.dashboardEnabled,
      allowFeedbackRequests: updateTrial.allowFeedbackRequests,
      countries: updateTrial.countries,
      expensePolicyReminders: updateTrial.expensePolicyReminders,
      bookingEnabled: updateTrial.bookingEnabled,
      patientAutoCompletion: updateTrial.patientAutoCompletion,
      bankAccountEnabled: updateTrial.bankAccountEnabled,
      bankAccountSelfManagementEnabled: updateTrial.bankAccountSelfManagementEnabled,
      baseCurrency: updateTrial.baseCurrency,
      bankAccountSelfCardManagementEnabled: updateTrial.bankAccountSelfCardManagementEnabled,
      muvEnabled: updateTrial.muvEnabled,
      requestTravelEmailTemplate: updateTrial.requestTravelEmailTemplate,
      magicLinkExpiryDays: updateTrial.magicLinkExpiryDays,
      emailPatientOnVisitCreation: updateTrial.emailPatientOnVisitCreation,
      diaryEnabled: updateTrial.diaryEnabled,
      imsSupplier: updateTrial.imsSupplier
    };

    LogHelper.log(body);

    return this.http.put(environment.apiUrl + '/trial/' + trialId, body);
  }

  /**
   * Create a new trial
   * @param createTrial
   */
  createTrial(createTrial: TrialCreate) {
    if (createTrial.allowExpenseRequests === null) {
      createTrial.allowExpenseRequests = false;
    }
    if (createTrial.allowTravelRequests === null) {
      createTrial.allowTravelRequests = false;
    }

    const body = {
      code: createTrial.trialCode.replace(/\s/g, ''),
      internalCode: createTrial.internalCode,
      name: createTrial.name,
      description: createTrial.description,
      client: createTrial.client,
      sponsor: createTrial.sponsor,
      nickname: createTrial.nickname,
      opportunityNumber: createTrial.opportunityNumber,
      protocolNumber: createTrial.protocolNumber,
      indications: createTrial.indications,
      therapeuticArea: createTrial.therapeuticArea,
      visitCount: createTrial.visitCount,
      allowTravelRequests: createTrial.allowTravelRequests,
      allowExpenseRequests: createTrial.allowExpenseRequests,
      allowActivityCentre: createTrial.allowActivityCentre,
      enableSmartNotifications: createTrial.enableSmartNotifications,
      expenseCategories: createTrial.expenseCategories,
      termsUrl: createTrial.termsUrl,
      privacyPolicyUrl: createTrial.privacyPolicyUrl,
      coordinatorEmail: createTrial.coordinatorEmail,
      translationVersionId: createTrial.translationVersionId,
      rideHealthEnabled: createTrial.rideHealthEnabled,
      dashboardEnabled: createTrial.dashboardEnabled,
      allowFeedbackRequests: createTrial.allowFeedbackRequests,
      countries: createTrial.countries,
      expensePolicyReminders: createTrial.expensePolicyReminders,
      bookingEnabled: createTrial.bookingEnabled,
      patientAutoCompletion: createTrial.patientAutoCompletion,
      bankAccountEnabled: createTrial.bankAccountEnabled,
      bankAccountSelfManagementEnabled: createTrial.bankAccountSelfManagementEnabled,
      bankAccountSelfCardManagementEnabled: createTrial.bankAccountSelfCardManagementEnabled,
      baseCurrency: createTrial.baseCurrency,
      muvEnabled: createTrial.muvEnabled,
      apiEnabled: createTrial.apiEnabled,
      apiConsumerIds: createTrial.apiConsumerIds,
      requestTravelEmailTemplate: createTrial.requestTravelEmailTemplate,
      magicLinkExpiryDays: createTrial.magicLinkExpiryDays,
      emailPatientOnVisitCreation: createTrial.emailPatientOnVisitCreation,
      diaryEnabled: createTrial.diaryEnabled,
      imsSupplier: createTrial.imsSupplier
    };

    return this.http.post(environment.apiUrl + '/trial', body);
  }

  /**
   * Retrieve a list of trials from the server
   * @param page the page number of trials to receive
   * @param keywords apply a keyword to the trial search
   * @param pageSize results per page
   * @param state trial state
   */
  retrieveTrials(page: number, keywords: string, pageSize: number, state?: TrialState) {
    let params = new HttpParams()
      .set('pageSize', environment.pageSize.toString());
    if (page > 1) {
      params = params.set('page', page.toString());
    }
    if (keywords != null) {
      params = params.set('keywords', keywords);
    }
    if (state != null) {
      params = params.set('state', state);
    }
    if (pageSize > 0) {
      params = params.set('pageSize', pageSize.toString());
    }

    return this.http.get<TrialList>(environment.apiUrl + '/trial', { params: params });
  }

  getBalanceAlerts(trialId: string): Observable<AlertBalances> {
    return this.http.get<AlertBalances>(`${environment.apiUrl}/trial/balance-alerts?trialId=${trialId}`);
  }

  getTrialsPublic(keywords: string, pageSize: number, state?: TrialState): Observable<TrialPublicView[]> {
    let params = new HttpParams()
      .set('pageSize', environment.pageSize.toString());

    if (keywords != null) {
      params = params.set('keywords', keywords);
    }
    if (state != null) {
      params = params.set('state', state);
    }

    if (pageSize > 0) {
      params = params.set('pageSize', pageSize.toString());
    }

    return this.http.get<TrialPublicView[]>(`${environment.apiUrl}/trial/public-list`, { params: params });
  }

  updateBalanceAlerts(request: SetTrialBalanceAlertsRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/balance-alerts`, request);
  }

  getTrialPaymentsInfo(trialId: string): Observable<TrialPaymentDetailsViewModel> {
    return this.http.get<TrialPaymentDetailsViewModel>(`${environment.apiUrl}/trial/${trialId}/payment-info`);
  }

  setCaxtonEnabled(request: CaxtonEnabledRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/payment-info`, request);
  }

  updateTrialVisitSchedule(trialId: string, request: UpdateVisitScheduleRequest[]): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/${trialId}/visit-schedule`, request);
  }

  getTrialVisitSchedule(trialId: string): Observable<VisitScheduleViewModel[]> {
    return this.http.get<VisitScheduleViewModel[]>(`${environment.apiUrl}/trial/${trialId}/visit-schedule`).pipe(map(rsp => rsp.map(visit => new VisitScheduleViewModel(visit))));
  }

  getKeyVisitsAutoPayment(trialId: string, policyId: string): Observable<KeyVisitAutoPaymentsViewModel> {
    return this.http.get<KeyVisitAutoPaymentsViewModel>(`${environment.apiUrl}/trial/${trialId}/key-visit/${policyId}`);
  }

  updateKeyVisitAutoPayment(trialId: string, policyId: string, request: KeyVisitAutoPaymentsRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/${trialId}/key-visit/${policyId}`, request);
  }

  createTrialPolicyExpenseRule(request: CreateTrialPolicyExpenseRuleRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/trial/policy-expense-rule`, request);
  }

  updateTrialPolicyExpenseRule(request: UpdateTrialPolicyExpenseRuleRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/policy-expense-rule`, request);
  }

  deleteTrialPolicyExpenseRule(ruleId: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/trial/policy-expense-rule/${ruleId}`);
  }

  getPolicyCategories(policyId: string): Observable<CategoryListItemViewModel[]> {
    return this.http.get<CategoryListItemViewModel[]>(`${environment.apiUrl}/trial/policy-categories/${policyId}`);
  }

  getPolicyExpenseRules(policyId: string): Observable<ExpenseRulesViewModel> {
    return this.http.get<ExpenseRulesViewModel>(`${environment.apiUrl}/trial/policy-expense-rules/${policyId}`);
  }

  setPolicyExpenseRuleCurrency(request: SetDefaultPolicyCurrencyRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/policy-expense-rule-currency`, request);
  }

  setPolicyMileageRateDistanceUnit(request: SetDefaultPolicyDistanceUnitRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/policy-mileage-rate-currency`, request);
  }

  createTrialPolicyMileageRateRule(request: CreateTrialPolicyMileageRateRequest): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/trial/policy-mileage-rate`, request);
  }

  updateTrialPolicyMileageRateRule(request: UpdateTrialPolicyMileageRateRequest): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/trial/policy-mileage-rate`, request);
  }

  deleteTrialPolicyMileageRateRule(ruleId: string): Observable<any> {
    return this.http.delete<any>(`${environment.apiUrl}/trial/policy-mileage-rate/${ruleId}`);
  }

  getPolicyMileageRatesRules(policyId: string): Observable<MileageRatesViewModel> {
    return this.http.get<MileageRatesViewModel>(`${environment.apiUrl}/trial/mileage-rates/${policyId}`);
  }

  getTrialsWithSites(): Observable<TrialWithSites[]> {
    return this.http.get<TrialWithSites[]>(`${environment.apiUrl}/trial/trials-with-sites`);
  }
}
