export class VisitTemplateTranslation {
  public id: string;
  public culture: string;
  public title: string;
  public description: string;
  public notes: string;

  constructor(init?: Partial<VisitTemplateTranslation>) {
    Object.assign(this, init);
  }
}
